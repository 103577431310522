import { composeRenderProps, Provider, Button as RACButton, ButtonProps as RACButtonProps, Text, TextContext } from 'react-aria-components'
import { tv } from 'tailwind-variants'
import { focusRing } from '../utils'
import { IconLoader, IconLoader2 } from '@tabler/icons-react'
import { IconContext } from '../Icon/Icon'

export interface ButtonProps extends RACButtonProps {
  variant?: 'primary' | 'secondary' | 'destructive' | 'icon' | 'link' | 'outline'
  mode?: 'dark' | 'light'
  isLoading?: boolean
}

export const buttonStyles = tv({
    extend: focusRing,
    base: 'cursor-pointer px-4 h-[32px] text-sm transition rounded dark:border-white/10 dark:shadow-none shrink-0 inline-flex items-center justify-center',
    variants: {
        variant: {
            primary: "bg-teal-600 hover:bg-teal-700 pressed:bg-teal-500 text-white",
            secondary: 'bg-gray-100 hover:bg-gray-200 pressed:bg-gray-300 text-gray-800 dark:bg-zinc-600 dark:hover:bg-zinc-500 dark:pressed:bg-zinc-400 dark:text-zinc-100',
            destructive: 'bg-red-700 hover:bg-red-800 pressed:bg-red-900 text-white',
            link: "text-teal-600 hover:bg-teal-800/[0.1]",
            outline: "text-teal-600 border-teal-600 border hover:bg-teal-800/[0.1]",
            icon: 'border-none shadow-none p-0 flex items-center justify-center text-gray-300 hover:bg-black/[5%] pressed:bg-black/10 dark:text-zinc-400 dark:hover:bg-white/10 dark:pressed:bg-white/20 disabled:bg-transparent'
        },
        mode: {
            light: "text-grey-800",
            dark: "",
        },
        isDisabled: {
            true: "outline-none cursor-default text-gray-300"
        }
    },
    defaultVariants: {
        variant: 'primary'
    }
});

const iconStyles = tv({
    base: "mx-1 only:mx-0 last:mr-2 first:ml-2"
})

const spinnerStyles = tv({
    extend: iconStyles,
    variants: {
        isLoading: {
            true: "animate-spin"
        }
    }
})

export function Button(props: ButtonProps) {
    const {variant = 'primary', mode = 'dark', isLoading = false, children} = props

    const Icon = isLoading ? <IconLoader2 size={20} className={spinnerStyles({isLoading})} /> : null

    return (
        <Provider values={[
            [IconContext, {
                size: 20,
                className: iconStyles()
            }],
            [TextContext, {
                className: "last:mr-3 first:ml-3"
            }]
        ]}>
            <RACButton
                {...props}
                className={composeRenderProps(
                    props.className,
                    (className, renderProps) => buttonStyles({...renderProps, variant, mode, className}))}>
                <>
                    {Icon && Icon}
                    {typeof children === "string"
                        ? <Text>{children}</Text>
                        : children}
                </>
            </RACButton>
        </Provider>
    )
}