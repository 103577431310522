import { Button } from "@/components/Button";
import { ChevronLeft, Palette, ViewQuilt, Widgets } from "@material-ui/icons";
import { useNavigate } from "@tanstack/react-router";
import { NavHeader, NavItem, NavMenu, NavSection } from "../Home";

export function EditorIndex() {
    const navigate = useNavigate();
    return (
        <>
            <Button variant="link" onPress={() => navigate({ to: "/" })}>
                <ChevronLeft />
                SITE EDITOR
            </Button>
            <NavMenu className="flex flex-col gap-2" aria-label="Theme settings menu">
                <NavSection>
                    <NavHeader>
                        <Palette fontSize="small" className="mr-2" />
                         Site Styles
                    </NavHeader>
                    <NavItem href="/theme-editor/general">Colors</NavItem>
                    <NavItem href="/theme-editor/fonts">Fonts</NavItem>
                    <NavItem href="/theme-editor/layout">Layout</NavItem>
                    <NavItem href="/theme-editor/buttons">Buttons</NavItem>
                    <NavItem href="/theme-editor/site-identity">Site Identity</NavItem>
                    <NavItem href="/theme-editor/icons">Icons</NavItem>
                </NavSection>
                <NavSection>
                    <NavHeader>
                        <Widgets fontSize="small" className="mr-2" />
                        Customize Components
                    </NavHeader>
                    <NavItem href="/theme-editor/header">Header</NavItem>
                    <NavItem href="/theme-editor/background-image">
                        Background Image
                    </NavItem>
                    <NavItem href="/theme-editor/menu-group">Menu Groups</NavItem>
                    <NavItem href="/theme-editor/menu-item">Menu Items</NavItem>
                    <NavItem href="/theme-editor/footer">Footer</NavItem>
                </NavSection>
                <NavSection>
                    <NavItem href="/theme-editor/theme-selector">
                        <ViewQuilt fontSize="small" className="mr-2" />
                        Theme selector
                    </NavItem>
                </NavSection>
                <NavSection>
                    <NavItem href="/theme-editor/customize-css">
                        <ViewQuilt fontSize="small" className="mr-2" />
                        Customize CSS
                    </NavItem>
                </NavSection>
            </NavMenu>
        </>
    );
}
