import { observer } from "mobx-react-lite";
import { SideBar } from "../SideBar";
import { Outlet } from "@tanstack/react-router";
import { useThemeStore } from "@/stores/Theme";

export const SettingsSideBar = observer(() => {
    const theme = useThemeStore()
    return <>
        <SideBar>
            {theme.preflight ? <Outlet/> : <></>}
        </SideBar>
    </>
})