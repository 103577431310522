import { Collection } from "react-aria-components";
import { ListBoxItem } from "../ListBox"
import { Select, SelectSection } from "../Select"

export const PageSelect = () => {
    const options = [
        {name: 'Site Pages', children: [
            {name: 'Login'},
            {name: 'Menu Items'},
            {name: 'Order tracker'},
            {name: 'Order type'},
            {name: 'Cart'},
            {name: 'Checkout'}
        ]},
        {name: 'Other Pages', children: [
            {name: 'Style Guide'}
        ]}
    ]

    return (
        <Select label="Pages" items={options}>
            {section => (
                <SelectSection id={section.name} title={section.name}>
                    <Collection items={section.children}>
                        {item => <ListBoxItem id={item.name}>{item.name}</ListBoxItem>}
                    </Collection>
                </SelectSection>
            )}
        </Select>
    )
}