import { ReactElement, ReactNode } from 'react'
import { Radio as RACRadio, RadioGroup as RACRadioGroup, RadioGroupProps as RACRadioGroupProps, RadioProps as RACRadioProps, RadioRenderProps, ValidationResult } from 'react-aria-components'
import { tv } from 'tailwind-variants';
import { Description, FieldError, Label } from '../Field'
import { composeTailwindRenderProps, focusRing } from '../utils'

export interface RadioGroupProps extends Omit<RACRadioGroupProps, 'children'> {
  label?: string,
  children?: ReactNode,
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
}

export function RadioGroup(props: RadioGroupProps) {
    return (
        <RACRadioGroup {...props} className={composeTailwindRenderProps(props.className, 'group flex flex-col gap-2')}>
            <Label>{props.label}</Label>
            <div className="flex group-orientation-vertical:flex-col gap-2 group-orientation-horizontal:gap-4">
                {props.children}
            </div>
            {props.description && <Description>{props.description}</Description>}
            <FieldError>{props.errorMessage}</FieldError>
        </RACRadioGroup>
    )
}

const styles = tv({
    extend: focusRing,
    base: "w-5 h-5 ring-2 ring-gray-200 rounded-full bg-white dark:bg-zinc-900 transition-all",
    variants: {
        isSelected: {
            false: 'bg-inherit dark:border-zinc-400 group-pressed:border-gray-500 dark:group-pressed:border-zinc-300',
            true: 'border-[4px] border-transparent bg-clip-padding'
        },
        isInvalid: {
            true: 'border-red-700 dark:border-red-600 group-pressed:border-red-800 dark:group-pressed:border-red-700 forced-colors:!border-[Mark]'
        },
        isDisabled: {
            true: 'border-gray-200 dark:border-zinc-700 forced-colors:!border-[GrayText]'
        }
    }
})

export interface RadioProps extends RACRadioProps {
    icon?: (props: RadioRenderProps) => ReactElement
}

export function Radio(props: RadioProps) {
    const {icon = DefaultIcon} = props
    return (
        <RACRadio {...props} className={composeTailwindRenderProps(props.className, 'flex gap-4 items-center group min-h-4 py-2 text-white transition cursor-pointer disabled:cursor-default disabled:opacity-35')}>
            {renderProps => <>
                {icon(renderProps)}
                {props.children}
            </>}
        </RACRadio>
    )
}

const DefaultIcon = (renderProps: RadioRenderProps) =>
    <div className={styles(renderProps)} />