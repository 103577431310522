import { FileMeta } from "@/client"
import { Button } from "@/components/Button"
import { Modal } from "@/components/Modal/Modal"
import { MediaLibrary, MediaLibraryProps } from "@/features/media/MediaCard/MediaLibrary"
import { useMedia } from "@/stores/Media"
import { observer } from "mobx-react-lite"
import { useMemo, useState } from "react"
import { Dialog } from "react-aria-components"
import { useControlledState } from '@react-stately/utils'


export interface MediaSelectProps {
    url?: string | null
    onChange?: (name: string | null) => void
}

export const MediaSelect = (props: MediaSelectProps) => {
    const [fileName, setFileName] = useState<string|undefined>()
    const [url, setUrl] = useControlledState(props.url, "", props.onChange)
    const [isOpen, setIsOpen] = useState(false)

    const onChange = (file: FileMeta | null) => {
        setUrl(file?.uri ?? null)
        props.onChange?.(file?.uri ?? null)
        const url = new URL(file?.uri ?? "")
        setFileName(url.pathname.split("/").pop())
        setIsOpen(false)
    }

    const onOpenChange = (value: boolean) => {
        console.log("Open change")
        setIsOpen(value)
    }

    return (
        <div className="flex flex-col w-full">
            <div className="flex items-center content-center justify-center w-full h-[144px] rounded bg-gray-700">
                {url && <img src={url} className="object-cover h-full w-full" />}
            </div>
            <div className="flex gap-5 mt-6">
                <UpdateButton selected={fileName} onChange={onChange} isOpen={isOpen} onOpenChange={onOpenChange} />
                <Button onPress={() => onChange(null)} variant="outline">REMOVE</Button>
            </div>
        </div>
    )
}

const UpdateButton = (props: MediaLibraryProps & {isOpen: boolean, onOpenChange: (value: boolean) => void}) => {
    return <>
        <Button onPress={() => props.onOpenChange(true)} variant="outline">UPDATE</Button>
        <Modal className="w-[80vw] h-[80vh]" isDismissable isOpen={props.isOpen} onOpenChange={props.onOpenChange}>
            <Dialog className="h-full">
                <UpdateModal {...props} />
            </Dialog>
        </Modal>
    </>
}

const UpdateModal = observer((props: MediaLibraryProps) => {
    const media = useMedia()

    const {isPending, data} = useMemo(() => media.queryLoad(), [])

    return (
        <MediaLibrary rendersDetailDrawer={false} {...props} files={data!} />
    )
})