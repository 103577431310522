import { useThemeStore } from "@/stores/Theme"
import { observer } from "mobx-react-lite"
import { Settings } from "./_Settings"
import { SettingsSection } from "@/features/themer/components/SettingsSection"
import { HColorPicker } from "@/components/ColorPicker/ColorPicker"
import { Switch } from "@/components/Switch/Switch"
import { AlignmentSelect } from "@/features/themer/components/AlignmentSelect"
import { OrientationSelect, OrientationType } from "@/features/themer/components/OrientationSelect"


export const MenuGroupSettings = observer(() => {
    const theme = useThemeStore()

    const [getDarkColor, setDarkColor] = theme.bind(p => p.theme.template.components.foodMenuNavigation?.styles.colors?.foreground)
    const [getLightColor, setLightColor] = theme.bind(p => p.theme.template.components.foodMenuNavigation?.styles.colors?.background)

    const [getSwapColor, setSwapColor] = theme.bind(p => p.theme.template.components.foodMenuNavigation?.styles.dark)
    const [getTransparent, setTransparent] = theme.bind(p => p.theme.template.components.foodMenuNavigation?.styles.transparent)
    const [getRaised, setRaised] = theme.bind(p => p.theme.template.components.foodMenuNavigation?.styles.raised)

    const [getAlignment, setAlignment] = theme.bind(p => p.theme.template.components.foodMenuNavigation?.innerLayout)
    const [getOrientation, setOrientation, isOrientationDisabled] = theme.bind(p => p.theme.template.components.foodMenuNavigation?.orientation)

    console.log(getDarkColor())

    return (
        <Settings title="MENU GROUPS">
            <SettingsSection label="DARK COLOR">
                <HColorPicker
                    defaultValue={getDarkColor()}
                    onChange={(value) => setDarkColor(value?.toString("hex") ?? "")}
                    isResettable
                />
            </SettingsSection>
            <SettingsSection label="LIGHT COLOR">
                <HColorPicker
                    defaultValue={getLightColor()}
                    onChange={(value) => setLightColor(value?.toString("hex") ?? "")}
                    isResettable
                />
            </SettingsSection>
            <SettingsSection label="STYLE">
                <Switch isSelected={getSwapColor()} onChange={setSwapColor}>Swap light and dark colors</Switch>
                <Switch isSelected={getTransparent()} onChange={setTransparent}>Transparent</Switch>
                <Switch isSelected={getRaised()} onChange={setRaised}>Raised</Switch>
            </SettingsSection>
            <SettingsSection label="ALIGNMENT">
                <AlignmentSelect value={getAlignment()} onChange={setAlignment}/>
            </SettingsSection>
            <SettingsSection label="ORIENTATION">
                <OrientationSelect value={getOrientation() as OrientationType} onChange={setOrientation} isDisabled={isOrientationDisabled()}/>
            </SettingsSection>
        </Settings>
    )
})