import { composeRenderProps, ModalOverlay, ModalOverlayProps, Modal as RACModal } from 'react-aria-components'
import { tv } from 'tailwind-variants'

const overlayStyles = tv({
    base: 'fixed top-0 left-0 w-full h-[--visual-viewport-height] isolate z-20 bg-black/[15%] flex p-4 text-center backdrop-blur-lg',
    variants: {
        isEntering: {
            true: 'animate-in fade-in duration-200 ease-out'
        },
        isExiting: {
            true: 'animate-out fade-out duration-200 ease-in'
        }
    }
})

const drawerStyles = tv({
    base: 'fixed top-0 right-0 bottom-0 w-[300px] bg-white forced-colors:bg-[Canvas] bg-clip-padding border border-black/10 overflow-hidden',
    variants: {
        isEntering: {
            true: 'animate-in slide-in-from-right ease-out duration-200'
        },
        isExiting: {
            true: 'animate-out slide-out-to-right ease-in duration-200'
        }
    }
})

export function Drawer(props: ModalOverlayProps) {
    return (
        <ModalOverlay {...props} className={overlayStyles}>
            <RACModal {...props} className={composeRenderProps(
                props.className, (className, renderProps) => drawerStyles({...renderProps, className}))} />
        </ModalOverlay>
    )
}