import {
    TextField as AriaTextField,
    TextFieldProps as AriaTextFieldProps,
    ValidationResult
} from 'react-aria-components';
import { tv } from 'tailwind-variants';
import { Description, FieldError, Input, Label, fieldBorderStyles } from '../Field'
import { composeTailwindRenderProps, focusRing } from '../utils'
import { useState } from 'react';
import { mergeProps } from 'react-aria';

const inputStyles = tv({
    extend: focusRing,
    base: 'border rounded dark:text-black',
    variants: {
        isFocused: fieldBorderStyles.variants.isFocusWithin,
        ...fieldBorderStyles.variants,
    }
})

export interface TextFieldProps extends AriaTextFieldProps {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
}

export function TextField(
    { label, description, errorMessage, ...props }: TextFieldProps
) {
    return (
        <AriaTextField {...props} className={composeTailwindRenderProps(props.className, 'flex flex-col gap-1')}>
            {label && <Label>{label}</Label>}
            <Input className={inputStyles} />
            {description && <Description>{description}</Description>}
            <FieldError>{errorMessage}</FieldError>
        </AriaTextField>
    );
}

export function TextFieldOnBlur(
    {onChange, defaultValue, ...props}: TextFieldProps
) {
    const [initialValue, setInitialValue] = useState(defaultValue)
    const [buffered, setBuffered] = useState(defaultValue ?? "")

    if (initialValue != defaultValue) {
        setInitialValue(defaultValue)
        setBuffered(defaultValue ?? "")
    }

    const onBlur = () => onChange?.(buffered)

    props = mergeProps(props, {onBlur})

    return <TextField {...props} onChange={setBuffered} value={buffered}/>
}