import {
  OperationParameter,
  OperationURLParameter,
  OperationQueryParameter,
} from "@azure/core-client";
import {
  LoginRequest as LoginRequestMapper,
  PublishRequestDto as PublishRequestDtoMapper,
  InitialisePreflightRequest as InitialisePreflightRequestMapper,
  Preflight as PreflightMapper,
  SaveRequestDto as SaveRequestDtoMapper,
} from "../models/mappers";

export const contentType: OperationParameter = {
  parameterPath: ["options", "contentType"],
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Content-Type",
    type: {
      name: "String",
    },
  },
};

export const body: OperationParameter = {
  parameterPath: "body",
  mapper: LoginRequestMapper,
};

export const accept: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/json, text/json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String",
    },
  },
};

export const $host: OperationURLParameter = {
  parameterPath: "$host",
  mapper: {
    serializedName: "$host",
    required: true,
    type: {
      name: "String",
    },
  },
  skipEncoding: true,
};

export const xHrSiteId: OperationParameter = {
  parameterPath: "xHrSiteId",
  mapper: {
    serializedName: "x-hr-site-id",
    required: true,
    type: {
      name: "Number",
    },
  },
};

export const isPublished: OperationQueryParameter = {
  parameterPath: ["options", "isPublished"],
  mapper: {
    serializedName: "isPublished",
    type: {
      name: "Boolean",
    },
  },
};

export const isDraft: OperationQueryParameter = {
  parameterPath: ["options", "isDraft"],
  mapper: {
    serializedName: "isDraft",
    type: {
      name: "Boolean",
    },
  },
};

export const offset: OperationQueryParameter = {
  parameterPath: ["options", "offset"],
  mapper: {
    serializedName: "offset",
    type: {
      name: "Number",
    },
  },
};

export const limit: OperationQueryParameter = {
  parameterPath: ["options", "limit"],
  mapper: {
    serializedName: "limit",
    type: {
      name: "Number",
    },
  },
};

export const searchTerm: OperationQueryParameter = {
  parameterPath: ["options", "searchTerm"],
  mapper: {
    defaultValue: "",
    serializedName: "searchTerm",
    type: {
      name: "String",
    },
  },
};

export const body1: OperationParameter = {
  parameterPath: "body",
  mapper: PublishRequestDtoMapper,
};

export const versionId: OperationURLParameter = {
  parameterPath: "versionId",
  mapper: {
    serializedName: "versionId",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const version: OperationURLParameter = {
  parameterPath: "version",
  mapper: {
    serializedName: "version",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const filename: OperationURLParameter = {
  parameterPath: "filename",
  mapper: {
    constraints: {
      MinLength: 1,
    },
    serializedName: "filename",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const body2: OperationParameter = {
  parameterPath: "body",
  mapper: InitialisePreflightRequestMapper,
};

export const body3: OperationParameter = {
  parameterPath: "body",
  mapper: PreflightMapper,
};

export const body4: OperationParameter = {
  parameterPath: "body",
  mapper: SaveRequestDtoMapper,
};

export const accept1: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "text/css",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String",
    },
  },
};

export const siteId: OperationURLParameter = {
  parameterPath: "siteId",
  mapper: {
    serializedName: "siteId",
    required: true,
    type: {
      name: "Number",
    },
  },
};
