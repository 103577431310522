import { useThemeStore } from "@/stores/Theme"
import { observer } from "mobx-react-lite"
import { Settings } from "./_Settings"
import { SettingsSection } from "@/features/themer/components/SettingsSection"
import { TextFieldOnBlur } from "@/components/TextField"
import { Switch } from "@/components/Switch/Switch"
import { MediaSelect } from "@/features/themer/components/MediaSelect"
import { Slider } from "@/components/Slider/Slider"

export const SiteIdentitySettings = observer(() => {
    const theme = useThemeStore()

    const [getSiteName, setSiteName] = theme.bind(p => p.theme.meta.name)
    const [getTagline, setTagline] = theme.bind(p => p.theme.meta.tagLine)

    const [getLogo, setLogo] = theme.bind(p => p.theme.template.logoUrl)
    const [getLogoSize, setLogoSize, isLogoSizeDisabled] = theme.bind(p => p.theme.template.components.logoOptions?.size)
    const [getLogoOverhang, setLogoOverhang, isLogoOverhangDisabled] = theme.bind(p => p.theme.template.components.logoOptions?.overHang)
    const [getIcon, setIcon] = theme.bind(p => p.theme.template.iconUrl)

    return (
        <Settings title="SITE IDENTITY">
            <SettingsSection label="SITE NAME">
                <TextFieldOnBlur defaultValue={getSiteName()} onChange={setSiteName} label="Name"/>
                <TextFieldOnBlur defaultValue={getTagline()} onChange={setTagline} label="Tagline"/>
            </SettingsSection>
            <SettingsSection label="LOGO">
                <MediaSelect url={getLogo()} onChange={setLogo}/>
            </SettingsSection>
            <SettingsSection label="LOGO SIZE">
                <Slider defaultValue={getLogoSize()} onChangeEnd={setLogoSize} minValue={1} maxValue={5} isDisabled={isLogoSizeDisabled()}/>
            </SettingsSection>
            <SettingsSection label="OVERHANG LOGO">
                <Switch isSelected={getLogoOverhang()} onChange={setLogoOverhang} isDisabled={isLogoOverhangDisabled()}>Overhang</Switch>
            </SettingsSection>
            <SettingsSection label="Favicon .ico file">
                <MediaSelect url={getIcon()} onChange={setIcon}/>
            </SettingsSection>
        </Settings>
    )
})