import { FileMeta } from "@/client"
import { ReactElement } from "react"
import { PressProps, useHover, usePress } from "react-aria"
import { GridListItemRenderProps } from "react-aria-components"
import { tv } from "tailwind-variants"

export interface MediaCardProps extends PressProps {
    file: FileMeta
    children?: ReactElement
    includeModified?: boolean
}

const cardStyles = tv({
    base: "flex flex-col rounded-md border border-black/15 cursor-pointer overflow-hidden",
    variants: {
        isSelected: { true: "ring-4 ring-teal-600"},
        isFocused: { true: "ring-4 ring-teal-600"}
    }
})

const footerStyles = tv({
    base: "flex justify-between items-center bg-white rounded-none p-2 font-semibold text-gray-600",
    variants: {
        isHovered: { true: "bg-gray-100" }
    }
})

export const MediaCard = ({file, children, includeModified = false, ...otherProps}: MediaCardProps & GridListItemRenderProps) => {
    const imageSrc = includeModified ?
        `${file.uri}?${file.lastModified?.toISOString()}`
        : file.uri

    console.log(otherProps.isSelected)

    return (
        <div className={cardStyles({...otherProps})}>
            <img loading="lazy" src={imageSrc} className="object-cover min-h-[12rem] h-[12rem] w-full min-w-full"></img>
            <div className={footerStyles({...otherProps})}>
                <div>{file.name}</div>{children ? children : null}
            </div>
        </div>
    )
}