import React, { useRef, useState } from "react";
import { Editor } from "@monaco-editor/react";
import { Settings } from "../../app/settings/_Settings";
import { SettingsSection } from "@/features/themer/components/SettingsSection";
import { Button } from "@/components/Button";
import { useThemeStore } from "@/stores/Theme";
import "./CustomCssStyles.css";

interface CustomCSSProp {
  onChange?: (value: string) => void;
  defaultValue?: string;
  title?: string;
}

export const CustomizeCSS = ({
  defaultValue,
  onChange,
  title,
}: CustomCSSProp) => {
  const [value, setValue] = useState("");
  const editorRef = useRef([] as any);
  const theme = useThemeStore();
  const [css, setCSS] = useState(defaultValue);
  const [initialDefault, setInitialDefault] = useState(defaultValue);

  if (initialDefault != defaultValue) {
    setInitialDefault(defaultValue);
    setCSS(defaultValue);
  }

  const handleEditorDidMount = (editor: any) => {
    editorRef.current = editor;
    editor.focus();
  };

  function handleRemove() {
    setValue("");
    if (onChange) onChange("");
  }

  const handleUpdate = () => {
    if (editorRef.current) {
      const content = editorRef.current.getValue();
      setCSS(content);
      if (onChange) onChange(content);
    }
  };

  return (
    <Settings title={title as string}>
      <SettingsSection>
        <Editor
          height="70vh"
          theme="vs-light"
          options={{
            wordWrap: "on",
            minimap: {
              enabled: false,
            },
            lineDecorationsWidth: 0,
            lineNumbersMinChars: 0,
            automaticLayout: true,
            fixedOverflowWidgets: true,
          }}
          language="scss"
          value={css}
          defaultValue="//css goes here"
          onMount={handleEditorDidMount}
          onChange={(value) => setValue(value as string)}
        />
        <div className="div-customcss-container">
          <Button variant="outline" onPress={handleUpdate}>
            Update
          </Button>
          <Button variant="outline" onPress={handleRemove}>
            Remove
          </Button>
        </div>
      </SettingsSection>
    </Settings>
  );
};
