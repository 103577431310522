import { Themer } from '@/app/Themer'
import { FontLoader } from '@/components/widgets/FontLoader/FontLoader'
import { createFileRoute } from '@tanstack/react-router'
import { observer } from 'mobx-react-lite'

export const ThemerRoute = observer(() => {
    return (
        <div className='flex h-full w-full'>
            <FontLoader/>
            <Themer/>
        </div>
    )
})

export const Route = createFileRoute('/_theme-editor')({
    component: ThemerRoute
})