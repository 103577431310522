import { Button } from "@/components/Button"
import { Modal } from "@/components/Modal/Modal"
import { Dialog, DialogTrigger } from "react-aria-components"
import { MediaUploader } from "./MediaUploader"


export const MediaUploadButton = () => {
    return (
        <DialogTrigger>
            <Button>UPLOAD IMAGES</Button>
            <Modal className="flex max-w-[1200px] w-[80vw] bg-gray-50 min-h-0 h-[70vh] overflow-hidden" isDismissable>
                <Dialog className="outline-none w-full">
                    <MediaUploader/>
                </Dialog>
            </Modal>
        </DialogTrigger>
    )
}