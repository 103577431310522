import { Preview } from "./Preview"
import { useThemeStore } from "@/stores/Theme"
import { useEffect } from "react"
import { useUser } from "@/stores/Auth"
import { observer } from "mobx-react-lite"
import { Header } from "./Header"
import { SettingsSideBar } from "./settings/SettingsSideBar"

export const Themer = observer(() => {
    const user = useUser()
    const theme = useThemeStore()

    useEffect(() => {
        if (!user) return
        theme.load()
    }, [theme, user])

    return <>
        <SettingsSideBar/>
        <div className='flex flex-col flex-grow h-full min-h-0 min-w-0'>
            <Header/>
            <Preview/>
        </div>
    </>
})