import {Color, ColorPickerStateContext, ColorSwatch, type ColorSwatchProps} from 'react-aria-components'

import './ColorSwatch.css'
import { useContext } from 'react'

export function HColorSwatch({hasColor, ...props}: ColorSwatchProps & {hasColor: boolean}) {
    if (!hasColor) return (
        <div className="react-aria-ColorSwatch items-center content-center bg-slate-700">
            ADD A COLOR
        </div>
    )

    return (
        <ColorSwatch
            {...props}
            style={({color}) => ({
                background: `linear-gradient(${color}, ${color}),
            repeating-conic-gradient(#CCC 0% 25%, white 0% 50%) 50% / 16px 16px`
            })} />
    );
}