import { observer } from "mobx-react-lite"
import { Settings } from "./_Settings"
import { ThemeSelector } from "@/components/ThemeSelector/ThemeSelector"
import { SettingsSection } from "@/features/themer/components/SettingsSection"

export const ThemeSelectorSettings = observer(() => {
    return (
        <Settings title="THEME SELECTOR">
            <SettingsSection label="BROWSE THEMES">
                <ThemeSelector />
            </SettingsSection>
        </Settings>
    )
});