import { Helmet } from 'react-helmet-async'
import { formatFonts } from './util'
import { HeaderFonts } from '@/app/lib/typography/HeaderFonts'
import { BodyFonts } from '@/app/lib/typography/BodyFonts'
import { observer } from 'mobx-react-lite'



export const FontLoader = observer(() => {
    const options = [...HeaderFonts, ...BodyFonts]

    const fonts = formatFonts(options)
    const fontsLoaded = fonts.names.length > 0

    return fontsLoaded ? (
        <Helmet data-testid='helmet'>
            <link
                id='gfonts'
                href={`https://fonts.googleapis.com/css?family=${fonts.names}&text=${fonts.text}&display=swap`}
                rel='stylesheet'
            />
        </Helmet>
    ) : null
})