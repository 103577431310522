import React from 'react'
import {
    ComboBox as AriaComboBox,
    ComboBoxProps as AriaComboBoxProps,
    ComboBoxStateContext,
    ListBoxItemProps,
    ValidationResult
} from 'react-aria-components'
import { Button } from '../Button'
import { Description, FieldError, FieldGroup, Input, Label } from '../Field'
import { DropdownItem, DropdownSection, DropdownSectionProps, ListBox } from '../ListBox'
import { Popover } from '../Popover'
import { composeTailwindRenderProps } from '../utils'
import { ArrowDropDown, Clear } from '@material-ui/icons'

export interface ComboBoxProps<T extends object> extends Omit<AriaComboBoxProps<T>, 'children'> {
    label?: string
    description?: string | null
    errorMessage?: string | ((validation: ValidationResult) => string)
    children: React.ReactNode | ((item: T) => React.ReactNode)
    canClear?: boolean
}

export function ComboBox<T extends object>(
    { label, description, errorMessage, children, items, canClear = false, ...props }: ComboBoxProps<T>
) {
    return (
        <AriaComboBox {...props} className={'group flex flex-col gap-1'}>
            <Label>{label}</Label>
            <FieldGroup>
                <Input />
                {canClear && <ComboBoxClearButton/>}
                <Button variant="icon" className="w-6 mr-1 rounded outline-offset-0 ">
                    <ArrowDropDown aria-hidden className="w-4 h-4" />
                </Button>
            </FieldGroup>
            {description && <Description>{description}</Description>}
            <FieldError>{errorMessage}</FieldError>
            <Popover className="w-[--trigger-width]">
                <ListBox items={items} className="outline-0 max-h-[inherit] overflow-auto">
                    {children}
                </ListBox>
            </Popover>
        </AriaComboBox>
    )
}

export function ComboBoxItem(props: ListBoxItemProps) {
    return <DropdownItem {...props} />
}

export function ComboBoxSection<T extends object>(props: DropdownSectionProps<T>) {
    return <DropdownSection {...props} />
}

function ComboBoxClearButton() {
    const state = React.useContext(ComboBoxStateContext)
    
    return state?.selectedItem ?
        <Button
            // Don't inherit default Button behavior from ComboBox.
            variant='icon'
            slot={null}
            className="clear-button"
            aria-label="Clear"
            onPress={() => state?.setSelectedKey(null)}
        >
            <Clear/>
        </Button> : null
}