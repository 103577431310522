import { focusRing } from "@/components/utils"
import { ReactElement } from "react"
import { composeRenderProps, Group, GroupProps, Label } from "react-aria-components"
import { tv } from "tailwind-variants"

export interface SettingsSectionProps {
    label?: string
    children: ReactElement | ReactElement[]
}

export const SettingsSection = (props: SettingsSectionProps) => 
    <FieldGroup>
        {props.label && <Label className="text-sm font-semibold text-gray-400 mb-2">{props.label}</Label>}
        {props.children}
    </FieldGroup>

export const fieldBorderStyles = tv({
    base: "bg-inherit",
    variants: {
        isInvalid: {
            true: 'border-red-600 dark:border-red-600 forced-colors:border-[Mark]'
        },
        isDisabled: {
            true: 'border-gray-200 dark:border-zinc-700 forced-colors:border-[GrayText]'
        }
    }
})

const fieldGroupStyles = tv({
    base: 'group flex flex-col min-h-0 p-4 overflow-hidden',
    variants: fieldBorderStyles.variants
})

function FieldGroup(props: GroupProps) {
    return <Group {...props} className={composeRenderProps(props.className, (className, renderProps) => fieldGroupStyles({...renderProps, className}))} />;
}