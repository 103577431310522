import { SideBar } from "./SideBar"
import { observer } from "mobx-react-lite"
import { Button } from "@/components/Button"
import { useNavigate } from "@tanstack/react-router"
import { ChevronLeft } from "@material-ui/icons"
import { HistoryTable } from "@/features/history/HistoryTable"

export const VersionsPage = observer(() => {
    const navigate = useNavigate()

    return <>
        <SideBar>
            <Button variant="link" onPress={() => navigate({to: "/"})}><ChevronLeft/>VERSIONS</Button>
        </SideBar>
        <div className="bg-slate-50 p-8 w-full overflow-auto">
            <div className="mb-4">
                <h1 className="text-3xl font-semibold mb-2">Versions</h1>
                <span className="text-gray-600">Versions let you quickly restore previously saved versions of your site. Simply choose Restore to first edit any previously saved version and publish at any time.</span>
            </div>
            <div className="rounded-md border bg-white">
                <HistoryTable/>
            </div>
        </div>
    </>
})