export const BodyFonts = [
    {
        family: "ABeeZee,sans-serif",
        name: "ABeeZee",
    },
    {
        family: "Abel,sans-serif",
        name: "Abel",
    },
    {
        family: "Andika,sans-serif",
        name: "Andika",
    },
    {
        family: "\"Anonymous Pro\",monospace",
        name: "Anonymous Pro",
    },
    {
        family: "\"Averia Sans Libre\",fantasy",
        name: "Averia Sans Libre",
    },
    {
        family: "\"Barlow Semi Condensed\",sans-serif",
        name: "Barlow Semi Condensed",
    },
    {
        family: "Bitter,serif",
        name: "Bitter",
    },
    {
        family: "\"Cormorant Garamond\",serif",
        name: "Cormorant Garamond",
    },
    {
        family: "\"Crimson Text\",serif",
        name: "Crimson Text",
    },
    {
        family: "\"Didact Gothic\",sans-serif",
        name: "Didact Gothic",
    },
    {
        family: "Dosis,sans-serif",
        name: "Dosis",
    },
    {
        family: "\"Fanwood Text\",serif",
        name: "Fanwood Text",
    },
    {
        family: "\"Gothic A1\",sans-serif",
        name: "Gothic A1",
    },
    {
        family: "Imprima,sans-serif",
        name: "Imprima",
    },
    {
        family: "Inconsolata,monospace",
        name: "Inconsolata",
    },
    {
        family: "\"Josefin Sans\",sans-serif",
        name: "Josefin Sans",
    },
    {
        family: "\"Josefin Slab\",serif",
        name: "Josefin Slab",
    },
    {
        family: "Jost,sans-serif",
        name: "Jost",
    },
    {
        family: "Lato,sans-serif",
        name: "Lato",
    },
    {
        family: "\"Libre Baskerville\",serif",
        name: "Libre Baskerville",
    },
    {
        family: "Lora,serif",
        name: "Lora",
    },
    {
        family: "\"Maven Pro\",sans-serif",
        name: "Maven Pro",
    },
    {
        family: "Montserrat,sans-serif",
        name: "Montserrat",
    },
    {
        family: "Nunito,sans-serif",
        name: "Nunito",
    },
    {
        family: "\"Open Sans\",sans-serif",
        name: "Open Sans",
    },
    {
        family: "Oswald,sans-serif",
        name: "Oswald",
    },
    {
        family: "Oxygen,sans-serif",
        name: "Oxygen",
    },
    {
        family: "\"Pontano Sans\",sans-serif",
        name: "Pontano Sans",
    },
    {
        family: "Questrial,sans-serif",
        name: "Questrial",
    },
    {
        family: "Quicksand,sans-serif",
        name: "Quicksand",
    },
    {
        family: "Raleway,sans-serif",
        name: "Raleway",
    },
    {
        family: "\"Red Hat Text\",sans-serif",
        name: "Red Hat Text",
    },
    {
        family: "Roboto,sans-serif",
        name: "Roboto",
    },
    {
        family: "\"Roboto Condensed\",sans-serif",
        name: "Roboto Condensed",
    },
    {
        family: "\"Roboto Mono\",monospace",
        name: "Roboto Mono",
    },
    {
        family: "\"Roboto Slab\",serif",
        name: "Roboto Slab",
    },
    {
        family: "Rubik,sans-serif",
        name: "Rubik",
    },
    {
        family: "Ruda,sans-serif",
        name: "Ruda",
    },
    {
        family: "\"Scope One\",serif",
        name: "Scope One",
    },
    {
        family: "Shanti,sans-serif",
        name: "Shanti",
    },
    {
        family: "\"Source Code Pro\",monospace",
        name: "Source Code Pro",
    },
    {
        family: "\"Space Mono\",monospace",
        name: "Space Mono",
    },
    {
        family: "Stylish,sans-serif",
        name: "Stylish",
    },
    {
        family: "Sunflower,sans-serif",
        name: "Sunflower",
    },
    {
        family: "\"Tenor Sans\",sans-serif",
        name: "Tenor Sans",
    },
    {
        family: "Trykker,serif",
        name: "Trykker",
    },
    {
        family: "\"Work Sans\",sans-serif",
        name: "Work Sans",
    },
]