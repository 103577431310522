import { SearchField } from "@/components/SearchField/SearchField"
import { HeaderContainer } from "../../../components/widgets/HeaderContainer"
import { MediaList, MediaListProps } from "../MediaList/MediaList"
import { useMemo, useState } from "react"
import Fuse from 'fuse.js'
import { MediaUploadButton } from "../MediaUploader/MediaUploaderModal"
import { FileMeta } from "@/client"

export interface MediaLibraryProps extends Pick<MediaListProps, 'onChange'> {
    files?: FileMeta[]
    selected?: string
    rendersDetailDrawer?: boolean
}

export const MediaLibrary = ({files, rendersDetailDrawer = true, selected, ...otherProps}: MediaLibraryProps) => {
    const [search, setSearch] = useState("")

    files = files ?? []

    const fuse = useMemo(() => {
        console.log("Making fuse")
        return new Fuse(files, {keys: ['name'], threshold: 0.3})
    }, [files])

    const filtered = search ? fuse.search(search).map(r => r.item) : files

    return (
        <div className="flex flex-col h-full w-full">
            <HeaderContainer>
                <SearchField value={search} onChange={setSearch}/>
                <MediaUploadButton/>
            </HeaderContainer>
            <MediaList files={filtered} selectedKeys={selected ? [selected] : []} rendersDetailDrawer={rendersDetailDrawer} {...otherProps} />
        </div>
    )
}