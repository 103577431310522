import React from 'react'
import {
    Switch as AriaSwitch,
    SwitchProps as AriaSwitchProps
} from 'react-aria-components'
import { tv } from 'tailwind-variants'
import { composeTailwindRenderProps, focusRing } from '../utils'
import { Label } from '../Field'

export interface SwitchProps extends Omit<AriaSwitchProps, 'children'> {
  children: React.ReactNode
  mode?: "light" | "dark"
}

const track = tv({
    extend: focusRing,
    base: 'flex h-4 w-10 items-center shrink-0 cursor-default rounded-full transition duration-200 ease-in-out shadow-inner border border-transparent',
    variants: {
        isSelected: {
            false: 'bg-zinc-500 dark:bg-zinc-400 group-pressed:bg-gray-500 dark:group-pressed:bg-zinc-300',
            true: 'bg-teal-900 group-pressed:bg-gray-800 dark:group-pressed:bg-zinc-200',
        },
        isDisabled: {
            true: 'bg-gray-200 dark:bg-zinc-700 forced-colors:group-selected:!bg-[GrayText] forced-colors:border-[GrayText]',
        },
        mode: {
            light: "text-gray-800",
            dark: ""
        }
    }
});

const handle = tv({
    base: 'h-5 w-5 transform rounded-full bg-white dark:bg-zinc-900 outline outline-1 -outline-offset-1 outline-transparent shadow transition duration-200 ease-in-out',
    variants: {
        isSelected: {
            false: 'translate-x-0 bg-zinc-400',
            true: 'translate-x-[100%] bg-teal-600'
        },
        isDisabled: {
            true: 'forced-colors:outline-[GrayText]'
        },
        mode: {
            light: "text-gray-800",
            dark: ""
        }
    }
});

const label = tv({
    variants: {
        mode: {
            light: "text-gray-600",
            dark: ""
        }
    }
})

export function Switch({ children, ...props }: SwitchProps) {
    return (
        <AriaSwitch {...props} className={composeTailwindRenderProps(props.className, 'flex items-center h-10 gap-2 text-gray-200 disabled:text-gray-300 forced-colors:disabled:text-[GrayText] text-sm transition')}>
            {(renderProps) => (
                <>
                    <div className={track({mode: props.mode, ...renderProps})}>
                        <span className={handle({mode: props.mode, ...renderProps})} />
                    </div>
                    <Label className={label({mode: props.mode})}>{children}</Label>
                </>
            )}
        </AriaSwitch>
    );
}
