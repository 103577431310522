import { createFileRoute } from '@tanstack/react-router'
import 'vite/modulepreload-polyfill'
import { observer } from 'mobx-react-lite'
import { useAuth } from '@/stores/Auth'
import { LoginPage } from '@/app/Login'
import { MediaLibraryPage } from '@/app/MediaLibraryPage'

const Component = observer(() => {
    const auth = useAuth()

    return (
        <div className='flex h-full w-full'>
            {auth.user ? <MediaLibraryPage/> : <LoginPage/>}
        </div>
    )
})

export const Route = createFileRoute('/media-library')({
    component: Component
})