import { HColorPicker } from "@/components/ColorPicker/ColorPicker"
import { Switch } from "@/components/Switch/Switch"
import { useThemeStore } from "@/stores/Theme"
import { observer } from "mobx-react-lite"
import { Settings } from "./_Settings"
import { SettingsSection } from "@/features/themer/components/SettingsSection"

export const Colors = observer(() => {
    const theme = useThemeStore()

    const [getTransparent, setTransparent] = theme.bind(p => p.theme.template.components.general.styles.transparent)
    const [getPrimaryColor, setPrimaryColor] = theme.bind(p => p.theme.template.colors.primary)
    const [getDark, setDark] = theme.bind(p => p.theme.template.components.general.styles.dark)
    const [getSecondaryColor, setSecondaryColor] = theme.bind(p => p.theme.template.colors.secondary)


    const [getDarkColor, setDarkColor] = theme.bind(p => p.theme.template.colors.neutralDark)
    const [getLightColor, setLightColor] = theme.bind(p => p.theme.template.colors.neutralLight)

    return (
        <Settings title="COLORS">
            <SettingsSection label='PRIMARY BRAND COLOR'>
                <HColorPicker
                    defaultValue={getPrimaryColor()}
                    onChange={(value) => setPrimaryColor(value?.toString("hex") ?? "")}
                />
            </SettingsSection>
            <SettingsSection>
                <HColorPicker
                    label='SECONDARY BRAND COLOR'
                    defaultValue={getSecondaryColor()}
                    onChange={value => setSecondaryColor(value?.toString("hex") ?? "")}
                />
            </SettingsSection>
            <SettingsSection>
                <HColorPicker
                    label='DARK COLOR'
                    defaultValue={getDarkColor()}
                    onChange={(value) => setDarkColor(value?.toString("hex") ?? "")}
                />
            </SettingsSection>
            <SettingsSection>
                <HColorPicker
                    label='LIGHT COLOR'
                    defaultValue={getLightColor()}
                    onChange={(value) => setLightColor(value?.toString("hex") ?? "")}
                />
            </SettingsSection>
            <SettingsSection label="STYLE">
                <Switch
                    isSelected={getDark()}
                    onChange={setDark}
                >
                            Swap light and dark
                </Switch>
                <Switch
                    isSelected={getTransparent()}
                    onChange={setTransparent}
                >
                            Transparent
                </Switch>
            </SettingsSection>
        </Settings>
    )
})