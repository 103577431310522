import { observer } from "mobx-react-lite"
import { Outlet } from "@tanstack/react-router"

interface SideBarProps {
    children?: React.ReactElement | React.ReactElement[]
}

export const SideBar = observer(({children}: SideBarProps) => {
    return (
        <div className='h-full shrink-0 basis-[350px] max-w-[500px] flex flex-col grow-[0.20] bg-slate-800 border-r border-r-slate-500 text-white overflow-y-auto overflow-x-hidden scroller'>
            {children ? children : <Outlet/>}
            <div className="h-[60px] shrink-0"/>
        </div>
    )
})