import { FormEvent } from "react"
import { Button } from "../Button/Button"
import { Form } from "../Form/Form"
import { TextField } from "../TextField/TextField"
import { useAuth } from "@/stores/Auth"
import { useMutation } from "@tanstack/react-query"

export function Login() {
    const auth = useAuth()

    const {mutate, isPending} = useMutation({
        mutationFn: ({username, password}: {username: string, password: string}) =>  auth.login(username, password)
    })

    const login = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const {username, password} = Object.fromEntries(new FormData(e.currentTarget)) as any
        mutate({username, password})
    }

    return (
        <Form onSubmit={login}>
            <TextField label="Username" name="username" type="email" isRequired />
            <TextField label="Password" name="password" type="password" isRequired />
            <Button isLoading={isPending} isDisabled={isPending} type="submit" className="flex">SIGN IN</Button>
        </Form>
    )
}