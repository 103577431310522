import { observable } from "mobx"
import { createUseStore } from "./util"

function getGlobalAssetsUrl(): string {
    return window.designer && window.designer.globalAssets;
}

export class AppStore {
    @observable accessor globalAssetsUrl: string = ""

    constructor() {
        const globalAssetsUrl = getGlobalAssetsUrl()
        if (globalAssetsUrl) this.globalAssetsUrl = globalAssetsUrl
    }
}

export const [AppProvider, useApp] = createUseStore(AppStore)
export const useGlobalAssetsUrl = () => useApp()?.globalAssetsUrl