import React from 'react';

interface Props {
  className?: string;
}

const Logo: React.FC<Props> = (props: Props) => {
    const { className = 'Logo' } = props;

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            data-cy='auth__title--logo'
            viewBox='115 0 670 185'
            className={className}>
            <path
                className='a'
                d='M279.7047,32.4061h-8.2991V50.252c0,3.5554-2.1206,6.0512-5.8016,6.0512-3.8693,0-5.6164-2.7454-5.6164-6.1768v-17.72H251.69V51.8107c0,6.676,4.2413,12.1056,11.6676,12.1056,3.2446,0,6.8015-1.2479,8.4858-4.1189a20.9292,20.9292,0,0,0,.25,3.3074h7.9239a50.27,50.27,0,0,1-.3124-5.5537Z'
                fill='#fff'
            />
            <path
                className='a'
                d='M304.7179,31.5945c-3.3074,0-6.9883,1.4347-8.861,4.6181V32.4061h-8.0494V63.1048h8.299V45.4471c0-3.5569,2.1222-6.3651,5.74-6.3651,3.9933,0,5.6776,2.6842,5.6776,6.1156V63.1048h8.299V43.7629C315.8235,37.0226,312.3294,31.5945,304.7179,31.5945Z'
                fill='#fff'
            />
            <path
                className='a'
                d='M411.7229,32.1565c-2.62,0-6.8643.7487-8.7354,4.8049V32.4061h-8.0494V63.1048h8.299V49.0653c0-6.6131,3.681-8.6726,7.9239-8.6726a12.5209,12.5209,0,0,1,2.4958.25v-8.36A18.7615,18.7615,0,0,0,411.7229,32.1565Z'
                fill='#fff'
            />
            <path
                className='a'
                d='M344.5915,36.0242c-1.0611-2.0577-3.8693-4.18-8.7354-4.18-8.4246,0-14.289,6.8642-14.289,14.975,0,8.6111,6.1156,14.9135,14.289,14.9135,4.43,0,7.3006-1.9352,8.547-3.932v2.2464c0,5.9268-2.9322,8.5487-8.1735,8.5487a6.8568,6.8568,0,0,1-7.0643-5.3666h-7.6832c.0108.9187.085,1.6845.085,1.6845l.024-.0045c.9316,5.8169,6.523,10.9871,14.8882,10.9871,11.5435,0,16.0988-7.6745,16.0988-16.0988V32.406h-7.9866Zm-7.3007,18.5947c-4.3057,0-7.3635-2.995-7.3635-7.8,0-4.7422,3.2462-7.7373,7.3635-7.7373,3.9933,0,7.2379,2.9951,7.2379,7.7373S341.5337,54.6189,337.2908,54.6189Z'
                fill='#fff'
            />
            <path
                className='a'
                d='M235.0961,36.649v.3319a23.2315,23.2315,0,0,1-19.2806-.0215V18.8658h-8.61v44.239h8.61V45.9639a31.6184,31.6184,0,0,0,19.2806.02V63.1048h8.6726V18.8658h-8.6726Z'
                fill='#fff'
            />
            <path
                className='a'
                d='M373.5713,31.4706c-7.861,0-15.1005,6.3636-15.1005,16.16,0,10.3568,7.4263,16.41,15.8492,16.41,7.55,0,12.4164-4.43,13.9767-9.7337h-8.0351a6.8935,6.8935,0,0,1-5.8787,2.6214,7.4765,7.4765,0,0,1-7.7371-6.9886H388.609c0-.1255.124-1.3733.124-2.5583C388.733,37.5219,383.0554,31.4706,373.5713,31.4706Zm-6.7387,12.7915a6.6425,6.6425,0,0,1,6.8643-6.0528c4.74,0,6.7372,2.995,6.8627,6.0528Z'
                fill='#fff'
            />
            <path
                className='a'
                d='M174.1083,25.2178a1.206,1.206,0,0,1-.7715-.6373,23.76,23.76,0,0,0-42.3234,0,1.2062,1.2062,0,0,1-.7716.6373c-7.5031,2.0481-12.69,8.8837-12.69,17.237,0,7.37,5.9865,13.3664,11.5479,15.82a30.0283,30.0283,0,0,0,3.1949,1.18.7037.7037,0,0,0,.9068-.6734V52.539a1.0827,1.0827,0,0,0-.6768-.9967q-.212-.0867-.3848-.1631c-2.7264-1.2023-7.0525-4.6885-7.0525-8.9244,0-5.4419,3.7052-9.7634,8.8094-10.2748l1.5931-.16a1.3336,1.3336,0,0,0,1.1382-.9241l.4836-1.5257c1.883-5.9358,8.3588-10.4109,15.0636-10.4109s13.1806,4.4751,15.0635,10.4109l.4836,1.5257a1.3337,1.3337,0,0,0,1.1383.9241l1.593.16c5.1043.5114,8.81,4.8329,8.81,10.2748,0,4.2359-4.3261,7.7221-7.0526,8.9244q-.1725.0761-.3847.1631a1.0827,1.0827,0,0,0-.6768.9967v6.2416a.7037.7037,0,0,0,.9068.6734,30.0364,30.0364,0,0,0,3.1949-1.18c5.5614-2.4532,11.5478-8.45,11.5478-15.82C186.7984,34.1015,181.6114,27.2659,174.1083,25.2178Z'
                fill='#fff'
            />
            <path
                className='a'
                d='M166.2809,41.5588h-5.27a1.1324,1.1324,0,0,0-1.1324,1.1325v11.394c-2.2381.2075-4.7942.3368-7.7029.3368s-5.4641-.1293-7.702-.3367V42.6913a1.1325,1.1325,0,0,0-1.1325-1.1325h-5.27a1.1325,1.1325,0,0,0-1.1325,1.1325V69.2141a1.1325,1.1325,0,0,0,1.1325,1.1325h5.27a1.1325,1.1325,0,0,0,1.1325-1.1325V61.6467c2.4417.2039,5.0182.3108,7.702.3108s5.2609-.1069,7.7029-.3109v7.5675a1.1324,1.1324,0,0,0,1.1324,1.1325h5.27a1.1325,1.1325,0,0,0,1.1325-1.1325V42.6913A1.1325,1.1325,0,0,0,166.2809,41.5588Z'
                fill='#fff'
            />
            <path
                className='a'
                d='M480.5429,33.092h-5.8644V51.1876c0,4.5552-2.37,7.6742-7.1139,7.6742-4.9289,0-6.8-3.6181-6.8-7.737V33.092h-5.8659V51.9975c0,6.8015,4.2429,12.0428,11.2312,12.0428,3.4941,0,7.2379-1.4347,8.861-4.5553a26.4316,26.4316,0,0,0,.25,3.62h5.6148a50.7069,50.7069,0,0,1-.3123-5.5537Z'
                fill='#fff'
            />
            <path
                className='a'
                d='M529.109,32.2193A10.615,10.615,0,0,0,520,36.5862V17.93h-5.866V63.1048H520V45.4471c.124-4.4312,2.4958-7.9254,6.9883-7.9254,5.24,0,6.9883,3.37,6.9883,7.55V63.1048h5.8644V44.0737C539.841,37.3977,536.2841,32.2193,529.109,32.2193Z'
                fill='#fff'
            />
            <path
                className='a'
                d='M445.02,56.1425c-5.6-2.0143-8.4181-7.7267-9.6827-11.3512l3.3423-.2185c6.676-1.2479,10.7305-6.1768,10.7305-12.5419,0-7.2991-5.1784-13.1651-13.5387-13.1651H419.212v44.239h6.1768V45.0092h3.6063c1.2693,4.435,4.9229,13.6308,13.956,16.8806a19.0054,19.0054,0,0,0,6.4716,1.1063c.1743,0,.3492-.0031.5242-.0077l-.1537-6.1049A13.0846,13.0846,0,0,1,445.02,56.1425Zm-19.631-31.7859h9.3585c4.9917,0,8.3618,2.995,8.3618,7.6743,0,4.5553-3.37,7.4875-8.3618,7.4875h-9.3585Z'
                fill='#fff'
            />
            <path
                className='a'
                d='M500.763,45.8206l-4.8676-1.0594c-2.3091-.4991-3.4314-1.81-3.4314-3.62,0-2.06,1.9966-3.9932,4.9917-3.9932a5.867,5.867,0,0,1,5.3049,2.5891H508.75c-.8007-2.7922-3.5109-7.5809-11.2944-7.5809-5.8661,0-10.6693,4.3668-10.6693,9.4841,0,4.2428,2.8066,7.4246,7.4875,8.4228l5.1156,1.1242c2.4958.5618,3.8693,1.9967,3.8693,3.8676,0,2.1224-1.81,3.9933-5.1172,3.9933-3.2726,0-5.4189-1.4913-6.4069-3.6014h-5.8579c.5719,3.4348,4.0759,8.5932,12.2021,8.5932,7.4247,0,10.9815-5.0546,10.9815-9.2959C509.06,50.3133,506.1911,47.0056,500.763,45.8206Z'
                fill='#fff'
            />
            <polygon
                className='a'
                points='537.385 18.795 539.131 18.795 539.131 23.752 540.162 23.752 540.162 18.795 541.909 18.795 541.909 17.93 537.385 17.93 537.385 18.795'
                fill='#fff'
            />
            <polygon
                className='a'
                points='547.764 17.93 545.826 21.448 543.888 17.93 542.874 17.93 542.874 23.752 543.905 23.752 543.905 19.735 545.51 22.696 546.142 22.696 547.747 19.735 547.747 23.752 548.778 23.752 548.778 17.93 547.764 17.93'
                fill='#fff'
            />
            <path
                className='a'
                d='M562.4878,36.3647l12.0284-10.7524H554.6923V18.5551h30.2429v6.72L573.1075,35.6246c6.5161.1344,13.0356,4.8386,13.0356,13.2381,0,7.46-5.9819,14.2469-16.3294,14.2469-10.0821,0-16.0605-6.5178-16.5982-13.7757l7.86-1.6128a8.4351,8.4351,0,0,0,8.67,8.4675c5.3081,0,8.2651-3.4265,8.2651-7.3923,0-5.041-4.0322-7.1915-7.7275-7.1915a9.5277,9.5277,0,0,0-4.1.74Z'
                fill='#fff'
            />
            <path
                className='a'
                d='M619.9554,26.2826a14.9665,14.9665,0,0,0-7.462-1.7456c-8.1971,0-14.1109,5.4426-14.8527,13.91,2.2866-3.4945,6.4515-5.1755,11.0893-5.1755,8.67,0,14.7846,5.7812,14.7846,14.7183,0,9.0732-7.3906,15.12-16.53,15.12-11.0892,0-17.408-9.0715-17.408-20.2271,0-15.7935,9.8814-25.3346,23.39-25.3346a18.09,18.09,0,0,1,9.2723,2.1505Zm-12.971,29.9741c4.57,0,8.4659-3.0931,8.4659-8.1324,0-5.1074-3.8961-8.1324-8.4659-8.1324s-8.4692,3.025-8.4692,8.1324C598.5152,53.0973,602.5474,56.2567,606.9844,56.2567Z'
                fill='#fff'
            />
            <path
                className='a'
                d='M631.7933,24.67c2.8242-4.2329,7.394-7.1218,13.9135-7.1218,6.5161,0,11.0178,2.8889,13.842,7.1218,2.9569,4.4371,3.8995,9.88,3.8995,15.6591s-.9426,11.222-3.8995,15.659c-2.8242,4.233-7.3259,7.1218-13.842,7.1218-6.5195,0-11.0893-2.8888-13.9135-7.1218-2.9569-4.437-3.8961-9.88-3.8961-15.659S628.8364,29.1068,631.7933,24.67Zm7.19,28.0924a8.6132,8.6132,0,0,0,13.44,0c2.0859-2.6881,3.025-6.9227,3.025-12.4333s-.9391-9.7453-3.025-12.4334a8.6132,8.6132,0,0,0-13.44,0c-2.1505,2.6881-3.09,6.9228-3.09,12.4334S636.9007,50.074,638.9831,52.7621Z'
                fill='#fff'
            />
            <path
                className='a'
                d='M207.2056,159.95V85.8411h25.9215c18.8134,0,35.2244,12.857,35.2244,37.21,0,24.2508-16.6186,36.8985-35.4319,36.8985Zm25.3975-9.0954c13.6958,0,25.1934-9.1974,25.1934-27.8031,0-18.7079-11.29-28.1163-24.9824-28.1163H217.4477v55.9194Z'
                fill='#fff'
            />
            <path
                className='a'
                d='M323.4407,145.631c-2.8242,8.8844-10.8715,15.8871-22.58,15.8871-13.4814,0-25.2954-9.8269-25.2954-26.864,0-15.7816,11.3955-26.551,24.0432-26.551,15.4685,0,24.1453,10.6639,24.1453,26.3434a21.881,21.881,0,0,1-.2109,3.1338H285.7051c.211,8.99,6.69,15.261,15.1555,15.261,8.1528,0,12.2326-4.4949,14.1108-10.14Zm-9.9324-15.9925c-.2076-7.2136-4.91-12.8586-13.7944-12.8586a13.5272,13.5272,0,0,0-13.6957,12.8586Z'
                fill='#fff'
            />
            <path
                className='a'
                d='M339.9538,143.7494c.63,5.2265,4.6,9.4049,11.7086,9.4049,5.54,0,8.5713-3.1338,8.5713-6.69,0-3.1339-2.3-5.54-6.4821-6.4787l-8.5713-1.8816c-7.84-1.6742-12.5422-7.0027-12.5422-14.1109,0-8.5713,8.0473-15.89,17.8742-15.89,13.7978,0,18.0817,8.99,19.1264,13.4847l-8.6734,3.2428c-.4186-2.6133-2.5112-8.3638-10.453-8.3638-5.0189,0-8.3638,3.2393-8.3638,6.69,0,3.0318,1.8817,5.2265,5.75,6.0636l8.1528,1.7762c9.0919,1.9871,13.9033,7.5267,13.9033,14.9479,0,7.1082-5.9581,15.574-18.3982,15.574-13.7978,0-19.65-8.8844-20.4874-14.6349Z'
                fill='#fff'
            />
            <path
                className='a'
                d='M385.3862,83.5443a7.0548,7.0548,0,1,1-7.0061,7.1048A7.0455,7.0455,0,0,1,385.3862,83.5443ZM380.5748,159.95V109.6717h9.7214V159.95Z'
                fill='#fff'
            />
            <path
                className='a'
                d='M410.1134,160.4735a13.3624,13.3624,0,0,0,13.6923,12.3312c10.5585,0,15.3664-5.434,15.3664-16.3055v-7.21c-2.4057,4.597-7.84,8.2549-15.3664,8.2549-13.3793,0-23.1007-10.1366-23.1007-24.3529,0-13.5868,9.3029-24.4583,23.1007-24.4583,7.7342,0,12.9607,2.9262,15.4685,7.9418v-7.0027h9.7214v46.5144c0,12.7532-6.2711,25.19-25.19,25.19-12.5422,0-21.9506-7.9418-23.2062-18.7078Zm29.4738-27.2826c0-9.7214-5.8525-16.098-14.3184-16.098-8.6768,0-14.5293,6.3766-14.5293,16.098,0,9.8269,5.645,16.098,14.5293,16.098C433.7347,149.2889,439.5872,142.9123,439.5872,133.1909Z'
                fill='#fff'
            />
            <path
                className='a'
                d='M470.8441,159.95h-9.8235V109.6717h9.616v7.2136a17.2191,17.2191,0,0,1,15.4685-8.6768c12.0216,0,17.98,8.6768,17.98,19.8614v31.88h-9.8269V129.7406c0-7.0027-2.9263-12.6477-11.7052-12.6477-7.7377,0-11.7086,6.169-11.7086,13.7978Z'
                fill='#fff'
            />
            <path
                className='a'
                d='M560.95,145.631c-2.8242,8.8844-10.8715,15.8871-22.58,15.8871-13.4813,0-25.2954-9.8269-25.2954-26.864,0-15.7816,11.3955-26.551,24.0432-26.551,15.4685,0,24.1453,10.6639,24.1453,26.3434a21.8663,21.8663,0,0,1-.2109,3.1338H523.2145c.211,8.99,6.69,15.261,15.1555,15.261,8.1528,0,12.2292-4.4949,14.1109-10.14Zm-9.9324-15.9925c-.2075-7.2136-4.91-12.8586-13.7978-12.8586a13.5263,13.5263,0,0,0-13.6923,12.8586Z'
                fill='#fff'
            />
            <path
                className='a'
                d='M599.9991,119.6041a30.3766,30.3766,0,0,0-4.284-.3165c-8.2582,0-13.7978,4.3929-13.7978,15.4719v25.19H572.09V109.6717H581.71v8.7823c3.6578-7.7377,9.9289-9.616,15.05-9.616a18.3811,18.3811,0,0,1,3.2394.3131Z'
                fill='#fff'
            />
        </svg>
    );
};

export default Logo
