import { action, observable } from "mobx"
import { createUseStore } from "./util"


export class PreviewStore {
    @observable accessor device = DeviceType.Computer

    constructor() {}

    @action
    setDevice(device: DeviceType) {
        this.device = device
    }

    get userAgent() {
        if (this.device == DeviceType.Mobile)
            return `Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1`
        else
            return ""
    }
}
export const [PreviewCtx, usePreviewStore] = createUseStore(PreviewStore)

export enum DeviceType {
    Computer = "Computer",
    Tablet = "Tablet",
    Mobile = "Mobile",
}