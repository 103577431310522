import { ComboBox, ComboBoxProps } from "@/components/ComboBox/ComboBox"
import { SelectItem } from "@/components/Select"

type FontOption = {family: string, name: string}

export interface FontSelectProps extends Omit<ComboBoxProps<FontOption>,'children'> {
    fonts: {family: string, name: string}[]
    label?: string
}

export const FontSelect = ({fonts, ...props}: FontSelectProps) => {
    return (
        <ComboBox {...props} items={fonts} canClear>
            {item => <SelectItem id={item.name} style={{fontFamily: item.family}}>{item.name}</SelectItem>}
        </ComboBox>
    )
}