import { FileMeta } from "@/client"
import { MediaCard } from "../MediaCard/MediaCard"
import { useState } from "react"
import { Dialog, GridListProps, Key, ListBox, ListBoxItem, Selection } from "react-aria-components"
import { Drawer } from "@/components/Drawer/Drawer"

export interface MediaListProps {
    files: FileMeta[]
    selectedKeys: Iterable<Key>
    rendersDetailDrawer?: boolean
    name?: string
    onChange?: (file: FileMeta | null) => void
}

export const MediaList = ({files, rendersDetailDrawer = true, selectedKeys, onChange}: MediaListProps) => {
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [selectedFile, setSelectedFile] = useState<FileMeta|null>(null)
    const [selected, setSelectedKeys] = useState<Iterable<Key>>(selectedKeys)

    const handleChange = (keys: Selection) => {
        if (keys == "all") return
        const key = keys.values().next().value
        if (!key) return
        const file = files.find(f => f.name == key) ?? null
        console.log(`Setting key ${key}`)
        setSelectedKeys(keys)
        setSelectedFile(file!)
        setDetailsOpen(true)
        onChange?.(file)
    }

    const handleDrawerClose = (value: boolean) => {
        setDetailsOpen(value)
        if (!value) setSelectedKeys([])
    }

    return <>
        {rendersDetailDrawer && <Drawer isDismissable isOpen={detailsOpen} className="flex w-[30vw]" onOpenChange={handleDrawerClose}>
            <Dialog className="outline-none w-full">
                <MediaDetails file={selectedFile!} />
            </Dialog>
        </Drawer>}
        <MediaListContainer items={files} selectedKeys={selected} selectionMode="single" selectionBehavior="toggle" onSelectionChange={handleChange}>
            {f => rendersDetailDrawer ?
                <ListBoxItem id={f.name} className="outline-none">
                    {(props) => <MediaCard key={f.name} file={f} includeModified {...props} />}
                </ListBoxItem>
                : <ListBoxItem id={f.name} className="outline-none">
                    {(props) => <MediaCard key={f.name} file={f} includeModified {...props} />}
                </ListBoxItem>
            }
        </MediaListContainer>
    </>
}

export const MediaListContainer = <T extends object,>({children, ...otherProps}: GridListProps<T>) =>
    <div className="flex flex-col bg-gray-50 overflow-y-auto">
        <div className="h-[40px] shrink-0"></div>
        <div className="h-full p-12">
            <ListBox {...otherProps} layout="grid" className="w-full grid gap-[3rem] grid-cols-[repeat(auto-fill,minmax(17rem,1fr))]">
                {children}
            </ListBox>
        </div>
    </div>

interface MediaDetails {
    file: FileMeta
}

const MediaDetails = ({file}: MediaDetails) => {

    return (
        <div className="h-full w-full">
            <img loading="lazy" src={`${file.uri}?${file.lastModified}`} className="w-full"/>
            <div className="w-full grid grid-cols-[auto_1fr] justify-items-start text-left break-anywhere gap-8 overflow-hidden p-4">
                <div>Name</div><div>{file.name}</div>
                <div>Last Updated</div><div>{file.lastModified?.toLocaleString()}</div>
                <div>Url</div><div>{file.uri}</div>
            </div>
        </div>
    )
}