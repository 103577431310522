import { Preflight } from '@/client';
import * as eclectic from './eclectic';
import * as elegant from './elegant';

export interface ThemeConfig {
    eclectic: Preflight;
    elegant: Preflight;
}
    
export function GetPreflight(globalAssetsUrl: string, themeName: string) {
    if (themeName == 'eclectic') {
        return eclectic.GetPreflight(globalAssetsUrl);
    } else if (themeName == 'elegant') {
        return elegant.GetPreflight(globalAssetsUrl);
    }
    return {} as Preflight
}
