import { ReactElement } from "react";

export const IconSquare = () => (
    <IconWrap>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 300'>
            <path fill='#9e9e9e' d='M15 15h270v270H15z' />
            <path fill='#f2f2f2' d='M285 15v270H15V15h270m15-15H0v300h300V0z' />
        </svg>
    </IconWrap>
);

export const IconSquareChecked = () => (
    <IconWrap>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 300'>
            <path fill='#009393' d='M15 15h270v270H15z' />
            <path fill='#f2f2f2' d='M285 15v270H15V15h270m15-15H0v300h300V0z' />
        </svg>
    </IconWrap>
);

export const IconRounded = () => (
    <IconWrap>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 300'>
            <rect width='270' height='270' x='15' y='15' fill='#9e9e9e' rx='45.8' />
            <path
                fill='#f2f2f2'
                d='M239.2 15A45.86 45.86 0 01285 60.8v178.4a45.86 45.86 0 01-45.8 45.8H60.8A45.86 45.86 0 0115 239.2V60.8A45.86 45.86 0 0160.8 15h178.4m0-15H60.8A60.8 60.8 0 000 60.8v178.4A60.8 60.8 0 0060.8 300h178.4a60.8 60.8 0 0060.8-60.8V60.8A60.8 60.8 0 00239.2 0z'
            />
        </svg>
    </IconWrap>
);

export const IconRoundedChecked = () => (
    <IconWrap>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 300'>
            <rect width='270' height='270' x='15' y='15' fill='#009393' rx='45.8' />
            <path
                fill='#f2f2f2'
                d='M239.2 15A45.86 45.86 0 01285 60.8v178.4a45.86 45.86 0 01-45.8 45.8H60.8A45.86 45.86 0 0115 239.2V60.8A45.86 45.86 0 0160.8 15h178.4m0-15H60.8A60.8 60.8 0 000 60.8v178.4A60.8 60.8 0 0060.8 300h178.4a60.8 60.8 0 0060.8-60.8V60.8A60.8 60.8 0 00239.2 0z'
            />
        </svg>
    </IconWrap>
);

export const IconCircular = () => (
    <IconWrap>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 300'>
            <path
                fill='#9e9e9e'
                d='M150 15a135 135 0 00-95.46 230.46A135 135 0 00245.46 54.54 134.11 134.11 0 00150 15z'
            />
            <path
                fill='#f2f2f2'
                d='M150 15a135 135 0 0195.46 230.46A135 135 0 0154.54 54.54 134.11 134.11 0 01150 15m0-15A150 150 0 000 150a150 150 0 00150 150 150 150 0 00150-150A150 150 0 00150 0z'
            />
        </svg>
    </IconWrap>
);

export const IconCircularChecked = () => (
    <IconWrap>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 300'>
            <path
                fill='#009393'
                d='M150 15a135 135 0 00-95.46 230.46A135 135 0 00245.46 54.54 134.11 134.11 0 00150 15z'
            />
            <path
                fill='#f2f2f2'
                d='M150 15a135 135 0 0195.46 230.46A135 135 0 0154.54 54.54 134.11 134.11 0 01150 15m0-15A150 150 0 000 150a150 150 0 00150 150 150 150 0 00150-150A150 150 0 00150 0z'
            />
        </svg>
    </IconWrap>
);

export const IconWrap = ({children}: {children: ReactElement}) =>
    <div className="w-[6rem] h-[6rem] m-r-[1rem]">
        {children}
    </div>
