import * as coreClient from "@azure/core-client";

export const LoginRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "LoginRequest",
    modelProperties: {
      username: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "username",
        required: true,
        type: {
          name: "String",
        },
      },
      password: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "password",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const UserDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UserDto",
    modelProperties: {
      userId: {
        serializedName: "userId",
        required: true,
        type: {
          name: "Number",
        },
      },
      username: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "username",
        required: true,
        type: {
          name: "String",
        },
      },
      firstName: {
        serializedName: "firstName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      lastName: {
        serializedName: "lastName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      siteId: {
        serializedName: "siteId",
        required: true,
        type: {
          name: "Number",
        },
      },
      sites: {
        serializedName: "sites",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SiteDto",
            },
          },
        },
      },
    },
  },
};

export const SiteDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SiteDto",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Number",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      url: {
        serializedName: "url",
        required: true,
        type: {
          name: "String",
        },
      },
      copyright: {
        serializedName: "copyright",
        type: {
          name: "String",
        },
      },
      footer: {
        serializedName: "footer",
        type: {
          name: "String",
        },
      },
      layout: {
        serializedName: "layout",
        type: {
          name: "Composite",
          className: "Layout",
        },
      },
    },
  },
};

export const Layout: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Layout",
    modelProperties: {
      menu: {
        serializedName: "menu",
        type: {
          name: "String",
        },
      },
      preMods: {
        serializedName: "preMods",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const VersionSummaryPaginatedResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "VersionSummaryPaginatedResponse",
    modelProperties: {
      meta: {
        serializedName: "meta",
        type: {
          name: "Composite",
          className: "PaginatedResponseMeta",
        },
      },
      data: {
        serializedName: "data",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VersionSummary",
            },
          },
        },
      },
    },
  },
};

export const PaginatedResponseMeta: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PaginatedResponseMeta",
    modelProperties: {
      totalRecords: {
        serializedName: "totalRecords",
        required: true,
        type: {
          name: "Number",
        },
      },
      offset: {
        serializedName: "offset",
        required: true,
        type: {
          name: "Number",
        },
      },
      limit: {
        serializedName: "limit",
        required: true,
        type: {
          name: "Number",
        },
      },
      search: {
        serializedName: "search",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const VersionSummary: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "VersionSummary",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      savedAt: {
        serializedName: "savedAt",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      scheduledPublicationDate: {
        serializedName: "scheduledPublicationDate",
        required: true,
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      preflight: {
        serializedName: "preflight",
        type: {
          name: "Composite",
          className: "PreflightSummary",
        },
      },
    },
  },
};

export const PreflightSummary: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PreflightSummary",
    modelProperties: {
      updatedBy: {
        serializedName: "updatedBy",
        nullable: true,
        type: {
          name: "String",
        },
      },
      updatedAt: {
        serializedName: "updatedAt",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
    },
  },
};

export const PublishRequestDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PublishRequestDto",
    modelProperties: {
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      versionId: {
        serializedName: "versionId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      scheduledPublicationDate: {
        serializedName: "scheduledPublicationDate",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
    },
  },
};

export const PreflightVersion: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PreflightVersion",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        type: {
          name: "String",
        },
      },
      savedAt: {
        serializedName: "savedAt",
        type: {
          name: "DateTime",
        },
      },
      scheduledPublicationDate: {
        serializedName: "scheduledPublicationDate",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      preflight: {
        serializedName: "preflight",
        type: {
          name: "Composite",
          className: "Preflight",
        },
      },
    },
  },
};

export const Preflight: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Preflight",
    modelProperties: {
      updatedBy: {
        serializedName: "updatedBy",
        nullable: true,
        type: {
          name: "String",
        },
      },
      updatedAt: {
        serializedName: "updatedAt",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      theme: {
        serializedName: "theme",
        type: {
          name: "Composite",
          className: "Theme",
        },
      },
      content: {
        serializedName: "content",
        type: {
          name: "Composite",
          className: "Content",
        },
      },
    },
  },
};

export const Theme: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Theme",
    modelProperties: {
      meta: {
        serializedName: "meta",
        type: {
          name: "Composite",
          className: "Meta",
        },
      },
      template: {
        serializedName: "template",
        type: {
          name: "Composite",
          className: "Template",
        },
      },
      customCss: {
        serializedName: "customCss",
        nullable: true,
        type: {
          name: "String",
        },
      },
      scss: {
        serializedName: "scss",
        type: {
          name: "Composite",
          className: "Scss",
        },
      },
    },
  },
};

export const Meta: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Meta",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String",
        },
      },
      basedOn: {
        serializedName: "basedOn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      tagLine: {
        serializedName: "tagLine",
        type: {
          name: "String",
        },
      },
      isFranchise: {
        serializedName: "isFranchise",
        type: {
          name: "Boolean",
        },
      },
      foodType: {
        serializedName: "foodType",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      website: {
        serializedName: "website",
        nullable: true,
        type: {
          name: "String",
        },
      },
      orderTypes: {
        serializedName: "orderTypes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      restaurantType: {
        serializedName: "restaurantType",
        type: {
          name: "String",
        },
      },
      brand: {
        serializedName: "brand",
        nullable: true,
        type: {
          name: "String",
        },
      },
      style: {
        serializedName: "style",
        nullable: true,
        type: {
          name: "String",
        },
      },
      themeName: {
        serializedName: "themeName",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Template: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Template",
    modelProperties: {
      layout: {
        serializedName: "layout",
        type: {
          name: "String",
        },
      },
      logoUrl: {
        serializedName: "logoUrl",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      iconUrl: {
        serializedName: "iconUrl",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      iconSet: {
        serializedName: "iconSet",
        type: {
          name: "String",
        },
      },
      shape: {
        serializedName: "shape",
        type: {
          name: "String",
        },
      },
      colors: {
        serializedName: "colors",
        type: {
          name: "Composite",
          className: "Colors",
        },
      },
      typography: {
        serializedName: "typography",
        type: {
          name: "Composite",
          className: "Typography",
        },
      },
      components: {
        serializedName: "components",
        type: {
          name: "Composite",
          className: "Components",
        },
      },
    },
  },
};

export const Colors: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Colors",
    modelProperties: {
      primary: {
        serializedName: "primary",
        type: {
          name: "String",
        },
      },
      secondary: {
        serializedName: "secondary",
        type: {
          name: "String",
        },
      },
      neutralDark: {
        serializedName: "neutralDark",
        type: {
          name: "String",
        },
      },
      neutralLight: {
        serializedName: "neutralLight",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Typography: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Typography",
    modelProperties: {
      fonts: {
        serializedName: "fonts",
        type: {
          name: "Composite",
          className: "Fonts",
        },
      },
      baseFontTypography: {
        serializedName: "baseFontTypography",
        type: {
          name: "Composite",
          className: "BaseFontTypography",
        },
      },
    },
  },
};

export const Fonts: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Fonts",
    modelProperties: {
      special: {
        serializedName: "special",
        type: {
          name: "Composite",
          className: "FontSpecification",
        },
      },
      heading: {
        serializedName: "heading",
        type: {
          name: "Composite",
          className: "FontSpecification",
        },
      },
      title: {
        serializedName: "title",
        type: {
          name: "Composite",
          className: "FontSpecification",
        },
      },
      body: {
        serializedName: "body",
        type: {
          name: "Composite",
          className: "FontSpecification",
        },
      },
    },
  },
};

export const FontSpecification: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "FontSpecification",
    modelProperties: {
      fontFamily: {
        serializedName: "fontFamily",
        type: {
          name: "String",
        },
      },
      fontWeight: {
        serializedName: "fontWeight",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const BaseFontTypography: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "BaseFontTypography",
    modelProperties: {
      fontSize: {
        serializedName: "fontSize",
        type: {
          name: "Number",
        },
      },
      lineHeight: {
        serializedName: "lineHeight",
        type: {
          name: "Number",
        },
      },
      fontHarmony: {
        serializedName: "fontHarmony",
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const Components: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Components",
    modelProperties: {
      general: {
        serializedName: "general",
        type: {
          name: "Composite",
          className: "GeneralComponent",
        },
      },
      header: {
        serializedName: "header",
        type: {
          name: "Composite",
          className: "HeaderComponent",
        },
      },
      foodMenu: {
        serializedName: "foodMenu",
        type: {
          name: "Composite",
          className: "FoodMenuComponent",
        },
      },
      foodMenuNavigation: {
        serializedName: "foodMenuNavigation",
        type: {
          name: "Composite",
          className: "FoodMenuNavComponent",
        },
      },
      mainBanner: {
        serializedName: "mainBanner",
        type: {
          name: "Composite",
          className: "BannerComponent",
        },
      },
      footer: {
        serializedName: "footer",
        type: {
          name: "Composite",
          className: "AlignmentInnerLayoutComponent",
        },
      },
      logoOptions: {
        serializedName: "logoOptions",
        type: {
          name: "Composite",
          className: "LogoOptions",
        },
      },
    },
  },
};

export const GeneralComponent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GeneralComponent",
    modelProperties: {
      styles: {
        serializedName: "styles",
        type: {
          name: "Composite",
          className: "Styles",
        },
      },
      innerLayout: {
        serializedName: "innerLayout",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Styles: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Styles",
    modelProperties: {
      dark: {
        serializedName: "dark",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      transparent: {
        serializedName: "transparent",
        required: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const HeaderComponent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "HeaderComponent",
    modelProperties: {
      styles: {
        serializedName: "styles",
        type: {
          name: "Composite",
          className: "LayoutStyles",
        },
      },
      innerLayout: {
        serializedName: "innerLayout",
        type: {
          name: "String",
        },
      },
      iconBackground: {
        serializedName: "iconBackground",
        required: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const LayoutStyles: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "LayoutStyles",
    modelProperties: {
      dark: {
        serializedName: "dark",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      transparent: {
        serializedName: "transparent",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      raised: {
        serializedName: "raised",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      colors: {
        serializedName: "colors",
        type: {
          name: "Composite",
          className: "ComponentColors",
        },
      },
    },
  },
};

export const ComponentColors: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ComponentColors",
    modelProperties: {
      foreground: {
        serializedName: "foreground",
        required: true,
        type: {
          name: "String",
        },
      },
      background: {
        serializedName: "background",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const FoodMenuComponent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "FoodMenuComponent",
    modelProperties: {
      styles: {
        serializedName: "styles",
        type: {
          name: "Composite",
          className: "LayoutStyles",
        },
      },
      imageLayout: {
        serializedName: "imageLayout",
        type: {
          name: "String",
        },
      },
      imageEdgeToEdge: {
        serializedName: "imageEdgeToEdge",
        type: {
          name: "Boolean",
        },
      },
      bannerEnabled: {
        serializedName: "bannerEnabled",
        type: {
          name: "Boolean",
        },
      },
      innerLayout: {
        serializedName: "innerLayout",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const FoodMenuNavComponent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "FoodMenuNavComponent",
    modelProperties: {
      styles: {
        serializedName: "styles",
        type: {
          name: "Composite",
          className: "LayoutStyles",
        },
      },
      innerLayout: {
        serializedName: "innerLayout",
        type: {
          name: "String",
        },
      },
      layout: {
        serializedName: "layout",
        type: {
          name: "String",
        },
      },
      orientation: {
        serializedName: "orientation",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const BannerComponent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "BannerComponent",
    modelProperties: {
      styles: {
        serializedName: "styles",
        type: {
          name: "Composite",
          className: "LayoutStyles",
        },
      },
      innerLayout: {
        serializedName: "innerLayout",
        type: {
          name: "String",
        },
      },
      shade: {
        serializedName: "shade",
        type: {
          name: "Boolean",
        },
      },
      enabled: {
        serializedName: "enabled",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const AlignmentInnerLayoutComponent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AlignmentInnerLayoutComponent",
    modelProperties: {
      styles: {
        serializedName: "styles",
        type: {
          name: "Composite",
          className: "LayoutStyles",
        },
      },
      innerLayout: {
        serializedName: "innerLayout",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const LogoOptions: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "LogoOptions",
    modelProperties: {
      size: {
        serializedName: "size",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      overHang: {
        serializedName: "overHang",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const Scss: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Scss",
    modelProperties: {
      libs: {
        serializedName: "libs",
        type: {
          name: "String",
        },
      },
      inputs: {
        serializedName: "inputs",
        type: {
          name: "String",
        },
      },
      generalComponents: {
        serializedName: "generalComponents",
        type: {
          name: "String",
        },
      },
      styleGuide: {
        serializedName: "styleGuide",
        type: {
          name: "String",
        },
      },
      normalize: {
        serializedName: "normalize",
        type: {
          name: "String",
        },
      },
      base: {
        serializedName: "base",
        type: {
          name: "String",
        },
      },
      variables: {
        serializedName: "variables",
        type: {
          name: "String",
        },
      },
      typography: {
        serializedName: "typography",
        type: {
          name: "String",
        },
      },
      mixins: {
        serializedName: "mixins",
        type: {
          name: "String",
        },
      },
      layout: {
        serializedName: "layout",
        type: {
          name: "String",
        },
      },
      button: {
        serializedName: "button",
        type: {
          name: "String",
        },
      },
      icons: {
        serializedName: "icons",
        type: {
          name: "String",
        },
      },
      header: {
        serializedName: "header",
        type: {
          name: "String",
        },
      },
      footer: {
        serializedName: "footer",
        type: {
          name: "String",
        },
      },
      foodMenu: {
        serializedName: "foodMenu",
        type: {
          name: "String",
        },
      },
      overrides: {
        serializedName: "overrides",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Content: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Content",
    modelProperties: {
      pages: {
        serializedName: "pages",
        type: {
          name: "Composite",
          className: "Pages",
        },
      },
      copyright: {
        serializedName: "copyright",
        type: {
          name: "String",
        },
      },
      footer: {
        serializedName: "footer",
        type: {
          name: "Composite",
          className: "Footer",
        },
      },
      publicationAssets: {
        serializedName: "publicationAssets",
        type: {
          name: "Composite",
          className: "PublicationAssets",
        },
      },
    },
  },
};

export const Pages: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Pages",
    modelProperties: {
      landingPage: {
        serializedName: "landingPage",
        type: {
          name: "Composite",
          className: "LandingPage",
        },
      },
      contentPages: {
        serializedName: "contentPages",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContentPage",
            },
          },
        },
      },
    },
  },
};

export const LandingPage: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "LandingPage",
    modelProperties: {
      bannerImageUrl: {
        serializedName: "bannerImageUrl",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      bannerImageShade: {
        serializedName: "bannerImageShade",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const ContentPage: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ContentPage",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String",
        },
      },
      slug: {
        serializedName: "slug",
        type: {
          name: "String",
        },
      },
      uid: {
        serializedName: "uid",
        type: {
          name: "String",
        },
      },
      published: {
        serializedName: "published",
        type: {
          name: "Boolean",
        },
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime",
        },
      },
      updatedAt: {
        serializedName: "updatedAt",
        type: {
          name: "DateTime",
        },
      },
      body: {
        serializedName: "body",
        type: {
          name: "String",
        },
      },
      author: {
        serializedName: "author",
        type: {
          name: "String",
        },
      },
      updatedBy: {
        serializedName: "updatedBy",
        type: {
          name: "String",
        },
      },
      layout: {
        serializedName: "layout",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Footer: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Footer",
    modelProperties: {
      content: {
        serializedName: "content",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PublicationAssets: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PublicationAssets",
    modelProperties: {
      contentImages: {
        serializedName: "contentImages",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PublishableImage",
            },
          },
        },
      },
      foodMenuImages: {
        serializedName: "foodMenuImages",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FoodMenuImage",
            },
          },
        },
      },
    },
  },
};

export const PublishableImage: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PublishableImage",
    modelProperties: {
      imageUrl: {
        serializedName: "imageUrl",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const FoodMenuImage: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "FoodMenuImage",
    modelProperties: {
      imageUrl: {
        serializedName: "imageUrl",
        type: {
          name: "String",
        },
      },
      replacesImageName: {
        serializedName: "replacesImageName",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ProblemDetails: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ProblemDetails",
    additionalProperties: { type: { name: "Object" } },
    modelProperties: {
      type: {
        serializedName: "type",
        nullable: true,
        type: {
          name: "String",
        },
      },
      title: {
        serializedName: "title",
        nullable: true,
        type: {
          name: "String",
        },
      },
      status: {
        serializedName: "status",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      detail: {
        serializedName: "detail",
        nullable: true,
        type: {
          name: "String",
        },
      },
      instance: {
        serializedName: "instance",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PreflightVersionPaginatedResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PreflightVersionPaginatedResponse",
    modelProperties: {
      meta: {
        serializedName: "meta",
        type: {
          name: "Composite",
          className: "PaginatedResponseMeta",
        },
      },
      data: {
        serializedName: "data",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PreflightVersion",
            },
          },
        },
      },
    },
  },
};

export const FileMeta: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "FileMeta",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String",
        },
      },
      uri: {
        serializedName: "uri",
        type: {
          name: "String",
        },
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "DateTime",
        },
      },
      default: {
        serializedName: "default",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const BlobUploadToken: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "BlobUploadToken",
    modelProperties: {
      uri: {
        serializedName: "uri",
        required: true,
        type: {
          name: "String",
        },
      },
      sasToken: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "sasToken",
        required: true,
        type: {
          name: "String",
        },
      },
      blobUri: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "blobUri",
        required: true,
        type: {
          name: "String",
        },
      },
      container: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "container",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const InitialisePreflightRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "InitialisePreflightRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String",
        },
      },
      foodMenuNavLayout: {
        serializedName: "foodMenuNavLayout",
        type: {
          name: "String",
        },
      },
      copyright: {
        serializedName: "copyright",
        type: {
          name: "String",
        },
      },
      footer: {
        serializedName: "footer",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const SaveRequestDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SaveRequestDto",
    modelProperties: {
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};
