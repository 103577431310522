import { RootCtx } from "@/contexts"
import { DefaultError, QueryClient, QueryKey, QueryObserver, QueryObserverOptions } from "@tanstack/react-query"
import { observable, runInAction } from "mobx"
import { Context, createContext, useContext } from "react"

export const createUseStore = <T>(storeType: Newable<T>): [Context<T | null>, () => T] => {
    const context = createContext<T | null>(null)

    return [context, () => {
        const rootStore = useContext(RootCtx)

        let store = rootStore?.getStoreOf(storeType)

        if (!store)
            store = useContext(context)

        if (!store) throw new Error(`Must call use hook for ${storeType.name} in context`)

        return store
    }]
}

export function PropProx<T extends object>(obj: T = {} as T): T & {__prox__: string[]} {
    const path: Array<string|symbol> = []
    const handler: ProxyHandler<any> = {
        get(target, prop, receiver) {
            if (prop == "__prox__") return path
            path.push(prop)
            return receiver
        }
    }
    return new Proxy(obj, handler)
}

export function queryObservable<
    TQueryFnData = unknown,
    TError = DefaultError,
    TData = TQueryFnData,
    TQueryData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
> (client: QueryClient, options: QueryObserverOptions<TQueryFnData, TError, TData, TQueryData, TQueryKey>) {
    const obs = new QueryObserver(client, options)

    const result = observable({...obs.getCurrentResult()})
    obs.subscribe(res => {
        runInAction(() => Object.assign(result, res) )
    })

    return result
}