import { useState } from "react"
import {ListBox, ListBoxItem} from 'react-aria-components'
import { Button } from "@/components/Button"
import { Modal } from "@/components/Modal/Modal"
import { Selection } from "react-aria-components"
import { Checkbox } from "@/components/CheckboxGroup/CheckboxGroup"
import { useThemeStore } from "@/stores/Theme"
import {GetPreflight, ThemeConfig} from '@/app/themes';
import { useGlobalAssetsUrl } from "@/stores/App"
import _ from 'lodash';

import themeEclectic from '@/assets/preview/theme-eclectic.jpg'
import themeElegant from '@/assets/preview/theme-elegant.jpg'
import { Preflight } from "@/client"

export type ThemeOption = {
    name: string;
    src: string;
};

const themes = [
    { name: 'eclectic', src: themeEclectic},
    { name: 'elegant', src: themeElegant}
];

export const ThemeSelector = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedTheme, setSelectedTheme] = useState<ThemeOption>();
    const [includeBrandColors, setIncludeBrandColors] = useState(false);
    const [includeFonts, setIncludeFonts] = useState(false);
    const [includeBackgroundImage, setIncludeBackgroundImage] = useState(false);
    const theme = useThemeStore()
    const [getActiveTheme] = theme.bind(p => p.theme.meta.themeName)
    const globalAssetsUrl = useGlobalAssetsUrl();
    
    const onOpenChange = (value: boolean) => {
        const activeTheme = getActiveTheme();
        if (!value && activeTheme) {
            const theme = themes.find(thm => thm.name == activeTheme);
            setSelectedTheme(theme);
        }
        setIsOpen(value)
    }

    const handleSelectionChange = (keys: Selection) => {
        if (keys == "all") return
        const key = keys.values().next().value
        const theme = themes.find((thm) => thm.name == key)
        setSelectedTheme(theme)
        setIsOpen(true)
    }

    const handleActivate = async () => {
        if (selectedTheme) {
            const key = selectedTheme.name as keyof ThemeConfig
            const propertyPathsToPersist = [
                'theme.template.iconUrl',
                'theme.template.logoUrl',
            ];

            if (includeBrandColors) {
                propertyPathsToPersist.push('theme.template.colors');
            }
            if (includeFonts) {
                propertyPathsToPersist.push('theme.template.typography.fonts');
            }
            if (includeBackgroundImage) {
                propertyPathsToPersist.push(
                    'content.pages.landingPage.bannerImageUrl',
                );
            }

            const newTheme = _.omit(GetPreflight(globalAssetsUrl, key), propertyPathsToPersist)
            theme.setPreflight(newTheme as Preflight);
            setIsOpen(false);
        }
    }

    return <>
        <ListBox 
            aria-label="Theme Selector"
            className="flex flex-col gap-5" 
            selectionMode="single" 
            selectionBehavior="replace"
            onSelectionChange={handleSelectionChange}
        >
            {themes.map((theme, index) => (
                <ListBoxItem href="#" id={theme.name} key={index} className="relative">
                    <img
                        src={theme.src}
                        alt={theme.name}
                    />
                    {theme.name == getActiveTheme() && 
                        <div className="absolute right-2 bottom-2 align-middle py-1 px-3 rounded-3xl bg-teal-600">✓ Selected</div>
                    }
                </ListBoxItem>
            ))}
        </ListBox>
        <Modal isDismissable className="max-w-[1280px] max-h-[95%] outline-none p-8 dark" isOpen={isOpen && selectedTheme != undefined} onOpenChange={onOpenChange}>
            <div className="flex justify-center gap-5">
                { selectedTheme && <img className="max-w-[50%]" src={selectedTheme.src}/>}
                <div className="flex flex-col justify-between">
                    <div>
                        <h1 className="mb-4 text-4xl font-medium leading-tight">Activate Theme</h1>
                        <h6 className="mb-4 text-base leading-tight opacity-70">Would you like to include any of the following from your current theme?</h6>
                        <Checkbox isSelected={includeBrandColors} onChange={setIncludeBrandColors} value="Brand Colors">Brand Colors</Checkbox>
                        <Checkbox isSelected={includeFonts} onChange={setIncludeFonts} value="Fonts">Fonts</Checkbox>
                        <Checkbox isSelected={includeBackgroundImage} onChange={setIncludeBackgroundImage} value="Background Image">Background Image</Checkbox>
                    </div>
                    <div className="flex gap-2 border-[1px] border-gray-200 py-[1.75rem] px-[1.25rem] rounded">
                        <svg fill="none" strokeWidth={1.5} stroke="currentColor" className="h-10 w-10 stroke-stone-800">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                        </svg>
                        <h6 className="text-base leading-tight text-stone-800 font-normal">
                            Activating a different theme will create a new version of your site, which you will be able to customize and publish when you are ready.
                        </h6>
                    </div>
                </div>
            </div>
            <div className="flex gap-2 justify-end pt-4">
                <Button type="button" className="flex" onPress={handleActivate}>Activate</Button>
            </div>
        </Modal>
    </> 
}