import { ReactElement } from 'react';
import {CheckboxGroup as AriaCheckboxGroup, Checkbox as AriaCheckbox, CheckboxGroupProps as AriaCheckboxGroupProps, CheckboxRenderProps, CheckboxProps as AriaCheckboxProps, ValidationResult} from 'react-aria-components';
import { Description, FieldError, Label } from '../Field'
import { tv } from 'tailwind-variants';
import { composeTailwindRenderProps, focusRing } from '../utils'

export interface CheckboxGroupProps extends Omit<AriaCheckboxGroupProps, 'children'> {
    children?: React.ReactNode;
    label?: string;
    description?: string;
    errorMessage?: string | ((validation: ValidationResult) => string);
}

export function CheckboxGroup(props: CheckboxGroupProps) {
    return (
        <AriaCheckboxGroup {...props} className={composeTailwindRenderProps(props.className, 'group flex flex-col gap-2')}>
            {props.label && <Label>{props.label}</Label>}
            <div className="flex group-orientation-vertical:flex-col gap-2 group-orientation-horizontal:gap-4">
                {props.children}
            </div>
            {props.description && <Description slot="description">{props.description}</Description>}
            <FieldError>{props.errorMessage}</FieldError>
        </AriaCheckboxGroup>
    );
}

const styles = tv({
    extend: focusRing,
    base: "w-5 h-5 ring-2 ring-gray-500 rounded-[0.1rem] bg-white transition-all",
    variants: {
        isSelected: {
            true: 'ring-teal-600 border-transparent'
        }
    }
})

export interface CheckboxProps extends AriaCheckboxProps {
    icon?: (props: CheckboxRenderProps) => ReactElement
}
  
export function Checkbox(props: CheckboxProps) {
    const {icon = DefaultIcon} = props
    return (
        <AriaCheckbox {...props} className={composeTailwindRenderProps(props.className, 'flex gap-4 items-center group min-h-4 py-2 transition cursor-pointer disabled:cursor-default disabled:opacity-35')}>
            {renderProps => <>
                {icon(renderProps)}
                {props.children}
            </>}
        </AriaCheckbox>
    );
}

const DefaultIcon = (renderProps: CheckboxRenderProps) =>
    <div className={styles(renderProps)}>
        {renderProps.isSelected && (
            <svg viewBox="4 4 16 16" aria-hidden="true" className='fill-teal-600'>
                <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
            </svg>
        )}
    </div>
  