export const HeaderFonts = [
    {
        family: "ABeeZee,sans-serif",
        name: "ABeeZee",
    },
    {
        family: "Abel,sans-serif",
        name: "Abel",
    },
    {
        family: "\"Abhaya Libre\",serif",
        name: "Abhaya Libre",
    },
    {
        family: "\"Abril Fatface\",fantasy",
        name: "Abril Fatface",
    },
    {
        family: "Aclonica,sans-serif",
        name: "Aclonica",
    },
    {
        family: "Acme,sans-serif",
        name: "Acme",
    },
    {
        family: "Actor,sans-serif",
        name: "Actor",
    },
    {
        family: "Adamina,serif",
        name: "Adamina",
    },
    {
        family: "\"Advent Pro\",sans-serif",
        name: "Advent Pro",
    },
    {
        family: "Aladin,fantasy",
        name: "Aladin",
    },
    {
        family: "Alata,sans-serif",
        name: "Alata",
    },
    {
        family: "Alatsi,sans-serif",
        name: "Alatsi",
    },
    {
        family: "Aldrich,sans-serif",
        name: "Aldrich",
    },
    {
        family: "Alef,sans-serif",
        name: "Alef",
    },
    {
        family: "Alegreya,serif",
        name: "Alegreya",
    },
    {
        family: "\"Alegreya SC\",serif",
        name: "Alegreya SC",
    },
    {
        family: "\"Alegreya Sans\",sans-serif",
        name: "Alegreya Sans",
    },
    {
        family: "\"Alegreya Sans SC\",sans-serif",
        name: "Alegreya Sans SC",
    },
    {
        family: "Aleo,serif",
        name: "Aleo",
    },
    {
        family: "\"Alex Brush\",cursive",
        name: "Alex Brush",
    },
    {
        family: "\"Alfa Slab One\",fantasy",
        name: "Alfa Slab One",
    },
    {
        family: "Alice,serif",
        name: "Alice",
    },
    {
        family: "Allerta,sans-serif",
        name: "Allerta",
    },
    {
        family: "\"Allerta Stencil\",sans-serif",
        name: "Allerta Stencil",
    },
    {
        family: "Almendra,serif",
        name: "Almendra",
    },
    {
        family: "Amarante,fantasy",
        name: "Amarante",
    },
    {
        family: "Amaranth,sans-serif",
        name: "Amaranth",
    },
    {
        family: "\"Amatic SC\",cursive",
        name: "Amatic SC",
    },
    {
        family: "Amiko,sans-serif",
        name: "Amiko",
    },
    {
        family: "Amita,cursive",
        name: "Amita",
    },
    {
        family: "Anaheim,sans-serif",
        name: "Anaheim",
    },
    {
        family: "Andika,sans-serif",
        name: "Andika",
    },
    {
        family: "\"Annie Use Your Telescope\",cursive",
        name: "Annie Use Your Telescope",
    },
    {
        family: "\"Anonymous Pro\",monospace",
        name: "Anonymous Pro",
    },
    {
        family: "Antic,sans-serif",
        name: "Antic",
    },
    {
        family: "\"Antic Didone\",serif",
        name: "Antic Didone",
    },
    {
        family: "\"Antic Slab\",serif",
        name: "Antic Slab",
    },
    {
        family: "Anton,sans-serif",
        name: "Anton",
    },
    {
        family: "Arapey,serif",
        name: "Arapey",
    },
    {
        family: "Arbutus,serif",
        name: "Arbutus",
    },
    {
        family: "\"Arbutus Slab\",serif",
        name: "Arbutus Slab",
    },
    {
        family: "\"Architects Daughter\",cursive",
        name: "Architects Daughter",
    },
    {
        family: "Archivo,sans-serif",
        name: "Archivo",
    },
    {
        family: "\"Archivo Black\",sans-serif",
        name: "Archivo Black",
    },
    {
        family: "\"Archivo Narrow\",sans-serif",
        name: "Archivo Narrow",
    },
    {
        family: "\"Aref Ruqaa\",serif",
        name: "Aref Ruqaa",
    },
    {
        family: "Arimo,sans-serif",
        name: "Arimo",
    },
    {
        family: "Arizonia,cursive",
        name: "Arizonia",
    },
    {
        family: "Armata,sans-serif",
        name: "Armata",
    },
    {
        family: "Arsenal,sans-serif",
        name: "Arsenal",
    },
    {
        family: "Artifika,serif",
        name: "Artifika",
    },
    {
        family: "Arvo,serif",
        name: "Arvo",
    },
    {
        family: "Arya,sans-serif",
        name: "Arya",
    },
    {
        family: "Asap,sans-serif",
        name: "Asap",
    },
    {
        family: "\"Asap Condensed\",sans-serif",
        name: "Asap Condensed",
    },
    {
        family: "Asar,serif",
        name: "Asar",
    },
    {
        family: "Assistant,sans-serif",
        name: "Assistant",
    },
    {
        family: "Asul,sans-serif",
        name: "Asul",
    },
    {
        family: "Atma,fantasy",
        name: "Atma",
    },
    {
        family: "Audiowide,fantasy",
        name: "Audiowide",
    },
    {
        family: "\"Autour One\",fantasy",
        name: "Autour One",
    },
    {
        family: "Average,serif",
        name: "Average",
    },
    {
        family: "\"Average Sans\",sans-serif",
        name: "Average Sans",
    },
    {
        family: "\"Averia Sans Libre\",fantasy",
        name: "Averia Sans Libre",
    },
    {
        family: "\"Averia Serif Libre\",fantasy",
        name: "Averia Serif Libre",
    },
    {
        family: "\"Bad Script\",cursive",
        name: "Bad Script",
    },
    {
        family: "\"Balsamiq Sans\",fantasy",
        name: "Balsamiq Sans",
    },
    {
        family: "Balthazar,serif",
        name: "Balthazar",
    },
    {
        family: "Bangers,fantasy",
        name: "Bangers",
    },
    {
        family: "Barlow,sans-serif",
        name: "Barlow",
    },
    {
        family: "\"Barlow Condensed\",sans-serif",
        name: "Barlow Condensed",
    },
    {
        family: "\"Barlow Semi Condensed\",sans-serif",
        name: "Barlow Semi Condensed",
    },
    {
        family: "Barriecito,fantasy",
        name: "Barriecito",
    },
    {
        family: "Barrio,fantasy",
        name: "Barrio",
    },
    {
        family: "Baskervville,serif",
        name: "Baskervville",
    },
    {
        family: "Baumans,fantasy",
        name: "Baumans",
    },
    {
        family: "\"Bebas Neue\",sans-serif",
        name: "Bebas Neue",
    },
    {
        family: "Belgrano,serif",
        name: "Belgrano",
    },
    {
        family: "Bellefair,serif",
        name: "Bellefair",
    },
    {
        family: "Belleza,sans-serif",
        name: "Belleza",
    },
    {
        family: "Bellota,fantasy",
        name: "Bellota",
    },
    {
        family: "\"Bellota Text\",fantasy",
        name: "Bellota Text",
    },
    {
        family: "\"Beth Ellen\",cursive",
        name: "Beth Ellen",
    },
    {
        family: "Bevan,serif",
        name: "Bevan",
    },
    {
        family: "\"Big Shoulders Text\",fantasy",
        name: "Big Shoulders Text",
    },
    {
        family: "\"Bigshot One\",fantasy",
        name: "Bigshot One",
    },
    {
        family: "Bilbo,cursive",
        name: "Bilbo",
    },
    {
        family: "BioRhyme,serif",
        name: "BioRhyme",
    },
    {
        family: "\"BioRhyme Expanded\",serif",
        name: "BioRhyme Expanded",
    },
    {
        family: "Biryani,sans-serif",
        name: "Biryani",
    },
    {
        family: "Bitter,serif",
        name: "Bitter",
    },
    {
        family: "\"Black Ops One\",fantasy",
        name: "Black Ops One",
    },
    {
        family: "Bonbon,cursive",
        name: "Bonbon",
    },
    {
        family: "Boogaloo,fantasy",
        name: "Boogaloo",
    },
    {
        family: "\"Bowlby One\",fantasy",
        name: "Bowlby One",
    },
    {
        family: "\"Bowlby One SC\",fantasy",
        name: "Bowlby One SC",
    },
    {
        family: "Brawler,serif",
        name: "Brawler",
    },
    {
        family: "\"Bree Serif\",serif",
        name: "Bree Serif",
    },
    {
        family: "\"Bubblegum Sans\",fantasy",
        name: "Bubblegum Sans",
    },
    {
        family: "\"Bubbler One\",sans-serif",
        name: "Bubbler One",
    },
    {
        family: "Buenard,serif",
        name: "Buenard",
    },
    {
        family: "Bungee,fantasy",
        name: "Bungee",
    },
    {
        family: "Cabin,sans-serif",
        name: "Cabin",
    },
    {
        family: "\"Cabin Condensed\",sans-serif",
        name: "Cabin Condensed",
    },
    {
        family: "\"Cabin Sketch\",fantasy",
        name: "Cabin Sketch",
    },
    {
        family: "\"Caesar Dressing\",fantasy",
        name: "Caesar Dressing",
    },
    {
        family: "Cagliostro,sans-serif",
        name: "Cagliostro",
    },
    {
        family: "Cairo,sans-serif",
        name: "Cairo",
    },
    {
        family: "Caladea,serif",
        name: "Caladea",
    },
    {
        family: "Calistoga,fantasy",
        name: "Calistoga",
    },
    {
        family: "Calligraffitti,cursive",
        name: "Calligraffitti",
    },
    {
        family: "Cambay,sans-serif",
        name: "Cambay",
    },
    {
        family: "Cambo,serif",
        name: "Cambo",
    },
    {
        family: "Candal,sans-serif",
        name: "Candal",
    },
    {
        family: "Cantarell,sans-serif",
        name: "Cantarell",
    },
    {
        family: "\"Cantata One\",serif",
        name: "Cantata One",
    },
    {
        family: "\"Cantora One\",sans-serif",
        name: "Cantora One",
    },
    {
        family: "Capriola,sans-serif",
        name: "Capriola",
    },
    {
        family: "Cardo,serif",
        name: "Cardo",
    },
    {
        family: "Carme,sans-serif",
        name: "Carme",
    },
    {
        family: "\"Carrois Gothic\",sans-serif",
        name: "Carrois Gothic",
    },
    {
        family: "\"Carrois Gothic SC\",sans-serif",
        name: "Carrois Gothic SC",
    },
    {
        family: "\"Carter One\",fantasy",
        name: "Carter One",
    },
    {
        family: "Catamaran,sans-serif",
        name: "Catamaran",
    },
    {
        family: "Caudex,serif",
        name: "Caudex",
    },
    {
        family: "Caveat,cursive",
        name: "Caveat",
    },
    {
        family: "\"Caveat Brush\",cursive",
        name: "Caveat Brush",
    },
    {
        family: "\"Cedarville Cursive\",cursive",
        name: "Cedarville Cursive",
    },
    {
        family: "\"Ceviche One\",fantasy",
        name: "Ceviche One",
    },
    {
        family: "\"Chakra Petch\",sans-serif",
        name: "Chakra Petch",
    },
    {
        family: "\"Changa One\",fantasy",
        name: "Changa One",
    },
    {
        family: "Chango,fantasy",
        name: "Chango",
    },
    {
        family: "Charmonman,cursive",
        name: "Charmonman",
    },
    {
        family: "\"Chau Philomene One\",sans-serif",
        name: "Chau Philomene One",
    },
    {
        family: "\"Chela One\",fantasy",
        name: "Chela One",
    },
    {
        family: "\"Chelsea Market\",fantasy",
        name: "Chelsea Market",
    },
    {
        family: "\"Cherry Cream Soda\",fantasy",
        name: "Cherry Cream Soda",
    },
    {
        family: "\"Cherry Swash\",fantasy",
        name: "Cherry Swash",
    },
    {
        family: "Chewy,fantasy",
        name: "Chewy",
    },
    {
        family: "Chicle,fantasy",
        name: "Chicle",
    },
    {
        family: "Chilanka,cursive",
        name: "Chilanka",
    },
    {
        family: "Chivo,sans-serif",
        name: "Chivo",
    },
    {
        family: "Chonburi,fantasy",
        name: "Chonburi",
    },
    {
        family: "Cinzel,serif",
        name: "Cinzel",
    },
    {
        family: "\"Cinzel Decorative\",fantasy",
        name: "Cinzel Decorative",
    },
    {
        family: "\"Clicker Script\",cursive",
        name: "Clicker Script",
    },
    {
        family: "Coda,fantasy",
        name: "Coda",
    },
    {
        family: "Coiny,fantasy",
        name: "Coiny",
    },
    {
        family: "Combo,fantasy",
        name: "Combo",
    },
    {
        family: "Comfortaa,fantasy",
        name: "Comfortaa",
    },
    {
        family: "\"Comic Neue\",cursive",
        name: "Comic Neue",
    },
    {
        family: "\"Coming Soon\",cursive",
        name: "Coming Soon",
    },
    {
        family: "Commissioner,sans-serif",
        name: "Commissioner",
    },
    {
        family: "\"Concert One\",fantasy",
        name: "Concert One",
    },
    {
        family: "\"Contrail One\",fantasy",
        name: "Contrail One",
    },
    {
        family: "Convergence,sans-serif",
        name: "Convergence",
    },
    {
        family: "Cookie,cursive",
        name: "Cookie",
    },
    {
        family: "Copse,serif",
        name: "Copse",
    },
    {
        family: "Corben,fantasy",
        name: "Corben",
    },
    {
        family: "Cormorant,serif",
        name: "Cormorant",
    },
    {
        family: "\"Cormorant Infant\",serif",
        name: "Cormorant Infant",
    },
    {
        family: "\"Cormorant SC\",serif",
        name: "Cormorant SC",
    },
    {
        family: "Courgette,cursive",
        name: "Courgette",
    },
    {
        family: "\"Courier Prime\",monospace",
        name: "Courier Prime",
    },
    {
        family: "Cousine,monospace",
        name: "Cousine",
    },
    {
        family: "Coustard,serif",
        name: "Coustard",
    },
    {
        family: "\"Covered By Your Grace\",cursive",
        name: "Covered By Your Grace",
    },
    {
        family: "\"Crafty Girls\",cursive",
        name: "Crafty Girls",
    },
    {
        family: "Creepster,fantasy",
        name: "Creepster",
    },
    {
        family: "\"Crete Round\",serif",
        name: "Crete Round",
    },
    {
        family: "\"Crimson Pro\",serif",
        name: "Crimson Pro",
    },
    {
        family: "\"Crimson Text\",serif",
        name: "Crimson Text",
    },
    {
        family: "\"Croissant One\",fantasy",
        name: "Croissant One",
    },
    {
        family: "Crushed,fantasy",
        name: "Crushed",
    },
    {
        family: "Cuprum,sans-serif",
        name: "Cuprum",
    },
    {
        family: "\"Cute Font\",fantasy",
        name: "Cute Font",
    },
    {
        family: "Cutive,serif",
        name: "Cutive",
    },
    {
        family: "\"Cutive Mono\",monospace",
        name: "Cutive Mono",
    },
    {
        family: "\"DM Mono\",monospace",
        name: "DM Mono",
    },
    {
        family: "\"DM Serif Text\",serif",
        name: "DM Serif Text",
    },
    {
        family: "Damion,cursive",
        name: "Damion",
    },
    {
        family: "\"Dancing Script\",cursive",
        name: "Dancing Script",
    },
    {
        family: "\"David Libre\",serif",
        name: "David Libre",
    },
    {
        family: "\"Days One\",sans-serif",
        name: "Days One",
    },
    {
        family: "Dekko,cursive",
        name: "Dekko",
    },
    {
        family: "Delius,cursive",
        name: "Delius",
    },
    {
        family: "\"Della Respira\",serif",
        name: "Della Respira",
    },
    {
        family: "\"Didact Gothic\",sans-serif",
        name: "Didact Gothic",
    },
    {
        family: "\"Do Hyeon\",sans-serif",
        name: "Do Hyeon",
    },
    {
        family: "Dokdo,fantasy",
        name: "Dokdo",
    },
    {
        family: "\"Donegal One\",serif",
        name: "Donegal One",
    },
    {
        family: "Dosis,sans-serif",
        name: "Dosis",
    },
    {
        family: "\"Dr Sugiyama\",cursive",
        name: "Dr Sugiyama",
    },
    {
        family: "\"East Sea Dokdo\",cursive",
        name: "East Sea Dokdo",
    },
    {
        family: "Economica,sans-serif",
        name: "Economica",
    },
    {
        family: "Eczar,serif",
        name: "Eczar",
    },
    {
        family: "\"El Messiri\",sans-serif",
        name: "El Messiri",
    },
    {
        family: "Electrolize,sans-serif",
        name: "Electrolize",
    },
    {
        family: "Elsie,fantasy",
        name: "Elsie",
    },
    {
        family: "\"Elsie Swash Caps\",fantasy",
        name: "Elsie Swash Caps",
    },
    {
        family: "\"Emilys Candy\",fantasy",
        name: "Emilys Candy",
    },
    {
        family: "Engagement,cursive",
        name: "Engagement",
    },
    {
        family: "Epilogue,sans-serif",
        name: "Epilogue",
    },
    {
        family: "\"Erica One\",fantasy",
        name: "Erica One",
    },
    {
        family: "\"Euphoria Script\",cursive",
        name: "Euphoria Script",
    },
    {
        family: "Ewert,fantasy",
        name: "Ewert",
    },
    {
        family: "Exo,sans-serif",
        name: "Exo",
    },
    {
        family: "\"Expletus Sans\",fantasy",
        name: "Expletus Sans",
    },
    {
        family: "Fahkwang,sans-serif",
        name: "Fahkwang",
    },
    {
        family: "\"Fanwood Text\",serif",
        name: "Fanwood Text",
    },
    {
        family: "Farro,sans-serif",
        name: "Farro",
    },
    {
        family: "Farsan,fantasy",
        name: "Farsan",
    },
    {
        family: "Fascinate,fantasy",
        name: "Fascinate",
    },
    {
        family: "Fasthand,fantasy",
        name: "Fasthand",
    },
    {
        family: "\"Fauna One\",serif",
        name: "Fauna One",
    },
    {
        family: "Federant,fantasy",
        name: "Federant",
    },
    {
        family: "Federo,sans-serif",
        name: "Federo",
    },
    {
        family: "Fenix,serif",
        name: "Fenix",
    },
    {
        family: "\"Finger Paint\",fantasy",
        name: "Finger Paint",
    },
    {
        family: "\"Fjalla One\",sans-serif",
        name: "Fjalla One",
    },
    {
        family: "Flamenco,fantasy",
        name: "Flamenco",
    },
    {
        family: "Fondamento,cursive",
        name: "Fondamento",
    },
    {
        family: "\"Fontdiner Swanky\",fantasy",
        name: "Fontdiner Swanky",
    },
    {
        family: "Forum,fantasy",
        name: "Forum",
    },
    {
        family: "\"Francois One\",sans-serif",
        name: "Francois One",
    },
    {
        family: "\"Frank Ruhl Libre\",serif",
        name: "Frank Ruhl Libre",
    },
    {
        family: "Fresca,sans-serif",
        name: "Fresca",
    },
    {
        family: "\"Fugaz One\",fantasy",
        name: "Fugaz One",
    },
    {
        family: "Gabriela,serif",
        name: "Gabriela",
    },
    {
        family: "Gaegu,cursive",
        name: "Gaegu",
    },
    {
        family: "Gafata,sans-serif",
        name: "Gafata",
    },
    {
        family: "Galada,fantasy",
        name: "Galada",
    },
    {
        family: "Galdeano,sans-serif",
        name: "Galdeano",
    },
    {
        family: "Galindo,fantasy",
        name: "Galindo",
    },
    {
        family: "\"Gamja Flower\",cursive",
        name: "Gamja Flower",
    },
    {
        family: "Gayathri,sans-serif",
        name: "Gayathri",
    },
    {
        family: "Gelasio,serif",
        name: "Gelasio",
    },
    {
        family: "\"Gilda Display\",serif",
        name: "Gilda Display",
    },
    {
        family: "Girassol,fantasy",
        name: "Girassol",
    },
    {
        family: "\"Give You Glory\",cursive",
        name: "Give You Glory",
    },
    {
        family: "\"Glass Antiqua\",fantasy",
        name: "Glass Antiqua",
    },
    {
        family: "Glegoo,serif",
        name: "Glegoo",
    },
    {
        family: "\"Gloria Hallelujah\",cursive",
        name: "Gloria Hallelujah",
    },
    {
        family: "\"Goblin One\",fantasy",
        name: "Goblin One",
    },
    {
        family: "\"Gochi Hand\",cursive",
        name: "Gochi Hand",
    },
    {
        family: "Gorditas,fantasy",
        name: "Gorditas",
    },
    {
        family: "\"Gothic A1\",sans-serif",
        name: "Gothic A1",
    },
    {
        family: "Gotu,sans-serif",
        name: "Gotu",
    },
    {
        family: "\"Goudy Bookletter 1911\",serif",
        name: "Goudy Bookletter 1911",
    },
    {
        family: "Graduate,serif",
        name: "Graduate",
    },
    {
        family: "\"Grand Hotel\",cursive",
        name: "Grand Hotel",
    },
    {
        family: "Grandstander,fantasy",
        name: "Grandstander",
    },
    {
        family: "\"Gravitas One\",fantasy",
        name: "Gravitas One",
    },
    {
        family: "\"Great Vibes\",cursive",
        name: "Great Vibes",
    },
    {
        family: "Grenze,serif",
        name: "Grenze",
    },
    {
        family: "\"Grenze Gotisch\",fantasy",
        name: "Grenze Gotisch",
    },
    {
        family: "Griffy,fantasy",
        name: "Griffy",
    },
    {
        family: "Gruppo,sans-serif",
        name: "Gruppo",
    },
    {
        family: "Gudea,sans-serif",
        name: "Gudea",
    },
    {
        family: "Gupter,serif",
        name: "Gupter",
    },
    {
        family: "Habibi,serif",
        name: "Habibi",
    },
    {
        family: "Halant,serif",
        name: "Halant",
    },
    {
        family: "\"Hammersmith One\",sans-serif",
        name: "Hammersmith One",
    },
    {
        family: "Handlee,cursive",
        name: "Handlee",
    },
    {
        family: "Hanuman,serif",
        name: "Hanuman",
    },
    {
        family: "\"Happy Monkey\",fantasy",
        name: "Happy Monkey",
    },
    {
        family: "Harmattan,sans-serif",
        name: "Harmattan",
    },
    {
        family: "\"Headland One\",serif",
        name: "Headland One",
    },
    {
        family: "Heebo,sans-serif",
        name: "Heebo",
    },
    {
        family: "\"Henny Penny\",fantasy",
        name: "Henny Penny",
    },
    {
        family: "\"Hepta Slab\",serif",
        name: "Hepta Slab",
    },
    {
        family: "\"Hi Melody\",cursive",
        name: "Hi Melody",
    },
    {
        family: "\"Holtwood One SC\",serif",
        name: "Holtwood One SC",
    },
    {
        family: "\"Homemade Apple\",cursive",
        name: "Homemade Apple",
    },
    {
        family: "Homenaje,sans-serif",
        name: "Homenaje",
    },
    {
        family: "\"Ibarra Real Nova\",serif",
        name: "Ibarra Real Nova",
    },
    {
        family: "Iceberg,fantasy",
        name: "Iceberg",
    },
    {
        family: "Iceland,fantasy",
        name: "Iceland",
    },
    {
        family: "Imprima,sans-serif",
        name: "Imprima",
    },
    {
        family: "Inconsolata,monospace",
        name: "Inconsolata",
    },
    {
        family: "Inder,sans-serif",
        name: "Inder",
    },
    {
        family: "\"Indie Flower\",cursive",
        name: "Indie Flower",
    },
    {
        family: "Inika,serif",
        name: "Inika",
    },
    {
        family: "\"Inknut Antiqua\",serif",
        name: "Inknut Antiqua",
    },
    {
        family: "\"Irish Grover\",fantasy",
        name: "Irish Grover",
    },
    {
        family: "Italiana,serif",
        name: "Italiana",
    },
    {
        family: "Italianno,cursive",
        name: "Italianno",
    },
    {
        family: "Itim,cursive",
        name: "Itim",
    },
    {
        family: "\"Jacques Francois\",serif",
        name: "Jacques Francois",
    },
    {
        family: "\"Jacques Francois Shadow\",fantasy",
        name: "Jacques Francois Shadow",
    },
    {
        family: "Jaldi,sans-serif",
        name: "Jaldi",
    },
    {
        family: "\"Jim Nightshade\",cursive",
        name: "Jim Nightshade",
    },
    {
        family: "\"Jockey One\",sans-serif",
        name: "Jockey One",
    },
    {
        family: "\"Josefin Sans\",sans-serif",
        name: "Josefin Sans",
    },
    {
        family: "\"Josefin Slab\",serif",
        name: "Josefin Slab",
    },
    {
        family: "Jost,sans-serif",
        name: "Jost",
    },
    {
        family: "\"Joti One\",fantasy",
        name: "Joti One",
    },
    {
        family: "Jua,sans-serif",
        name: "Jua",
    },
    {
        family: "Judson,serif",
        name: "Judson",
    },
    {
        family: "Julee,cursive",
        name: "Julee",
    },
    {
        family: "\"Julius Sans One\",sans-serif",
        name: "Julius Sans One",
    },
    {
        family: "Junge,serif",
        name: "Junge",
    },
    {
        family: "Jura,sans-serif",
        name: "Jura",
    },
    {
        family: "\"Just Another Hand\",cursive",
        name: "Just Another Hand",
    },
    {
        family: "\"Just Me Again Down Here\",cursive",
        name: "Just Me Again Down Here",
    },
    {
        family: "Kalam,cursive",
        name: "Kalam",
    },
    {
        family: "\"Kaushan Script\",cursive",
        name: "Kaushan Script",
    },
    {
        family: "Kavivanar,cursive",
        name: "Kavivanar",
    },
    {
        family: "Kavoon,fantasy",
        name: "Kavoon",
    },
    {
        family: "\"Kelly Slab\",fantasy",
        name: "Kelly Slab",
    },
    {
        family: "\"Kite One\",sans-serif",
        name: "Kite One",
    },
    {
        family: "Knewave,fantasy",
        name: "Knewave",
    },
    {
        family: "KoHo,sans-serif",
        name: "KoHo",
    },
    {
        family: "Kodchasan,sans-serif",
        name: "Kodchasan",
    },
    {
        family: "\"Kotta One\",serif",
        name: "Kotta One",
    },
    {
        family: "Kranky,fantasy",
        name: "Kranky",
    },
    {
        family: "Kreon,serif",
        name: "Kreon",
    },
    {
        family: "Kristi,cursive",
        name: "Kristi",
    },
    {
        family: "\"Krona One\",sans-serif",
        name: "Krona One",
    },
    {
        family: "Krub,sans-serif",
        name: "Krub",
    },
    {
        family: "Kufam,sans-serif",
        name: "Kufam",
    },
    {
        family: "\"Kumar One\",fantasy",
        name: "Kumar One",
    },
    {
        family: "\"Kumbh Sans\",sans-serif",
        name: "Kumbh Sans",
    },
    {
        family: "Kurale,serif",
        name: "Kurale",
    },
    {
        family: "\"La Belle Aurore\",cursive",
        name: "La Belle Aurore",
    },
    {
        family: "Lacquer,fantasy",
        name: "Lacquer",
    },
    {
        family: "Laila,sans-serif",
        name: "Laila",
    },
    {
        family: "Lalezar,fantasy",
        name: "Lalezar",
    },
    {
        family: "Lancelot,fantasy",
        name: "Lancelot",
    },
    {
        family: "Lato,sans-serif",
        name: "Lato",
    },
    {
        family: "\"Leckerli One\",cursive",
        name: "Leckerli One",
    },
    {
        family: "Lekton,monospace",
        name: "Lekton",
    },
    {
        family: "Lemon,fantasy",
        name: "Lemon",
    },
    {
        family: "Lemonada,fantasy",
        name: "Lemonada",
    },
    {
        family: "\"Lexend Deca\",sans-serif",
        name: "Lexend Deca",
    },
    {
        family: "\"Libre Baskerville\",serif",
        name: "Libre Baskerville",
    },
    {
        family: "\"Libre Caslon Display\",serif",
        name: "Libre Caslon Display",
    },
    {
        family: "\"Libre Caslon Text\",serif",
        name: "Libre Caslon Text",
    },
    {
        family: "\"Libre Franklin\",sans-serif",
        name: "Libre Franklin",
    },
    {
        family: "\"Life Savers\",fantasy",
        name: "Life Savers",
    },
    {
        family: "\"Lilita One\",fantasy",
        name: "Lilita One",
    },
    {
        family: "\"Lily Script One\",fantasy",
        name: "Lily Script One",
    },
    {
        family: "Limelight,fantasy",
        name: "Limelight",
    },
    {
        family: "\"Linden Hill\",serif",
        name: "Linden Hill",
    },
    {
        family: "Lobster,fantasy",
        name: "Lobster",
    },
    {
        family: "\"Lobster Two\",fantasy",
        name: "Lobster Two",
    },
    {
        family: "\"Londrina Outline\",fantasy",
        name: "Londrina Outline",
    },
    {
        family: "\"Londrina Shadow\",fantasy",
        name: "Londrina Shadow",
    },
    {
        family: "\"Londrina Sketch\",fantasy",
        name: "Londrina Sketch",
    },
    {
        family: "\"Londrina Solid\",fantasy",
        name: "Londrina Solid",
    },
    {
        family: "\"Long Cang\",cursive",
        name: "Long Cang",
    },
    {
        family: "Lora,serif",
        name: "Lora",
    },
    {
        family: "\"Love Ya Like A Sister\",fantasy",
        name: "Love Ya Like A Sister",
    },
    {
        family: "\"Lovers Quarrel\",cursive",
        name: "Lovers Quarrel",
    },
    {
        family: "\"Luckiest Guy\",fantasy",
        name: "Luckiest Guy",
    },
    {
        family: "Lusitana,serif",
        name: "Lusitana",
    },
    {
        family: "Lustria,serif",
        name: "Lustria",
    },
    {
        family: "\"Macondo Swash Caps\",fantasy",
        name: "Macondo Swash Caps",
    },
    {
        family: "\"Maiden Orange\",serif",
        name: "Maiden Orange",
    },
    {
        family: "Maitree,serif",
        name: "Maitree",
    },
    {
        family: "\"Major Mono Display\",monospace",
        name: "Major Mono Display",
    },
    {
        family: "Mako,sans-serif",
        name: "Mako",
    },
    {
        family: "Mali,cursive",
        name: "Mali",
    },
    {
        family: "Manrope,sans-serif",
        name: "Manrope",
    },
    {
        family: "Mansalva,cursive",
        name: "Mansalva",
    },
    {
        family: "Marcellus,serif",
        name: "Marcellus",
    },
    {
        family: "\"Marcellus SC\",serif",
        name: "Marcellus SC",
    },
    {
        family: "\"Marck Script\",cursive",
        name: "Marck Script",
    },
    {
        family: "Margarine,fantasy",
        name: "Margarine",
    },
    {
        family: "\"Marko One\",serif",
        name: "Marko One",
    },
    {
        family: "Martel,serif",
        name: "Martel",
    },
    {
        family: "\"Martel Sans\",sans-serif",
        name: "Martel Sans",
    },
    {
        family: "Marvel,sans-serif",
        name: "Marvel",
    },
    {
        family: "Mate,serif",
        name: "Mate",
    },
    {
        family: "\"Mate SC\",serif",
        name: "Mate SC",
    },
    {
        family: "\"Maven Pro\",sans-serif",
        name: "Maven Pro",
    },
    {
        family: "McLaren,fantasy",
        name: "McLaren",
    },
    {
        family: "Meddon,cursive",
        name: "Meddon",
    },
    {
        family: "\"Meera Inimai\",sans-serif",
        name: "Meera Inimai",
    },
    {
        family: "\"Meie Script\",cursive",
        name: "Meie Script",
    },
    {
        family: "Merriweather,serif",
        name: "Merriweather",
    },
    {
        family: "\"Merriweather Sans\",sans-serif",
        name: "Merriweather Sans",
    },
    {
        family: "Metamorphous,fantasy",
        name: "Metamorphous",
    },
    {
        family: "Metrophobic,sans-serif",
        name: "Metrophobic",
    },
    {
        family: "Michroma,sans-serif",
        name: "Michroma",
    },
    {
        family: "Milonga,fantasy",
        name: "Milonga",
    },
    {
        family: "\"Miltonian Tattoo\",fantasy",
        name: "Miltonian Tattoo",
    },
    {
        family: "Mina,sans-serif",
        name: "Mina",
    },
    {
        family: "Miniver,fantasy",
        name: "Miniver",
    },
    {
        family: "\"Miriam Libre\",sans-serif",
        name: "Miriam Libre",
    },
    {
        family: "Mirza,serif",
        name: "Mirza",
    },
    {
        family: "Mitr,sans-serif",
        name: "Mitr",
    },
    {
        family: "\"Modern Antiqua\",fantasy",
        name: "Modern Antiqua",
    },
    {
        family: "Mogra,fantasy",
        name: "Mogra",
    },
    {
        family: "Molengo,sans-serif",
        name: "Molengo",
    },
    {
        family: "Monda,sans-serif",
        name: "Monda",
    },
    {
        family: "Montaga,serif",
        name: "Montaga",
    },
    {
        family: "Montez,cursive",
        name: "Montez",
    },
    {
        family: "Montserrat,sans-serif",
        name: "Montserrat",
    },
    {
        family: "\"Montserrat Alternates\",sans-serif",
        name: "Montserrat Alternates",
    },
    {
        family: "\"Montserrat Subrayada\",sans-serif",
        name: "Montserrat Subrayada",
    },
    {
        family: "\"Mouse Memoirs\",sans-serif",
        name: "Mouse Memoirs",
    },
    {
        family: "\"Mr Bedfort\",cursive",
        name: "Mr Bedfort",
    },
    {
        family: "Mulish,sans-serif",
        name: "Mulish",
    },
    {
        family: "MuseoModerno,fantasy",
        name: "MuseoModerno",
    },
    {
        family: "\"Mystery Quest\",fantasy",
        name: "Mystery Quest",
    },
    {
        family: "NTR,sans-serif",
        name: "NTR",
    },
    {
        family: "Neucha,cursive",
        name: "Neucha",
    },
    {
        family: "Neuton,serif",
        name: "Neuton",
    },
    {
        family: "\"New Rocker\",fantasy",
        name: "New Rocker",
    },
    {
        family: "\"News Cycle\",sans-serif",
        name: "News Cycle",
    },
    {
        family: "Niconne,cursive",
        name: "Niconne",
    },
    {
        family: "Niramit,sans-serif",
        name: "Niramit",
    },
    {
        family: "\"Nixie One\",fantasy",
        name: "Nixie One",
    },
    {
        family: "Norican,cursive",
        name: "Norican",
    },
    {
        family: "Notable,sans-serif",
        name: "Notable",
    },
    {
        family: "\"Nothing You Could Do\",cursive",
        name: "Nothing You Could Do",
    },
    {
        family: "\"Noticia Text\",serif",
        name: "Noticia Text",
    },
    {
        family: "\"Noto Sans\",sans-serif",
        name: "Noto Sans",
    },
    {
        family: "\"Noto Serif\",serif",
        name: "Noto Serif",
    },
    {
        family: "Numans,sans-serif",
        name: "Numans",
    },
    {
        family: "Nunito,sans-serif",
        name: "Nunito",
    },
    {
        family: "\"Nunito Sans\",sans-serif",
        name: "Nunito Sans",
    },
    {
        family: "\"Odibee Sans\",fantasy",
        name: "Odibee Sans",
    },
    {
        family: "Offside,fantasy",
        name: "Offside",
    },
    {
        family: "\"Old Standard TT\",serif",
        name: "Old Standard TT",
    },
    {
        family: "Oldenburg,fantasy",
        name: "Oldenburg",
    },
    {
        family: "\"Oleo Script\",fantasy",
        name: "Oleo Script",
    },
    {
        family: "\"Open Sans\",sans-serif",
        name: "Open Sans",
    },
    {
        family: "Oranienbaum,serif",
        name: "Oranienbaum",
    },
    {
        family: "Orbitron,sans-serif",
        name: "Orbitron",
    },
    {
        family: "Oregano,fantasy",
        name: "Oregano",
    },
    {
        family: "Orienta,sans-serif",
        name: "Orienta",
    },
    {
        family: "\"Original Surfer\",fantasy",
        name: "Original Surfer",
    },
    {
        family: "Oswald,sans-serif",
        name: "Oswald",
    },
    {
        family: "Overlock,fantasy",
        name: "Overlock",
    },
    {
        family: "\"Overlock SC\",fantasy",
        name: "Overlock SC",
    },
    {
        family: "Overpass,sans-serif",
        name: "Overpass",
    },
    {
        family: "\"Overpass Mono\",monospace",
        name: "Overpass Mono",
    },
    {
        family: "Ovo,serif",
        name: "Ovo",
    },
    {
        family: "Oxygen,sans-serif",
        name: "Oxygen",
    },
    {
        family: "\"Oxygen Mono\",monospace",
        name: "Oxygen Mono",
    },
    {
        family: "\"PT Mono\",monospace",
        name: "PT Mono",
    },
    {
        family: "\"PT Sans\",sans-serif",
        name: "PT Sans",
    },
    {
        family: "\"PT Serif\",serif",
        name: "PT Serif",
    },
    {
        family: "Pacifico,cursive",
        name: "Pacifico",
    },
    {
        family: "\"Palanquin Dark\",sans-serif",
        name: "Palanquin Dark",
    },
    {
        family: "Pangolin,cursive",
        name: "Pangolin",
    },
    {
        family: "Paprika,fantasy",
        name: "Paprika",
    },
    {
        family: "Parisienne,cursive",
        name: "Parisienne",
    },
    {
        family: "\"Passero One\",fantasy",
        name: "Passero One",
    },
    {
        family: "\"Passion One\",fantasy",
        name: "Passion One",
    },
    {
        family: "\"Pathway Gothic One\",sans-serif",
        name: "Pathway Gothic One",
    },
    {
        family: "\"Patrick Hand\",cursive",
        name: "Patrick Hand",
    },
    {
        family: "\"Patrick Hand SC\",cursive",
        name: "Patrick Hand SC",
    },
    {
        family: "Pattaya,sans-serif",
        name: "Pattaya",
    },
    {
        family: "\"Paytone One\",sans-serif",
        name: "Paytone One",
    },
    {
        family: "Peralta,serif",
        name: "Peralta",
    },
    {
        family: "\"Permanent Marker\",cursive",
        name: "Permanent Marker",
    },
    {
        family: "\"Petit Formal Script\",cursive",
        name: "Petit Formal Script",
    },
    {
        family: "Petrona,serif",
        name: "Petrona",
    },
    {
        family: "Philosopher,sans-serif",
        name: "Philosopher",
    },
    {
        family: "Piazzolla,serif",
        name: "Piazzolla",
    },
    {
        family: "\"Pinyon Script\",cursive",
        name: "Pinyon Script",
    },
    {
        family: "\"Pirata One\",fantasy",
        name: "Pirata One",
    },
    {
        family: "Plaster,fantasy",
        name: "Plaster",
    },
    {
        family: "Play,sans-serif",
        name: "Play",
    },
    {
        family: "Playball,fantasy",
        name: "Playball",
    },
    {
        family: "\"Playfair Display\",serif",
        name: "Playfair Display",
    },
    {
        family: "\"Playfair Display SC\",serif",
        name: "Playfair Display SC",
    },
    {
        family: "\"Poiret One\",fantasy",
        name: "Poiret One",
    },
    {
        family: "\"Poller One\",fantasy",
        name: "Poller One",
    },
    {
        family: "Poly,serif",
        name: "Poly",
    },
    {
        family: "Pompiere,fantasy",
        name: "Pompiere",
    },
    {
        family: "\"Pontano Sans\",sans-serif",
        name: "Pontano Sans",
    },
    {
        family: "\"Poor Story\",fantasy",
        name: "Poor Story",
    },
    {
        family: "Poppins,sans-serif",
        name: "Poppins",
    },
    {
        family: "Prata,serif",
        name: "Prata",
    },
    {
        family: "\"Press Start 2P\",fantasy",
        name: "Press Start 2P",
    },
    {
        family: "\"Princess Sofia\",cursive",
        name: "Princess Sofia",
    },
    {
        family: "\"Prosto One\",fantasy",
        name: "Prosto One",
    },
    {
        family: "\"Proza Libre\",sans-serif",
        name: "Proza Libre",
    },
    {
        family: "\"Public Sans\",sans-serif",
        name: "Public Sans",
    },
    {
        family: "Puritan,sans-serif",
        name: "Puritan",
    },
    {
        family: "\"Purple Purse\",fantasy",
        name: "Purple Purse",
    },
    {
        family: "Quando,serif",
        name: "Quando",
    },
    {
        family: "Quantico,sans-serif",
        name: "Quantico",
    },
    {
        family: "Quattrocento,serif",
        name: "Quattrocento",
    },
    {
        family: "\"Quattrocento Sans\",sans-serif",
        name: "Quattrocento Sans",
    },
    {
        family: "Questrial,sans-serif",
        name: "Questrial",
    },
    {
        family: "Quicksand,sans-serif",
        name: "Quicksand",
    },
    {
        family: "Quintessential,cursive",
        name: "Quintessential",
    },
    {
        family: "\"Racing Sans One\",fantasy",
        name: "Racing Sans One",
    },
    {
        family: "Radley,serif",
        name: "Radley",
    },
    {
        family: "Rakkas,fantasy",
        name: "Rakkas",
    },
    {
        family: "Raleway,sans-serif",
        name: "Raleway",
    },
    {
        family: "Rambla,sans-serif",
        name: "Rambla",
    },
    {
        family: "\"Rammetto One\",fantasy",
        name: "Rammetto One",
    },
    {
        family: "Ranchers,fantasy",
        name: "Ranchers",
    },
    {
        family: "Rancho,cursive",
        name: "Rancho",
    },
    {
        family: "Ranga,fantasy",
        name: "Ranga",
    },
    {
        family: "Rationale,sans-serif",
        name: "Rationale",
    },
    {
        family: "\"Ravi Prakash\",fantasy",
        name: "Ravi Prakash",
    },
    {
        family: "Recursive,sans-serif",
        name: "Recursive",
    },
    {
        family: "\"Red Hat Display\",sans-serif",
        name: "Red Hat Display",
    },
    {
        family: "\"Red Hat Text\",sans-serif",
        name: "Red Hat Text",
    },
    {
        family: "Redressed,cursive",
        name: "Redressed",
    },
    {
        family: "\"Reem Kufi\",sans-serif",
        name: "Reem Kufi",
    },
    {
        family: "\"Reenie Beanie\",cursive",
        name: "Reenie Beanie",
    },
    {
        family: "Revalia,fantasy",
        name: "Revalia",
    },
    {
        family: "\"Rhodium Libre\",serif",
        name: "Rhodium Libre",
    },
    {
        family: "Ribeye,fantasy",
        name: "Ribeye",
    },
    {
        family: "\"Ribeye Marrow\",fantasy",
        name: "Ribeye Marrow",
    },
    {
        family: "Righteous,fantasy",
        name: "Righteous",
    },
    {
        family: "Risque,fantasy",
        name: "Risque",
    },
    {
        family: "Roboto,sans-serif",
        name: "Roboto",
    },
    {
        family: "\"Roboto Condensed\",sans-serif",
        name: "Roboto Condensed",
    },
    {
        family: "\"Roboto Mono\",monospace",
        name: "Roboto Mono",
    },
    {
        family: "\"Roboto Slab\",serif",
        name: "Roboto Slab",
    },
    {
        family: "Rochester,cursive",
        name: "Rochester",
    },
    {
        family: "\"Rock Salt\",cursive",
        name: "Rock Salt",
    },
    {
        family: "Rokkitt,serif",
        name: "Rokkitt",
    },
    {
        family: "Romanesco,cursive",
        name: "Romanesco",
    },
    {
        family: "\"Ropa Sans\",sans-serif",
        name: "Ropa Sans",
    },
    {
        family: "Rosario,sans-serif",
        name: "Rosario",
    },
    {
        family: "\"Rouge Script\",cursive",
        name: "Rouge Script",
    },
    {
        family: "Rowdies,fantasy",
        name: "Rowdies",
    },
    {
        family: "\"Rozha One\",serif",
        name: "Rozha One",
    },
    {
        family: "Rubik,sans-serif",
        name: "Rubik",
    },
    {
        family: "\"Rubik Mono One\",sans-serif",
        name: "Rubik Mono One",
    },
    {
        family: "Ruda,sans-serif",
        name: "Ruda",
    },
    {
        family: "Rufina,serif",
        name: "Rufina",
    },
    {
        family: "\"Ruge Boogie\",cursive",
        name: "Ruge Boogie",
    },
    {
        family: "Ruluko,sans-serif",
        name: "Ruluko",
    },
    {
        family: "\"Rum Raisin\",sans-serif",
        name: "Rum Raisin",
    },
    {
        family: "\"Russo One\",sans-serif",
        name: "Russo One",
    },
    {
        family: "Ruthie,cursive",
        name: "Ruthie",
    },
    {
        family: "Rye,fantasy",
        name: "Rye",
    },
    {
        family: "Sacramento,cursive",
        name: "Sacramento",
    },
    {
        family: "Sahitya,serif",
        name: "Sahitya",
    },
    {
        family: "Sail,fantasy",
        name: "Sail",
    },
    {
        family: "Saira,sans-serif",
        name: "Saira",
    },
    {
        family: "Salsa,fantasy",
        name: "Salsa",
    },
    {
        family: "Sanchez,serif",
        name: "Sanchez",
    },
    {
        family: "Sancreek,fantasy",
        name: "Sancreek",
    },
    {
        family: "Sansita,sans-serif",
        name: "Sansita",
    },
    {
        family: "\"Sansita Swashed\",fantasy",
        name: "Sansita Swashed",
    },
    {
        family: "Sarina,fantasy",
        name: "Sarina",
    },
    {
        family: "Satisfy,cursive",
        name: "Satisfy",
    },
    {
        family: "Schoolbell,cursive",
        name: "Schoolbell",
    },
    {
        family: "\"Scope One\",serif",
        name: "Scope One",
    },
    {
        family: "\"Seaweed Script\",fantasy",
        name: "Seaweed Script",
    },
    {
        family: "\"Secular One\",sans-serif",
        name: "Secular One",
    },
    {
        family: "\"Sedgwick Ave\",cursive",
        name: "Sedgwick Ave",
    },
    {
        family: "\"Sedgwick Ave Display\",cursive",
        name: "Sedgwick Ave Display",
    },
    {
        family: "Sen,sans-serif",
        name: "Sen",
    },
    {
        family: "Sevillana,fantasy",
        name: "Sevillana",
    },
    {
        family: "\"Shadows Into Light Two\",cursive",
        name: "Shadows Into Light Two",
    },
    {
        family: "Shanti,sans-serif",
        name: "Shanti",
    },
    {
        family: "Shojumaru,fantasy",
        name: "Shojumaru",
    },
    {
        family: "\"Short Stack\",cursive",
        name: "Short Stack",
    },
    {
        family: "Shrikhand,fantasy",
        name: "Shrikhand",
    },
    {
        family: "\"Sigmar One\",fantasy",
        name: "Sigmar One",
    },
    {
        family: "Signika,sans-serif",
        name: "Signika",
    },
    {
        family: "Simonetta,fantasy",
        name: "Simonetta",
    },
    {
        family: "\"Single Day\",fantasy",
        name: "Single Day",
    },
    {
        family: "Sintony,sans-serif",
        name: "Sintony",
    },
    {
        family: "\"Sirin Stencil\",fantasy",
        name: "Sirin Stencil",
    },
    {
        family: "Slackey,fantasy",
        name: "Slackey",
    },
    {
        family: "Smokum,fantasy",
        name: "Smokum",
    },
    {
        family: "Smythe,fantasy",
        name: "Smythe",
    },
    {
        family: "Sniglet,fantasy",
        name: "Sniglet",
    },
    {
        family: "Snippet,sans-serif",
        name: "Snippet",
    },
    {
        family: "\"Snowburst One\",fantasy",
        name: "Snowburst One",
    },
    {
        family: "\"Sofadi One\",fantasy",
        name: "Sofadi One",
    },
    {
        family: "Sofia,cursive",
        name: "Sofia",
    },
    {
        family: "Solway,serif",
        name: "Solway",
    },
    {
        family: "\"Sonsie One\",fantasy",
        name: "Sonsie One",
    },
    {
        family: "Sora,sans-serif",
        name: "Sora",
    },
    {
        family: "\"Sorts Mill Goudy\",serif",
        name: "Sorts Mill Goudy",
    },
    {
        family: "\"Source Code Pro\",monospace",
        name: "Source Code Pro",
    },
    {
        family: "\"Space Mono\",monospace",
        name: "Space Mono",
    },
    {
        family: "\"Special Elite\",fantasy",
        name: "Special Elite",
    },
    {
        family: "\"Spicy Rice\",fantasy",
        name: "Spicy Rice",
    },
    {
        family: "Spinnaker,sans-serif",
        name: "Spinnaker",
    },
    {
        family: "Spirax,fantasy",
        name: "Spirax",
    },
    {
        family: "Sriracha,cursive",
        name: "Sriracha",
    },
    {
        family: "Staatliches,fantasy",
        name: "Staatliches",
    },
    {
        family: "Stalemate,cursive",
        name: "Stalemate",
    },
    {
        family: "\"Stardos Stencil\",fantasy",
        name: "Stardos Stencil",
    },
    {
        family: "\"Stint Ultra Condensed\",serif",
        name: "Stint Ultra Condensed",
    },
    {
        family: "\"Stint Ultra Expanded\",serif",
        name: "Stint Ultra Expanded",
    },
    {
        family: "Stoke,serif",
        name: "Stoke",
    },
    {
        family: "Stylish,sans-serif",
        name: "Stylish",
    },
    {
        family: "\"Sue Ellen Francisco\",cursive",
        name: "Sue Ellen Francisco",
    },
    {
        family: "\"Suez One\",serif",
        name: "Suez One",
    },
    {
        family: "\"Sulphur Point\",sans-serif",
        name: "Sulphur Point",
    },
    {
        family: "Sumana,serif",
        name: "Sumana",
    },
    {
        family: "Sunflower,sans-serif",
        name: "Sunflower",
    },
    {
        family: "Sunshiney,cursive",
        name: "Sunshiney",
    },
    {
        family: "Syncopate,sans-serif",
        name: "Syncopate",
    },
    {
        family: "Tangerine,cursive",
        name: "Tangerine",
    },
    {
        family: "Tauri,sans-serif",
        name: "Tauri",
    },
    {
        family: "Telex,sans-serif",
        name: "Telex",
    },
    {
        family: "\"Tenor Sans\",sans-serif",
        name: "Tenor Sans",
    },
    {
        family: "\"Text Me One\",sans-serif",
        name: "Text Me One",
    },
    {
        family: "Thasadith,sans-serif",
        name: "Thasadith",
    },
    {
        family: "\"The Girl Next Door\",cursive",
        name: "The Girl Next Door",
    },
    {
        family: "Tienne,serif",
        name: "Tienne",
    },
    {
        family: "Tillana,fantasy",
        name: "Tillana",
    },
    {
        family: "Timmana,sans-serif",
        name: "Timmana",
    },
    {
        family: "Tinos,serif",
        name: "Tinos",
    },
    {
        family: "\"Titan One\",fantasy",
        name: "Titan One",
    },
    {
        family: "Trirong,serif",
        name: "Trirong",
    },
    {
        family: "Trispace,sans-serif",
        name: "Trispace",
    },
    {
        family: "Trocchi,serif",
        name: "Trocchi",
    },
    {
        family: "Trochut,fantasy",
        name: "Trochut",
    },
    {
        family: "Trykker,serif",
        name: "Trykker",
    },
    {
        family: "Ultra,serif",
        name: "Ultra",
    },
    {
        family: "\"Unica One\",fantasy",
        name: "Unica One",
    },
    {
        family: "Unlock,fantasy",
        name: "Unlock",
    },
    {
        family: "Unna,serif",
        name: "Unna",
    },
    {
        family: "VT323,monospace",
        name: "VT323",
    },
    {
        family: "\"Vampiro One\",fantasy",
        name: "Vampiro One",
    },
    {
        family: "\"Vast Shadow\",serif",
        name: "Vast Shadow",
    },
    {
        family: "\"Vesper Libre\",serif",
        name: "Vesper Libre",
    },
    {
        family: "\"Viaoda Libre\",fantasy",
        name: "Viaoda Libre",
    },
    {
        family: "Vibes,fantasy",
        name: "Vibes",
    },
    {
        family: "Vibur,cursive",
        name: "Vibur",
    },
    {
        family: "Vidaloka,serif",
        name: "Vidaloka",
    },
    {
        family: "Viga,sans-serif",
        name: "Viga",
    },
    {
        family: "Vollkorn,serif",
        name: "Vollkorn",
    },
    {
        family: "\"Vollkorn SC\",serif",
        name: "Vollkorn SC",
    },
    {
        family: "Voltaire,sans-serif",
        name: "Voltaire",
    },
    {
        family: "\"Walter Turncoat\",cursive",
        name: "Walter Turncoat",
    },
    {
        family: "\"Wendy One\",sans-serif",
        name: "Wendy One",
    },
    {
        family: "\"Work Sans\",sans-serif",
        name: "Work Sans",
    },
    {
        family: "\"Yanone Kaffeesatz\",sans-serif",
        name: "Yanone Kaffeesatz",
    },
    {
        family: "\"Yatra One\",fantasy",
        name: "Yatra One",
    },
    {
        family: "Yellowtail,cursive",
        name: "Yellowtail",
    },
    {
        family: "\"Yeseva One\",fantasy",
        name: "Yeseva One",
    },
    {
        family: "Yesteryear,cursive",
        name: "Yesteryear",
    },
    {
        family: "Zeyada,cursive",
        name: "Zeyada",
    },
    {
        family: "\"Zilla Slab\",serif",
        name: "Zilla Slab",
    },
]