import { useThemeStore } from "@/stores/Theme"
import { observer } from "mobx-react-lite"
import { Settings } from "./_Settings"
import { SettingsSection } from "@/features/themer/components/SettingsSection"
import { Radio, RadioGroup } from "@/components/RadioGroup/RadioGroup"

import solidSvg from '@/assets/preview/solid-icons.svg'
import outlineSvg from '@/assets/preview/outline-icons.svg'
import handDrawnSvg from '@/assets/preview/handdrawn-icons.svg'

export const IconSettings = observer(() => {
    const theme = useThemeStore()

    const [getIconSet, setIconSet] = theme.bind(p => p.theme.template.iconSet)

    return (
        <Settings title="ICONS">
            <SettingsSection label="STYLE">
                <RadioGroup value={getIconSet()} onChange={setIconSet}>
                    <Radio value="SOLID">Solid</Radio>
                    <Radio value="OUTLINE">Outline</Radio>
                    <Radio value="HAND_DRAWN">Hand Drawn</Radio>
                </RadioGroup>
                <div className="p-4 mt-8">
                    {getIconSet() === 'SOLID' && (
                        <img src={solidSvg} alt='Solid icons' />
                    )}
                    {getIconSet() === 'OUTLINE' && (
                        <img src={outlineSvg} alt='Outline icons' />
                    )}
                    {getIconSet() === 'HAND_DRAWN' && (
                        <img src={handDrawnSvg} alt='Hand-drawn icons' />
                    )}
                </div>
            </SettingsSection>
        </Settings>
    )
})