import ReactDOM from 'react-dom/client'
import { RootStore } from './stores/Root.ts'
import { RootCtx } from './contexts.ts'
import { DesignerClient } from '@/client'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createRouter, RouterProvider } from '@tanstack/react-router'
import { routeTree } from './routeTree.gen.ts'

import './index.css'
import { autorun } from 'mobx'

const router = createRouter({ routeTree, defaultPendingMinMs: 0 })

declare module '@tanstack/react-router' {
    interface Register {
        router: typeof router
    }
}

const queryClient = new QueryClient()

const helmetContext = {}
const designer = new DesignerClient(window.location.origin, 0, {allowInsecureConnection: true})
const rootStore = new RootStore(designer, queryClient)
// Listen to site ID changes and do housekeeping
autorun(() => {
    const siteId = rootStore.auth.user?.selectedSite.id ?? 0
    // Set client's site ID
    designer.xHrSiteId = siteId
    // Invalidate all queries caches
    queryClient.invalidateQueries()
})

ReactDOM.createRoot(document.getElementById('root')!).render(
    <HelmetProvider context={helmetContext}>
        <RootCtx.Provider value={rootStore}>
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={router}/>
            </QueryClientProvider>
        </RootCtx.Provider>
    </HelmetProvider>
)