import { createFileRoute } from '@tanstack/react-router'
import 'vite/modulepreload-polyfill'
import { observer } from 'mobx-react-lite'
import { Home } from '@/app/Home'

export const Index = observer(() => {
    return (
        <div className='flex h-full w-full'>
            <Home/>
        </div>
    )
})

export const Route = createFileRoute('/')({
    component: Index
})