import { observer } from "mobx-react-lite"
import { DeviceType, usePreviewStore } from "../stores/Preview"
import { Computer, Smartphone, TabletMac } from "@material-ui/icons"
import { Button } from "@/components/Button/Button"
import { PageSelect } from "@/components/widgets/PageSelect"
import { HeaderContainer } from "@/components/widgets/HeaderContainer"
import { PublishButton } from "@/features/history/PublishButton"
import { SaveButton } from "@/features/history/SaveButton"

export const Header = observer(() => {
    const preview = usePreviewStore()

    return (
        <HeaderContainer>
            <PageSelect/>
            <div className="flex gap-2">
                <Button variant="icon" onPress={() => preview.setDevice(DeviceType.Mobile)}><Smartphone/></Button>
                <Button variant="icon" onPress={() => preview.setDevice(DeviceType.Tablet)}><TabletMac/></Button>
                <Button variant="icon" onPress={() => preview.setDevice(DeviceType.Computer)}><Computer/></Button>
            </div>
            <div className="flex gap-4">
                <SaveButton/>
                <PublishButton/>
            </div>
        </HeaderContainer>
    )
})