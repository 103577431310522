import { Login } from "@/components/Login/Login"
import { SideBar } from "./SideBar"
import Logo from "@/components/Logo"

import authBanner from  "@/assets/auth-banner.jpg"

export function LoginPage() {
    return (
        <div className='flex h-full w-full'>
            <SideBar>
                <div className="flex w-full justify-around">
                    <Logo className="my-16 w-[400px]"/>
                </div>
                <div className="flex justify-around">
                    <div className="dark bg-white rounded p-4 m-4 w-[300px]">
                        <h1 className="text-gray-600 text-2xl font-semibold mb-5">Sign In</h1>
                        <Login/>
                    </div>
                </div>
            </SideBar>
            <div style={{backgroundImage: `url('${authBanner}')`}} className={`h-full grow bg-right bg-cover`}>
            </div>
        </div>
    )
}