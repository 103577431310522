import * as coreClient from "@azure/core-client";
import * as Parameters from "./models/parameters";
import * as Mappers from "./models/mappers";
import {
  DesignerOptionalParams,
  LoginRequest,
  AuthLoginOptionalParams,
  AuthLoginResponse,
  AuthLogoutOptionalParams,
  HistoryListOptionalParams,
  HistoryListResponse,
  HistoryDeleteOptionalParams,
  PublishRequestDto,
  HistoryUpdateOptionalParams,
  HistoryUpdateResponse,
  HistoryLatestGetOptionalParams,
  HistoryLatestGetResponse,
  HistoryGetVersionOptionalParams,
  HistoryGetVersionResponse,
  HistoryRestoreOptionalParams,
  HistoryRestoreResponse,
  HistoryPublishedSitesListOptionalParams,
  HistoryPublishedSitesListResponse,
  MediaFilesListOptionalParams,
  MediaFilesListResponse,
  MediaUploadTokenGetOptionalParams,
  MediaUploadTokenGetResponse,
  InitialisePreflightRequest,
  PreflightCreateOptionalParams,
  PreflightCreateResponse,
  PreflightGetOptionalParams,
  PreflightGetResponse,
  Preflight,
  PreflightUpdateOptionalParams,
  PreflightUpdateResponse,
  PreflightDeleteOptionalParams,
  SaveRequestDto,
  PreflightSaveOptionalParams,
  PreflightPublishOptionalParams,
  StylesGetOptionalParams,
  StylesGetResponse,
  StylesGetForThemeOptionalParams,
  StylesGetForThemeResponse,
} from "./models";

export class Designer extends coreClient.ServiceClient {
  $host: string;
  xHrSiteId: number;

  /**
   * Initializes a new instance of the Designer class.
   * @param $host server parameter
   * @param xHrSiteId The Site ID
   * @param options The parameter options
   */
  constructor(
    $host: string,
    xHrSiteId: number,
    options?: DesignerOptionalParams,
  ) {
    if ($host === undefined) {
      throw new Error("'$host' cannot be null");
    }
    if (xHrSiteId === undefined) {
      throw new Error("'xHrSiteId' cannot be null");
    }

    // Initializing default values for options
    if (!options) {
      options = {};
    }
    const defaults: DesignerOptionalParams = {
      requestContentType: "application/json; charset=utf-8",
    };

    const packageDetails = `azsdk-js-designer/1.0.0-beta.1`;
    const userAgentPrefix =
      options.userAgentOptions && options.userAgentOptions.userAgentPrefix
        ? `${options.userAgentOptions.userAgentPrefix} ${packageDetails}`
        : `${packageDetails}`;

    const optionsWithDefaults = {
      ...defaults,
      ...options,
      userAgentOptions: {
        userAgentPrefix,
      },
      endpoint: options.endpoint ?? options.baseUri ?? "{$host}",
    };
    super(optionsWithDefaults);
    // Parameter assignments
    this.$host = $host;
    this.xHrSiteId = xHrSiteId;
  }

  /**
   * @param body
   * @param options The options parameters.
   */
  authLogin(
    body: LoginRequest,
    options?: AuthLoginOptionalParams,
  ): Promise<AuthLoginResponse> {
    return this.sendOperationRequest({ body, options }, authLoginOperationSpec);
  }

  /** @param options The options parameters. */
  authLogout(options?: AuthLogoutOptionalParams): Promise<void> {
    return this.sendOperationRequest({ options }, authLogoutOperationSpec);
  }

  /** @param options The options parameters. */
  historyList(
    options?: HistoryListOptionalParams,
  ): Promise<HistoryListResponse> {
    return this.sendOperationRequest({ options }, historyListOperationSpec);
  }

  /** @param options The options parameters. */
  historyDelete(options?: HistoryDeleteOptionalParams): Promise<void> {
    return this.sendOperationRequest({ options }, historyDeleteOperationSpec);
  }

  /**
   * @param body
   * @param options The options parameters.
   */
  historyUpdate(
    body: PublishRequestDto,
    options?: HistoryUpdateOptionalParams,
  ): Promise<HistoryUpdateResponse> {
    return this.sendOperationRequest(
      { body, options },
      historyUpdateOperationSpec,
    );
  }

  /** @param options The options parameters. */
  historyLatestGet(
    options?: HistoryLatestGetOptionalParams,
  ): Promise<HistoryLatestGetResponse> {
    return this.sendOperationRequest(
      { options },
      historyLatestGetOperationSpec,
    );
  }

  /**
   * @param versionId
   * @param options The options parameters.
   */
  historyGetVersion(
    versionId: string,
    options?: HistoryGetVersionOptionalParams,
  ): Promise<HistoryGetVersionResponse> {
    return this.sendOperationRequest(
      { versionId, options },
      historyGetVersionOperationSpec,
    );
  }

  /**
   * @param version
   * @param options The options parameters.
   */
  historyRestore(
    version: string,
    options?: HistoryRestoreOptionalParams,
  ): Promise<HistoryRestoreResponse> {
    return this.sendOperationRequest(
      { version, options },
      historyRestoreOperationSpec,
    );
  }

  /** @param options The options parameters. */
  historyPublishedSitesList(
    options?: HistoryPublishedSitesListOptionalParams,
  ): Promise<HistoryPublishedSitesListResponse> {
    return this.sendOperationRequest(
      { options },
      historyPublishedSitesListOperationSpec,
    );
  }

  /** @param options The options parameters. */
  mediaFilesList(
    options?: MediaFilesListOptionalParams,
  ): Promise<MediaFilesListResponse> {
    return this.sendOperationRequest({ options }, mediaFilesListOperationSpec);
  }

  /**
   * @param filename
   * @param options The options parameters.
   */
  mediaUploadTokenGet(
    filename: string,
    options?: MediaUploadTokenGetOptionalParams,
  ): Promise<MediaUploadTokenGetResponse> {
    return this.sendOperationRequest(
      { filename, options },
      mediaUploadTokenGetOperationSpec,
    );
  }

  /**
   * @param body
   * @param options The options parameters.
   */
  preflightCreate(
    body: InitialisePreflightRequest,
    options?: PreflightCreateOptionalParams,
  ): Promise<PreflightCreateResponse> {
    return this.sendOperationRequest(
      { body, options },
      preflightCreateOperationSpec,
    );
  }

  /** @param options The options parameters. */
  preflightGet(
    options?: PreflightGetOptionalParams,
  ): Promise<PreflightGetResponse> {
    return this.sendOperationRequest({ options }, preflightGetOperationSpec);
  }

  /**
   * @param body
   * @param options The options parameters.
   */
  preflightUpdate(
    body: Preflight,
    options?: PreflightUpdateOptionalParams,
  ): Promise<PreflightUpdateResponse> {
    return this.sendOperationRequest(
      { body, options },
      preflightUpdateOperationSpec,
    );
  }

  /** @param options The options parameters. */
  preflightDelete(options?: PreflightDeleteOptionalParams): Promise<void> {
    return this.sendOperationRequest({ options }, preflightDeleteOperationSpec);
  }

  /**
   * @param body
   * @param options The options parameters.
   */
  preflightSave(
    body: SaveRequestDto,
    options?: PreflightSaveOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { body, options },
      preflightSaveOperationSpec,
    );
  }

  /**
   * @param body
   * @param options The options parameters.
   */
  preflightPublish(
    body: PublishRequestDto,
    options?: PreflightPublishOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { body, options },
      preflightPublishOperationSpec,
    );
  }

  /**
   * @param siteId
   * @param options The options parameters.
   */
  stylesGet(
    siteId: number,
    options?: StylesGetOptionalParams,
  ): Promise<StylesGetResponse> {
    return this.sendOperationRequest(
      { siteId, options },
      stylesGetOperationSpec,
    );
  }

  /**
   * @param body
   * @param options The options parameters.
   */
  stylesGetForTheme(
    body: Preflight,
    options?: StylesGetForThemeOptionalParams,
  ): Promise<StylesGetForThemeResponse> {
    return this.sendOperationRequest(
      { body, options },
      stylesGetForThemeOperationSpec,
    );
  }
}
// Operation Specifications
const serializer = coreClient.createSerializer(Mappers, /* isXml */ false);

const authLoginOperationSpec: coreClient.OperationSpec = {
  path: "/Auth/login",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.UserDto,
    },
  },
  requestBody: Parameters.body,
  urlParameters: [Parameters.$host],
  headerParameters: [
    Parameters.contentType,
    Parameters.accept,
    Parameters.xHrSiteId,
  ],
  mediaType: "json",
  serializer,
};
const authLogoutOperationSpec: coreClient.OperationSpec = {
  path: "/Auth/auth/logout",
  httpMethod: "POST",
  responses: { 200: {} },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.xHrSiteId],
  serializer,
};
const historyListOperationSpec: coreClient.OperationSpec = {
  path: "/History",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.VersionSummaryPaginatedResponse,
    },
  },
  queryParameters: [
    Parameters.isPublished,
    Parameters.isDraft,
    Parameters.offset,
    Parameters.limit,
    Parameters.searchTerm,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.xHrSiteId],
  serializer,
};
const historyDeleteOperationSpec: coreClient.OperationSpec = {
  path: "/History",
  httpMethod: "DELETE",
  responses: { 200: {} },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.xHrSiteId],
  serializer,
};
const historyUpdateOperationSpec: coreClient.OperationSpec = {
  path: "/History",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.PreflightVersion,
    },
  },
  requestBody: Parameters.body1,
  urlParameters: [Parameters.$host],
  headerParameters: [
    Parameters.contentType,
    Parameters.accept,
    Parameters.xHrSiteId,
  ],
  mediaType: "json",
  serializer,
};
const historyLatestGetOperationSpec: coreClient.OperationSpec = {
  path: "/History/latest",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.PreflightVersion,
    },
    404: {
      bodyMapper: Mappers.ProblemDetails,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.xHrSiteId],
  serializer,
};
const historyGetVersionOperationSpec: coreClient.OperationSpec = {
  path: "/History/version/{versionId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.PreflightVersionPaginatedResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.versionId],
  headerParameters: [Parameters.accept, Parameters.xHrSiteId],
  serializer,
};
const historyRestoreOperationSpec: coreClient.OperationSpec = {
  path: "/History/restore/{version}",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.PreflightVersion,
    },
  },
  urlParameters: [Parameters.$host, Parameters.version],
  headerParameters: [Parameters.accept, Parameters.xHrSiteId],
  serializer,
};
const historyPublishedSitesListOperationSpec: coreClient.OperationSpec = {
  path: "/History/published",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: { name: "Sequence", element: { type: { name: "String" } } },
      },
    },
    404: {
      bodyMapper: Mappers.ProblemDetails,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.xHrSiteId],
  serializer,
};
const mediaFilesListOperationSpec: coreClient.OperationSpec = {
  path: "/Media",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "FileMeta" } },
        },
      },
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.xHrSiteId],
  serializer,
};
const mediaUploadTokenGetOperationSpec: coreClient.OperationSpec = {
  path: "/Media/{filename}/token",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.BlobUploadToken,
    },
  },
  urlParameters: [Parameters.$host, Parameters.filename],
  headerParameters: [Parameters.accept, Parameters.xHrSiteId],
  serializer,
};
const preflightCreateOperationSpec: coreClient.OperationSpec = {
  path: "/Preflight",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.Preflight,
    },
  },
  requestBody: Parameters.body2,
  urlParameters: [Parameters.$host],
  headerParameters: [
    Parameters.contentType,
    Parameters.accept,
    Parameters.xHrSiteId,
  ],
  mediaType: "json",
  serializer,
};
const preflightGetOperationSpec: coreClient.OperationSpec = {
  path: "/Preflight",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Preflight,
    },
    404: {
      bodyMapper: Mappers.ProblemDetails,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.xHrSiteId],
  serializer,
};
const preflightUpdateOperationSpec: coreClient.OperationSpec = {
  path: "/Preflight",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.Preflight,
    },
  },
  requestBody: Parameters.body3,
  urlParameters: [Parameters.$host],
  headerParameters: [
    Parameters.contentType,
    Parameters.accept,
    Parameters.xHrSiteId,
  ],
  mediaType: "json",
  serializer,
};
const preflightDeleteOperationSpec: coreClient.OperationSpec = {
  path: "/Preflight",
  httpMethod: "DELETE",
  responses: { 200: {} },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.xHrSiteId],
  serializer,
};
const preflightSaveOperationSpec: coreClient.OperationSpec = {
  path: "/Preflight/save",
  httpMethod: "POST",
  responses: { 200: {} },
  requestBody: Parameters.body4,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.xHrSiteId],
  mediaType: "json",
  serializer,
};
const preflightPublishOperationSpec: coreClient.OperationSpec = {
  path: "/Preflight/publish",
  httpMethod: "PUT",
  responses: { 200: {} },
  requestBody: Parameters.body1,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.xHrSiteId],
  mediaType: "json",
  serializer,
};
const stylesGetOperationSpec: coreClient.OperationSpec = {
  path: "/Style/{siteId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: { type: { name: "String" } },
    },
  },
  urlParameters: [Parameters.$host, Parameters.siteId],
  headerParameters: [Parameters.xHrSiteId, Parameters.accept1],
  serializer,
};
const stylesGetForThemeOperationSpec: coreClient.OperationSpec = {
  path: "/Style",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: { type: { name: "ByteArray" } },
    },
  },
  requestBody: Parameters.body3,
  urlParameters: [Parameters.$host],
  headerParameters: [
    Parameters.contentType,
    Parameters.xHrSiteId,
    Parameters.accept1,
  ],
  mediaType: "json",
  serializer,
};
