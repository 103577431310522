import { LoginPage } from '@/app/Login';
import { useUser } from '@/stores/Auth';
import { createRootRoute, NavigateOptions, Outlet, RegisteredRouter, ToOptions, ToPathOption, useRouter } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import { observer } from 'mobx-react-lite';
import {RouterProvider} from 'react-aria-components'

declare module 'react-aria-components' {
  interface RouterConfig {
    href: ToPathOption<RegisteredRouter>;
    routerOptions: Omit<NavigateOptions, keyof ToOptions>;
  }
}

const RootRoute = observer(() => {
    const router = useRouter()
    const user = useUser()
    return (
        <RouterProvider
            navigate={(to, options) => router.navigate({ to, ...options })}
            useHref={(to) => router.buildLocation({to}).href}
        >
            {user ? 
                <Outlet />
                : <LoginPage/>
            }
            
            <TanStackRouterDevtools />
        </RouterProvider>
    )
})

export const Route = createRootRoute({
    component: RootRoute
})