import { useThemeStore } from "@/stores/Theme"
import { observer } from "mobx-react-lite"
import { Settings } from "./_Settings"
import { SettingsSection } from "@/features/themer/components/SettingsSection"
import { HColorPicker } from "@/components/ColorPicker/ColorPicker"
import { Switch } from "@/components/Switch/Switch"
import { AlignmentSelect } from "@/features/themer/components/AlignmentSelect"
import { Radio, RadioGroup } from "@/components/RadioGroup/RadioGroup"

export const MenuItemSettings = observer(() => {
    const theme = useThemeStore()

    const [getDarkColor, setDarkColor] = theme.bind(p => p.theme.template.components.foodMenu?.styles.colors?.foreground)
    const [getLightColor, setLightColor] = theme.bind(p => p.theme.template.components.foodMenu?.styles.colors?.background)

    const [getDark, setDark] = theme.bind(p => p.theme.template.components.foodMenu?.styles.dark)
    const [getTransparent, setTransparent] = theme.bind(p => p.theme.template.components.foodMenu?.styles.transparent)
    const [getRaise, setRaise] = theme.bind(p => p.theme.template.components.foodMenu?.styles.raised)

    const [getLayout, setLayout] = theme.bind(p => p.theme.template.components.foodMenu?.innerLayout)
    const [getImageLayout, setImageLayout, _, disabledKeys] = theme.bind(p => p.theme.template.components.foodMenu?.imageLayout)
    const [getImageEdge2Edge, setImageEdge2Edge] = theme.bind(p => p.theme.template.components.foodMenu?.imageEdgeToEdge)

    return (
        <Settings title="MENU ITEMS">
            <SettingsSection label="DARK COLOR">
                <HColorPicker
                    defaultValue={getDarkColor()}
                    onChange={(value) => setDarkColor(value?.toString("hex") ?? "")}
                    isResettable
                />
            </SettingsSection>
            <SettingsSection label="LIGHT COLOR">
                <HColorPicker
                    defaultValue={getLightColor()}
                    onChange={(value) => setLightColor(value?.toString("hex") ?? "")}
                    isResettable
                />
            </SettingsSection>
            <SettingsSection
                label="STYLE"
            >
                <Switch
                    isSelected={getDark()}
                    onChange={setDark}
                >
                    Swap light and dark
                </Switch>
                <Switch
                    isSelected={getTransparent()}
                    onChange={setTransparent}
                >
                    Transparent
                </Switch>
                <Switch
                    isSelected={getRaise()}
                    onChange={setRaise}
                >
                    Raised
                </Switch>
            </SettingsSection>
            <SettingsSection label="LAYOUT">
                <RadioGroup value={getLayout()} onChange={setLayout}>
                    <Radio value="HORIZONTAL_CARD">Horizontal Card</Radio>
                    <Radio value="VERTICAL_CARD">Vertical Card</Radio>
                    <Radio value="FULL_WIDTH_LIST">Full Width List</Radio>
                    <Radio value="TWO_COLUMN_LIST">Two Column List</Radio>
                </RadioGroup>
            </SettingsSection>
            <SettingsSection label="IMAGE OPTIONS">
                <RadioGroup value={getImageLayout()} onChange={setImageLayout}>
                    <Radio isDisabled={disabledKeys().includes(ImageOption.Left)} value={ImageOption.Left}>
                        Image Left
                    </Radio>
                    <Radio isDisabled={disabledKeys().includes(ImageOption.Right)} value={ImageOption.Right}>
                        Image Right
                    </Radio>
                    <Radio isDisabled={disabledKeys().includes(ImageOption.Top)} value={ImageOption.Top}>
                        Image Top
                    </Radio>
                    <Radio isDisabled={disabledKeys().includes(ImageOption.Bottom)} value={ImageOption.Bottom}>
                        Image Bottom
                    </Radio>
                    <Radio isDisabled={disabledKeys().includes(ImageOption.None)} value={ImageOption.None}>
                        Image None
                    </Radio>
                </RadioGroup>
                <Switch isSelected={getImageEdge2Edge()} onChange={setImageEdge2Edge}>Edge-to-Edge</Switch>
            </SettingsSection>
        </Settings>
    )
})

enum ImageOption {
    Left = "IMAGE_LEFT",
    Right = "IMAGE_RIGHT",
    Top = "IMAGE_TOP",
    Bottom = "IMAGE_BOTTOM",
    None = "IMAGE_NONE"
}