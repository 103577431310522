import { Header, ListBoxItem, ListBoxItemProps, ListBoxProps, Section, SectionProps } from "react-aria-components"
import { SideBar } from "./SideBar"
import Logo from "@/components/Logo"
import { useAuth } from "@/stores/Auth"
import { ListBox } from "@/components/ListBox"
import { AvTimer, Edit, House,ExitToApp } from "@material-ui/icons"

export const Home = () => {
    const auth = useAuth()
    return (
        <div className='flex h-full w-full'>
            <SideBar>
                <Logo className="p-4 mb-10 shrink-0"/>
                <div className="flex h-full w-full flex-col justify-between">
                    <NavMenu>
                        <NavItem href="/theme-editor"><Edit fontSize="small" className="mr-4"/>Site Editor</NavItem>
                        <NavItem href="/media-library" ><House fontSize="small" className="mr-4"/>Media Library</NavItem>
                        <NavItem href="/theme-versions" ><AvTimer fontSize="small" className="mr-4"/>Versions</NavItem>
                    </NavMenu>
                    <NavMenu>
                        <NavItem href="#" onAction={() => auth.logout()}><ExitToApp fontSize="small" className="mr-4"/>Sign Out</NavItem>
                    </NavMenu>
                </div>
            </SideBar>
            <div className='flex flex-col flex-grow h-full min-h-0 min-w-0'>
            </div>
        </div>
    )
}

export const NavMenu = <T extends object,>(props: ListBoxProps<T>) =>
    <ListBox className="flex flex-col gap-2" aria-label="Theme settings menu" {...props}/>

export const NavSection = <T extends object,>({children, ...props}: SectionProps<T>) =>
    <Section className="flex flex-col" {...props}>
        {children}
    </Section>

export const NavItem = <T extends object,>(props: ListBoxItemProps<T>) =>
    <ListBoxItem className="flex items-center text text-gray-300 hover:bg-slate-700 h-[32px] pl-4" {...props}/>

export const NavHeader = (props: React.HTMLAttributes<HTMLElement> & React.RefAttributes<HTMLElement>) =>
    <Header className="flex items-center content-center text-lg text-gray-400 pl-2" {...props}/>