import { Radio, RadioGroup, RadioGroupProps } from "@/components/RadioGroup/RadioGroup"
import { IconHorizontal, IconHorizontalChecked, IconVertical, IconVerticalChecked } from "./LayoutSelectIcons"

export type OrientationType = 'ROW' | 'COLUMN'

export interface ButtonShapeSelectProps extends Omit<RadioGroupProps, "value"> {
    value: OrientationType
}

export const OrientationSelect = (props: ButtonShapeSelectProps) => {
    return (
        <RadioGroup {...props}>
            <Radio icon={({isSelected}) => isSelected ? <IconVerticalChecked/> : <IconVertical/>} value="ROW">Row</Radio>
            <Radio icon={({isSelected}) => isSelected ? <IconHorizontalChecked/> : <IconHorizontal/>} value="COLUMN">Column</Radio>
        </RadioGroup>
    )
}