import { useThemeStore } from "@/stores/Theme"
import { observer } from "mobx-react-lite"
import { Settings } from "./_Settings"
import { ButtonShapeSelect, ShapeType } from "@/features/themer/components/ButtonShapeSelect"
import { SettingsSection } from "@/features/themer/components/SettingsSection"

export const ButtonSettings = observer(() => {
    const theme = useThemeStore()

    const [getButtonShape, setButtonShape] = theme.bind(p => p.theme.template.shape)

    return (
        <Settings title="BUTTONS">
            <SettingsSection label="STYLE">
                <ButtonShapeSelect value={getButtonShape() as ShapeType} onChange={setButtonShape}/>
            </SettingsSection>
        </Settings>
    )
})