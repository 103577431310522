import { PipelineRequest, RestError, SendRequest } from "@azure/core-rest-pipeline"
import { observable } from "mobx"

import { DesignerClient, UserDto, SiteDto } from "@/client"
import { createUseStore } from "./util"


export class AuthStore {
    @observable accessor user: Nullable<User> = null

    constructor(readonly designer: DesignerClient) {
        this.listenForBadCredentials()

        const user = parseEmbededUser()
        if (user) this.user = User.FromLogin(user)
    }

    login = async (username: string, password: string) => {
        const resp = await this.designer.authLogin({username, password})
        this.user = User.FromLogin(resp)
    }

    async logout() {
        await this.designer.authLogout()
        this.user = null
    }

    // Install a policy into the client to hook 401 responses and logout user
    private listenForBadCredentials() {
        this.designer.pipeline.addPolicy({
            name: "Bad Credentials Policy",
            sendRequest: async (request: PipelineRequest, next: SendRequest) => {
                try {
                    const result = await next(request)
                    return result
                } catch (e) {
                    const ex = e as RestError
                    if (ex.statusCode == 401)
                        this.logout.bind(this)()
                    throw e
                }
            }
        })
    }
}
export const [AuthProvider, useAuth] = createUseStore(AuthStore)
export const useUser = () => useAuth()?.user

export class User {
    selectedSite!: SiteDto

    constructor(
        readonly username: string,
        readonly siteId: number,
        readonly firstName?: string,
        readonly lastName?: string,
        readonly sites: SiteDto[] = []
    ) {}

    static FromLogin(resp: UserDto) {
        const user = new User(resp.username, resp.siteId, resp.firstName, resp.lastName, resp.sites ?? [])
        user.selectedSite = user.sites[0]
        return user
    }

    static FromCookie(auth: AuthCookie) {
        const user = new User(auth.username, auth.siteId, auth.firstName, auth.lastName, auth.sites)
        user.selectedSite = user.sites[0]
        return user
    }
}

interface AuthCookie {
    id: string
    username: string
    firstName: string
    lastName: string
    siteId: number,
    sites: SiteDto[]
}

function parseEmbededUser(): UserDto | undefined {
    const userStr = document.getElementById("user")?.textContent
    if (!userStr) return
    return JSON.parse(userStr)
}

function parseAuthCookieOrg(): AuthCookie | undefined {
    const cookie = document.cookie
        .split("; ")
        .find((row) => row.startsWith("AUTH_CLIENT="))
        ?.split("=")[1]

    if (!cookie) return

    const auth = JSON.parse(atob(decodeURIComponent(cookie!))) as AuthCookie
    return auth
}