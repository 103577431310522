import { SideBar } from "./SideBar"
import { observer } from "mobx-react-lite"
import { MediaLibrary } from "@/features/media/MediaCard/MediaLibrary"
import { Button } from "@/components/Button"
import { useNavigate } from "@tanstack/react-router"
import { ChevronLeft } from "@material-ui/icons"
import { useMedia } from "@/stores/Media"
import { useMemo } from "react"

export const MediaLibraryPage = observer(() => {
    const media = useMedia()
    const navigate = useNavigate()

    const {isPending, data} = useMemo(() => media.queryLoad(), [])

    return <>
        <SideBar>
            <Button variant="link" onPress={() => navigate({to: "/"})}><ChevronLeft/>MEDIA LIBRARY</Button>
        </SideBar>
        {!isPending && <MediaLibrary files={data!}/>}
    </>
})