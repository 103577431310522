import { OperationArguments, OperationSpec } from "@azure/core-client"
import { Designer } from "./gen"

export class DesignerClient extends Designer {
    async sendOperationRequest<T>(
        operationArguments: OperationArguments,
        operationSpec: OperationSpec,
    ): Promise<T> {
        // Modify operation args here
        operationArguments['xHrSiteId'] = this.xHrSiteId
        return super.sendOperationRequest<T>(operationArguments, operationSpec)
    }
}