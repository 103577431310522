import { IconWrap } from "./ButtonShapeIcons"

export const IconVertical = () => (
    <IconWrap>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 300'>
            <path fill='#c2c6cd' d='M0 0h300v300H0z' />
            <path
                d='M12.5 12.5h275v33.77h-275zM12.5 60.75h275v106.14h-275zM12.5 181.36h275V287.5h-275z'
                fill='#2B3648'
            />
        </svg>
    </IconWrap>
)

export const IconVerticalChecked = () => (
    <IconWrap>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 300'>
            <path fill='#f2f2f2' d='M0 0h300v300H0z' />
            <path
                d='M12.5 12.5h275v33.77h-275zM12.5 60.75h275v106.14h-275zM12.5 181.36h275V287.5h-275z'
                fill='#009393'
            />
        </svg>
    </IconWrap>
)

export const IconHorizontal = () => (
    <IconWrap>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 300'>
            <path fill='#c2c6cd' d='M0 0h300v300H0z' />
            <path
                d='M12.5 12.5h82.02v275H12.5zM108.99 12.5H287.5v33.77H108.99zM108.99 60.75H287.5v106.14H108.99zM108.99 181.36H287.5V287.5H108.99z'
                fill='#2B3648'
            />
        </svg>
    </IconWrap>
)

export const IconHorizontalChecked = () => (
    <IconWrap>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 300'>
            <path fill='#f2f2f2' d='M0 0h300v300H0z' />
            <path
                d='M12.5 12.5h82.02v275H12.5zM108.99 12.5H287.5v33.77H108.99zM108.99 60.75H287.5v106.14H108.99zM108.99 181.36H287.5V287.5H108.99z'
                fill='#009393'
            />
        </svg>
    </IconWrap>
)

export const IconSplit = () => (
    <IconWrap>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 300'>
            <path fill='#c2c6cd' d='M0 0h300v300H0z' />
            <path
                d='M12.5 12.5h130.26v275H12.5zM157.24 12.5H287.5v82.02H157.24zM157.24 108.99H287.5v82.02H157.24zM157.24 205.48H287.5v82.02H157.24z'
                fill='#2B3648'
            />
        </svg>
    </IconWrap>
)

export const IconSplitChecked = () => (
    <IconWrap>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 300'>
            <path fill='#f2f2f2' d='M0 0h300v300H0z' />
            <path
                d='M12.5 12.5h130.26v275H12.5zM157.24 12.5H287.5v82.02H157.24zM157.24 108.99H287.5v82.02H157.24zM157.24 205.48H287.5v82.02H157.24z'
                fill='#009393'
            />
        </svg>
    </IconWrap>
)
