import { action, observable, runInAction, toJS } from "mobx"
import { IDisposer } from "mobx-utils"
import { JSONPath } from "jsonpath-plus"

import { DesignerClient, Preflight } from "@/client"
import { createUseStore, PropProx } from "./util"
import _ from "lodash"

export class ThemeStore {
    designer: DesignerClient
    @observable accessor preflight: Preflight | null = null
    @observable accessor css: string = ''

    disposer?: IDisposer

    constructor(designer: DesignerClient) {
        this.designer = designer
    }

    bind = <T>(f: (p: Preflight) => T, multi?: boolean) => {
        const prox = PropProx()
        // @ts-expect-error Shinnanigans
        const path = f(prox).__prox__ as string[]
        const property = path.at(-1)
        const basePath = path.slice(0, -1)
        const jsonPath = ["$", ...path]

        const set = (val: T) => {

            runInAction(() => {
                JSONPath({
                    json: this.preflight,
                    path: jsonPath,
                    resultType: 'all',
                    callback: action((_, __, { parent, parentProperty }) => {
                        console.log(parent, parentProperty)
                        parent[parentProperty] = val
                    }),
                })

                if (basePath.join(".") == "theme.template.components.general.styles") {
                    for(const [key, value] of Object.entries(this.preflight?.theme.template.components ?? {})) {
                        if (key == "general") continue
                        const styles = value.styles
                        if (!styles) continue
                        console.log(`Setting ${property} of ${key} to ${val} on`, value)
                        styles[property!] = val
                    }
                }

                rules.mutationRules.get(path.join('.'))?.onMutate(this.preflight!, val)

            })
            this.update()
            this.getCss()
        }

        const get = (): T => {
            const result = JSONPath({
                json: this.preflight,
                path: jsonPath,
                wrap: false
            })
            return result as T
        }

        const isDisabled = () =>
            rules.disableRules.get(path.join('.'))?.isDisabled(this.preflight!) ?? false

        const disabledKeys = () =>
            rules.disableRules.get(path.join('.'))?.disabledKeys?.(this.preflight!) ?? []


        return [get, set, isDisabled, disabledKeys] as const
    }

    async load() {
        return this.designer.preflightGet().then((pf) =>{
            this.preflight = pf;
            this.getCss()
        })
    }

    restore = async (version: string) => {
        const preflightVersion = await this.designer.historyRestore(version)
        preflightVersion.preflight
        this.getCss()
    }

    publish = async ({name, versionId}: {name?: string, versionId?: string} = {}) => {
        name ??= "My Published Version"
        await this.designer.preflightPublish({name, versionId})
    }

    save = async (name?: string) => {
        name ??= "My Draft"
        await this.designer.preflightSave({name})
    }

    update = async () => {
        await this.designer.preflightUpdate(this.preflight!)
    }

    getCss = async () => {
        if (!this.preflight) return

        let str = ""
        await this.designer.stylesGetForTheme(toJS(this.preflight), {onResponse: (raw) => {
            str += raw.bodyAsText
        }})
        console.log("Css got")
        this.css = str
    }

    setPreflight = (preflight: Preflight) => {
        const newPreflight = _.merge(this.preflight, preflight)
        this.preflight = newPreflight
        this.update()
        this.getCss()
    }
}

export const [ThemeCtx, useThemeStore] = createUseStore(ThemeStore)

type PreflightMutationRule<T> = {
    name: string
    path: (preflight: Preflight) => T
    onMutate: (preflith: Preflight, value: T) => Preflight
}

type PreflightDisableRule<T> = {
    name: string
    path: (preflight: Preflight) => T
    isDisabled: (preflith: Preflight) => boolean
    disabledKeys?: (preflith: Preflight) => T[]
}

class PreflightRules {
    mutationRules: Map<string, PreflightMutationRule<any>> = new Map()
    disableRules: Map<string, PreflightDisableRule<any>> = new Map()

    registerRule(rule: PreflightMutationRule<any> | PreflightDisableRule<any>) {
        const prox = PropProx({} as Preflight)
        const path = rule.path(prox).__prox__ as string[]

        if ("onMutate" in rule)
            this.mutationRules.set(path.join('.'), rule)
        else
            this.disableRules.set(path.join('.'), rule)
    }
}

const rules = new PreflightRules()


rules.registerRule({
    name: "Reset logo options AND food menu orientation WHEN layout is changed to horizontal",
    path: (pf) => pf.theme.template.layout,
    onMutate: (pf, value) => {
        if (value == "VERTICAL") return pf
        pf.theme.template.components.logoOptions!.size = 1
        pf.theme.template.components.logoOptions!.overHang = false
        pf.theme.template.components.foodMenuNavigation!.orientation = "ROW"
        return pf
    }
})

rules.registerRule({
    name: "Reset logo options when logo url is unset",
    path: (pf) => pf.theme.template.logoUrl,
    onMutate: (pf, value) => {
        if (value != null) return pf
        pf.theme.template.components.logoOptions!.size = 1
        pf.theme.template.components.logoOptions!.overHang = false
        return pf
    }
})

rules.registerRule({
    name: "Disable logo size when logo is unset",
    path: (pf) => pf.theme.template.components.logoOptions!.size,
    isDisabled: (pf) => !pf.theme.template.logoUrl || pf.theme.template.layout == 'HORIZONTAL'
})

rules.registerRule({
    name: "Disable logo overhang when logo is unset OR layout is horizontal OR header is centered",
    path: (pf) => pf.theme.template.components.logoOptions!.overHang,
    isDisabled: (pf) =>
        !pf.theme.template.logoUrl || pf.theme.template.components.header.innerLayout == 'CENTER_ALIGN' || pf.theme.template.layout == 'HORIZONTAL'
})

rules.registerRule({
    name: "Disable food menu nav orientation WHEN layout is horizontal",
    path: (pf) => pf.theme.template.components.foodMenuNavigation?.orientation,
    isDisabled: (pf) => pf.theme.template.layout == 'HORIZONTAL'
})

rules.registerRule({
    name: "Disable menu item image options BASED on layout",
    path: (pf) => pf.theme.template.components.foodMenu?.imageLayout,
    isDisabled: (pf) => ['FULL_WIDTH_LIST', 'TWO_COLUMN_LIST'].includes(pf.theme.template.components.foodMenu.innerLayout),
    disabledKeys: (pf) => {
        const layout = pf.theme.template.components.foodMenu.innerLayout
        if (layout == 'HORIZONTAL_CARD') return ['IMAGE_TOP', 'IMAGE_BOTTOM']
        if (layout == 'VERTICAL_CARD') return ['IMAGE_LEFT', 'IMAGE_RIGHT']

        if (layout == 'FULL_WIDTH_LIST' || layout == 'TWO_COLUMN_LIST')
            return ['IMAGE_LEFT', 'IMAGE_RIGHT', 'IMAGE_TOP', 'IMAGE_BOTTOM', 'IMAGE_NONE']

        return []
    }
})