import {ColorSlider, ColorThumb, Label, SliderOutput, SliderTrack, type ColorSliderProps} from 'react-aria-components'

import './ColorSlider.css'

interface MyColorSliderProps extends ColorSliderProps {
     label?: string;
}

export function HColorSlider({ label, ...props }: MyColorSliderProps) {
    return (
        <ColorSlider {...props}>
            <Label>{label}</Label>
            <SliderOutput />
            <SliderTrack
                style={({ defaultStyle }) => ({
                    background: `${defaultStyle.background},
                repeating-conic-gradient(#CCC 0% 25%, white 0% 50%) 50% / 16px 16px`
                })}
            >
                <ColorThumb />
            </SliderTrack>
        </ColorSlider>
    )
}