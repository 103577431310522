import { PaginatedResponseMeta, VersionSummary } from "@/client"
import { Button } from "@/components/Button"
import { SearchField } from "@/components/SearchField/SearchField"
import { useRootStore } from "@/contexts"
import { useUser } from "@/stores/Auth"
import { useThemeStore } from "@/stores/Theme"
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react"
import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import { chain, mergeProps } from "react-aria"
import humanize from 'humanize-duration'
import { Cell as RACCell, ColumnProps, Column as RACColumn, Row, Table, TableBody, TableHeader, CellProps } from "react-aria-components"
import { Switch } from "@/components/Switch/Switch"
import { PublishButton } from "./PublishButton"


export const HistoryTable = () => {
    const root = useRootStore()
    const user = useUser()
    const [search, setSearch] = useState<string>()
    const [isPublished, _setIsPublished] = useState(true)
    const [isDraft, _setIsDraft] = useState(true)
    const [offset, setOffset] = useState(0)

    const {data} = useQuery({
        queryKey: ["history", user?.selectedSite.id, {search, offset, isDraft, isPublished}],
        queryFn: async() => {
            return await root.designer.historyList({searchTerm: search, limit: 10, offset, isPublished, isDraft})
        },
        placeholderData: keepPreviousData
    })

    const setIsDraft = chain(_setIsDraft, () => setOffset(0))
    const setIsPublished = chain(_setIsPublished, () => setOffset(0))

    return (
        <div className="flex flex-col w-full">
            <div className="flex items-center justify-between h-[60px] px-4 border-b border-slate-300">
                <h1 className="text-2xl font-semibold">Recent Versions</h1>
                <div className="flex items-center gap-4">
                    <Switch mode="light" isSelected={isDraft} onChange={setIsDraft}>Draft</Switch>
                    <Switch mode="light" isSelected={isPublished} onChange={setIsPublished}>Published</Switch>
                    <SearchField onSubmit={setSearch} onClear={() => setSearch("")}/>
                </div>
            </div>
            <Table className="border-separate w-full border-spacing-0">
                <TableHeader>
                    <Column isRowHeader>Status</Column>
                    <Column>Name</Column>
                    <Column>Recent Activity</Column>
                    <Column>Last Edit</Column>
                    <Column></Column>
                </TableHeader>
                <TableBody items={data?.data}>
                    {(item) =>
                        <VersionItem item={item}/>
                    }
                </TableBody>
            </Table>
            {data && data.meta.totalRecords > 10 && <div className="flex items-center justify-end border-t border-slate-300 py-2">
                <PaginationControl meta={data.meta} onChange={setOffset} />
            </div>}
        </div>
    )
}

const VersionItem = ({item}: {item: VersionSummary}) => {
    const theme = useThemeStore()
    const queryClient = useQueryClient()
    const publishMutation = useMutation({
        mutationFn: theme.publish,
        onSuccess: () => queryClient.invalidateQueries({queryKey: ["history"]})
    })

    const activityDate = item.scheduledPublicationDate ?? item.savedAt
    const recentDatePart = `${activityDate.toLocaleDateString()} ${activityDate.toLocaleTimeString()}`
    const recentType = item.scheduledPublicationDate ? 'Published' : 'Saved'
    const recentActivity = `${recentType} at ${recentDatePart}`

    const humanDuration = humanize(Date.now() - item.savedAt.getTime(), {largest: 2})
    const lastEdit = `${humanDuration} ago`

    return (
        <Row>
            <Cell>{item.scheduledPublicationDate ? 'PUBLISHED' : 'DRAFT'}</Cell>
            <Cell>{item.name}</Cell>
            <Cell>{recentActivity}</Cell>
            <Cell>{lastEdit}</Cell>
            <Cell className="flex gap-4 justify-end">
                <Button variant="outline" onPress={() => theme.restore(item.id)}>RESTORE</Button>
                <PublishButton id={item.id}/>
            </Cell>
        </Row>
    )
}


interface PaginationControlProps {
    meta: PaginatedResponseMeta
    pageSize?: number
    onChange: (page: number) => void
}

const PaginationControl = ({meta, pageSize = 10, onChange}: PaginationControlProps) => {
    const {offset, totalRecords} = meta

    const lastOffset = totalRecords - (totalRecords % pageSize || pageSize)

    return (
        <div className="flex items-center">
            <div className="flex">
                {offset + 1}-{Math.min(offset + pageSize, meta.totalRecords)}
                {" of "}
                {totalRecords}
            </div>
            <div className="flex items-center">
                <Button
                    variant="icon"
                    mode="light"
                    isDisabled={offset == 0}
                    onPress={() => onChange(Math.max(offset - pageSize, 0))}
                >
                    <IconChevronLeft/>
                </Button>
                <Button
                    variant="icon"
                    mode="light"
                    isDisabled={offset >= lastOffset}
                    onPress={() => onChange(Math.min(offset + pageSize, lastOffset))}
                >
                    <IconChevronRight/>
                </Button>
            </div>
        </div>
    )
}

const Column = (props: ColumnProps) => <RACColumn {...mergeProps(props, {className: "p-4 text-left" })}/>

const Cell = (props: CellProps) => <RACCell {...mergeProps(props, {className: "border-t border-slate-300 p-4" })}/>