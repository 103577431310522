import { DesignerClient, FileMeta } from "@/client"
import { createUseStore } from "./util"
import { IProviderParams, Upload, UploadStore } from "./UploadStore"

import { BlockBlobClient } from '@azure/storage-blob'
import { QueryClient, QueryObserver } from "@tanstack/react-query"
import { observable, runInAction } from "mobx"

export class MediaStore {
    query: QueryClient
    client: DesignerClient

    @observable accessor media: FileMeta[] = []

    constructor(client: DesignerClient, query: QueryClient) {
        this.client = client
        this.query = query
    }

    queryLoad(){
        const obs = new QueryObserver(this.query, {
            queryKey: ['media'],
            queryFn: async () => {
                return await this.client.mediaFilesList()
            },
            initialData: [],
            enabled: true,
        })

        const result = observable({...obs.getCurrentResult()})
        obs.subscribe(res => {
            runInAction(() => Object.assign(result, res) )
        })
        return result
    }

    async load() {
        const resp = await this.client.mediaFilesList()
        this.media = resp
    }

    async upload(file: File, uploader: UploadStore) {
        const upload = new Upload(file, CreateSasUploadProvider(file, this.client))
        await uploader.upload(upload)
        await this.query.invalidateQueries({queryKey: ["media"]})
    }
}

export const [MediaCtx, useMedia] = createUseStore(MediaStore)

const CreateSasUploadProvider = (file: File, client: DesignerClient) => async (params: IProviderParams) => {
    const token = await client.mediaUploadTokenGet(file.name)
    const blob = new BlockBlobClient(token.uri)
    await blob.upload(params.file, params.file.size, {
        onProgress: params.onProgress,
        blobHTTPHeaders: {
            blobContentType: params.file.type
        }
    })
}