import { CustomizeCSS } from "@/components/CustomizeCSS/CustomizeCSS";
import { useThemeStore } from "@/stores/Theme";
import { observer } from "mobx-react-lite";

export const CustomCSS = observer(() => {
  const theme = useThemeStore();

  const [getCustomCss, setCustomCss] = theme.bind((p) => p.theme.customCss);

  return (
    <CustomizeCSS
      title="Customize CSS"
      defaultValue={getCustomCss()}
      onChange={(value) => setCustomCss(value.toString())}
    />
  );
});
