import { Preflight } from "@/client"


export function GetPreflight(globalAssetsUrl: string): DeepPartial<Preflight> {
    return {
        theme: {
            meta: {
                themeName: 'elegant',
            },
            template: {
                layout: 'VERTICAL',
                iconSet: 'OUTLINE',
                shape: 'ROUNDED',
                colors: {
                    primary: '#b0a35f',
                    secondary: '#2b3648',
                    neutralDark: '#181D25',
                    neutralLight: '#f4f4f4',
                },
                typography: {
                    fonts: {
                        heading: {
                            fontFamily: ['Forum', 'serif'].join(','),
                            fontWeight: 'light',
                        },
                        body: {
                            fontFamily: ['Pontano Sans', 'sans-serif'].join(','),
                            fontWeight: 'light',
                        },
                    },
                    baseFontTypography: {
                        fontSize: 16,
                        lineHeight: 24,
                        fontHarmony: 1.25,
                    },
                },
                components: {
                    general: {
                        innerLayout: 'CENTER_ALIGN',
                        styles: {
                            dark: true,
                            transparent: false,
                        },
                    },
                    header: {
                        innerLayout: 'CENTER_ALIGN',
                        styles: {
                            dark: true,
                            raised: true,
                            transparent: false,
                            colors: {
                                background: '',
                                foreground: '',
                            },
                        },
                    },
                    foodMenu: {
                        innerLayout: 'VERTICAL_CARD',
                        imageLayout: 'IMAGE_TOP',
                        bannerEnabled: true,
                        imageEdgeToEdge: false,
                        styles: {
                            dark: true,
                            raised: false,
                            transparent: false,
                            colors: {
                                background: '',
                                foreground: '',
                            },
                        },
                    },
                    foodMenuNavigation: {
                        innerLayout: 'CENTER_ALIGN',
                        orientation: 'ROW',
                        styles: {
                            dark: true,
                            raised: false,
                            transparent: false,
                            colors: {
                                background: '',
                                foreground: '',
                            },
                        },
                    },
                    mainBanner: {
                        innerLayout: 'LEFT_ALIGN',
                        styles: {
                            dark: true,
                            raised: false,
                            transparent: false,
                            colors: {
                                background: '',
                                foreground: '',
                            },
                        },
                        shade: true,
                        enabled: true,
                    },
                    footer: {
                        innerLayout: 'CENTER_ALIGN',
                        styles: {
                            dark: true,
                            raised: false,
                            transparent: false,
                            colors: {
                                background: '',
                                foreground: '',
                            },
                        },
                    },
                    logoOptions: {
                        size: 1,
                        overHang: false,
                    },
                },
            },
        },
        content: {
            pages: {
                landingPage: {
                    bannerImageUrl: globalAssetsUrl + "/elegant.jpg",
                    bannerImageShade: true,
                },
            },
        },
    }
}