import { Radio, RadioGroup, RadioGroupProps } from "@/components/RadioGroup/RadioGroup"
import { IconCircular, IconCircularChecked, IconRounded, IconRoundedChecked, IconSquare, IconSquareChecked } from "./ButtonShapeIcons"


export type ShapeType = 'CIRCULAR' | 'ROUNDED' | 'SQUARE'

export interface ButtonShapeSelectProps extends Omit<RadioGroupProps, "value"> {
    value: ShapeType
}

export const ButtonShapeSelect = (props: ButtonShapeSelectProps) => {
    return (
        <RadioGroup {...props}>
            <Radio icon={({isSelected}) => isSelected ? <IconCircularChecked/> : <IconCircular/>} value="CIRCULAR">Circular</Radio>
            <Radio icon={({isSelected}) => isSelected ? <IconRoundedChecked/> : <IconRounded/>} value="ROUNDED">Rounded</Radio>
            <Radio icon={({isSelected}) => isSelected ? <IconSquareChecked/> : <IconSquare/>} value="SQUARE">Square</Radio>
        </RadioGroup>
    )
}