import { useThemeStore } from "@/stores/Theme"
import { observer } from "mobx-react-lite"
import { Settings } from "./_Settings"
import { SettingsSection } from "@/features/themer/components/SettingsSection"
import { MediaSelect } from "@/features/themer/components/MediaSelect"
import { Switch } from "@/components/Switch/Switch"

export const BackgroundImageSettings = observer(() => {
    const theme = useThemeStore()

    const [getBackgroundImage, setBackgroundImage] = theme.bind(p => p.content.pages?.landingPage.bannerImageUrl)
    const [getShade, setShade] = theme.bind(p => p.content.pages?.landingPage.bannerImageShade)

    return (
        <Settings title="BUTTONS">
            <SettingsSection label="BACKGROUND IMAGE">
                <MediaSelect url={getBackgroundImage()} onChange={(value) =>setBackgroundImage(value ?? "")}/>
            </SettingsSection>
            <SettingsSection label="IMAGE SHADE">
                <Switch isSelected={getShade()} onChange={setShade}>Image Shade</Switch>
            </SettingsSection>
        </Settings>
    )
})