import {ColorArea, ColorThumb, type ColorAreaProps} from 'react-aria-components'

import './ColorArea.css'

export function HColorArea(props: ColorAreaProps) {
    return (
        <ColorArea {...props}>
            <ColorThumb />
        </ColorArea>
    );
}