import React from 'react'
import {
    Select as AriaSelect,
    SelectProps as AriaSelectProps,
    Button,
    ListBoxItemProps,
    SelectValue,
    ValidationResult
} from 'react-aria-components'
import { tv } from 'tailwind-variants'
import { Description, FieldError, Label } from '../Field'
import { DropdownItem, DropdownSection, DropdownSectionProps, ListBox } from '../ListBox'
import { Popover } from '../Popover'
import { composeTailwindRenderProps } from '../utils'
import { ArrowDropDown } from '@material-ui/icons'

const styles = tv({
    base: 'flex items-center text-start gap-4 w-full cursor-default border border-gray-500 hover:border-gray-800 rounded-sm pl-3 pr-2 py-2 min-w-[150px] transition',
    variants: {
        isDisabled: {
            false: 'text-gray-200 dark:text-zinc-300 dark:hover:bg-zinc-600 group-invalid:border-red-600 forced-colors:group-invalid:border-[Mark]',
            true: 'text-gray-400 dark:text-zinc-600 forced-colors:text-[GrayText] dark:bg-zinc-800 dark:border-white/5 forced-colors:border-[GrayText]'
        }
    }
})

export interface SelectProps<T extends object> extends Omit<AriaSelectProps<T>, 'children'> {
  label?: string
  description?: string
  errorMessage?: string | ((validation: ValidationResult) => string)
  items?: Iterable<T>
  children: React.ReactNode | ((item: T) => React.ReactNode)
}

export function Select<T extends object>(
    { label, description, errorMessage, children, items, ...props }: SelectProps<T>
) {
    return (
        <AriaSelect {...props} className={composeTailwindRenderProps(props.className, 'group flex flex-col gap-1 relative')}>
            {label && <Label className='absolute top-[-12px] left-2 bg-'>{label}</Label>}
            <Button className={styles}>
                <SelectValue className="flex-1 text-sm" />
                <ArrowDropDown aria-hidden className="w-4 h-4 text-gray-200" />
            </Button>
            {description && <Description>{description}</Description>}
            <FieldError>{errorMessage}</FieldError>
            <Popover className="min-w-[--trigger-width] rounded">
                <ListBox items={items} className="outline-none py-2">
                    {children}
                </ListBox>
            </Popover>
        </AriaSelect>
    );
}

export function SelectItem(props: ListBoxItemProps) {
    return <DropdownItem {...props} />
}

export function SelectSection<T extends object>(props: DropdownSectionProps<T>) {
    return <DropdownSection {...props} />
}
