import { HColorPicker } from "@/components/ColorPicker/ColorPicker"
import { Switch } from "@/components/Switch/Switch"
import { useThemeStore } from "@/stores/Theme"
import { observer } from "mobx-react-lite"
import { Settings } from "./_Settings"
import { AlignmentSelect } from "@/features/themer/components/AlignmentSelect"
import { LayoutType } from "@/features/themer/components/LayoutSelect"
import { SettingsSection } from "@/features/themer/components/SettingsSection"


export const FooterSettings = observer(() => {
    const theme = useThemeStore()

    const [getDarkColor, setDarkColor] = theme.bind(p => p.theme.template.components.footer.styles.colors?.foreground)
    const [getLightColor, setLightColor] = theme.bind(p => p.theme.template.components.footer.styles.colors?.background)

    const [getDark, setDark] = theme.bind(p => p.theme.template.components.general.styles.dark)
    const [getTransparent, setTransparent] = theme.bind(p => p.theme.template.components.footer.styles.transparent)
    const [getRaise, setRaise] = theme.bind(p => p.theme.template.components.footer.styles.raised)

    const [getAlign, setAlign] = theme.bind(p => p.theme.template.components.footer.innerLayout)

    return (
        <Settings title="FOOTER">
            <SettingsSection label="DARK COLOR">
                <HColorPicker
                    defaultValue={getDarkColor()}
                    onChange={(value) => setDarkColor(value?.toString("hex") ?? "")}
                    isResettable
                />
            </SettingsSection>
            <SettingsSection label="LIGHT COLOR">
                <HColorPicker
                    defaultValue={getLightColor()}
                    onChange={(value) => setLightColor(value?.toString("hex") ?? "")}
                    isResettable
                />
            </SettingsSection>
            <SettingsSection
                label="STYLE"
            >
                <Switch
                    isSelected={getDark()}
                    onChange={setDark}
                >
                    Swap light and dark
                </Switch>
                <Switch
                    isSelected={getTransparent()}
                    onChange={setTransparent}
                >
                    Transparent
                </Switch>
                <Switch
                    isSelected={getRaise()}
                    onChange={setRaise}
                >
                    Raised
                </Switch>
            </SettingsSection>
            <SettingsSection label="LAYOUT">
                <AlignmentSelect value={getAlign() as LayoutType} onChange={setAlign}/>
            </SettingsSection>
        </Settings>
    )
})