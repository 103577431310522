import { Radio, RadioGroup, RadioGroupProps } from "@/components/RadioGroup/RadioGroup"
import { IconHorizontal, IconHorizontalChecked, IconVertical, IconVerticalChecked } from "./LayoutSelectIcons"

export type LayoutType = 'VERTICAL' | 'HORIZONTAL'

export interface ButtonShapeSelectProps extends Omit<RadioGroupProps, "value"> {
    value: LayoutType
}

export const LayoutSelect = (props: ButtonShapeSelectProps) => {
    return (
        <RadioGroup {...props}>
            <Radio icon={({isSelected}) => isSelected ? <IconVerticalChecked/> : <IconVertical/>} value="VERTICAL">Vertical</Radio>
            <Radio icon={({isSelected}) => isSelected ? <IconHorizontalChecked/> : <IconHorizontal/>} value="HORIZONTAL">Horizontal</Radio>
        </RadioGroup>
    )
}