import { Preflight } from "@/client";


export function GetPreflight(globalAssetsUrl: string): DeepPartial<Preflight> {
    return {
        theme: {
            meta: {
                themeName: 'eclectic',
            },
            template: {
                layout: 'VERTICAL',
                iconSet: 'HAND_DRAWN',
                shape: 'CIRCULAR',
                colors: {
                    primary: '#ffb071',
                    secondary: '#9ad3d0',
                    neutralDark: '#000000',
                    neutralLight: '#f4f4f4',
                },
                typography: {
                    fonts: {
                        heading: {
                            fontFamily: ['Cutive', 'serif'].join(','),
                            fontWeight: 'normal',
                        },
                        body: {
                            fontFamily: ['Roboto Mono', 'serif'].join(','),
                            fontWeight: 'normal',
                        },
                    },
                    baseFontTypography: {
                        fontSize: 16,
                        lineHeight: 26,
                        fontHarmony: 1.333,
                    },
                },
                components: {
                    general: {
                        innerLayout: 'LEFT_ALIGN',
                        styles: {
                            dark: false,
                            transparent: false,
                        },
                    },
                    header: {
                        innerLayout: 'LEFT_ALIGN',
                        styles: {
                            dark: false,
                            raised: true,
                            transparent: false,
                            colors: {
                                background: '',
                                foreground: '',
                            },
                        },
                    },
                    foodMenu: {
                        innerLayout: 'VERTICAL_CARD',
                        imageLayout: 'IMAGE_BOTTOM',
                        bannerEnabled: false,
                        imageEdgeToEdge: false,
                        styles: {
                            dark: false,
                            raised: false,
                            transparent: false,
                            colors: {
                                background: '',
                                foreground: '',
                            },
                        },
                    },
                    foodMenuNavigation: {
                        innerLayout: 'CENTER_ALIGN',
                        orientation: 'ROW',
                        styles: {
                            dark: false,
                            raised: false,
                            transparent: false,
                            colors: {
                                background: '',
                                foreground: '',
                            },
                        },
                    },
                    mainBanner: {
                        innerLayout: 'CENTER_ALIGN',
                        styles: {
                            dark: false,
                            raised: false,
                            transparent: false,
                            colors: {
                                background: '',
                                foreground: '',
                            },
                        },
                        shade: false,
                        enabled: true,
                    },
                    footer: {
                        innerLayout: 'RIGHT_ALIGN',
                        styles: {
                            dark: false,
                            raised: false,
                            transparent: false,
                            colors: {
                                background: '',
                                foreground: '',
                            },
                        },
                    },
                    logoOptions: {
                        size: 1,
                        overHang: false,
                    },
                },
            },
        },
        content: {
            pages: {
                landingPage: {
                    bannerImageUrl: globalAssetsUrl + "/eclectic.jpg",
                    bannerImageShade: false,
                }
            },
        }
    }
}
