import { FontSelect } from "@/components/widgets/FontSelect/FontSelect"
import { useThemeStore } from "@/stores/Theme"
import { observer } from "mobx-react-lite"
import { HeaderFonts } from "../lib/typography/HeaderFonts"
import { BodyFonts } from "../lib/typography/BodyFonts"
import { Slider } from "@/components/Slider/Slider"
import { Settings } from "./_Settings"
import { SettingsSection } from "@/features/themer/components/SettingsSection"

export const FontSettings = observer(() => {
    const theme = useThemeStore()

    const [getHeaderFont, setHeaderFont] = theme.bind(p => p.theme.template.typography.fonts?.heading?.fontFamily)
    const [getBodyFont, setBodyFont] = theme.bind(p => p.theme.template.typography.fonts?.body?.fontFamily)
    const [getFontSize, setFontSize] = theme.bind(p => p.theme.template.typography.baseFontTypography.fontSize)
    const [getLineSpace, setLineSpacing] = theme.bind(p => p.theme.template.typography.baseFontTypography.lineHeight)
    const [getFontScale, setFontScale] = theme.bind(p => p.theme.template.typography.baseFontTypography.fontHarmony)

    return (
        <Settings title="FONTS">
            <SettingsSection label="SITE FONTS">
                <FontSelect fonts={HeaderFonts} label="Header Font" selectedKey={getHeaderFont()} onSelectionChange={key => setHeaderFont(key?.toString())}/>
                <FontSelect fonts={BodyFonts} label="Body Font" selectedKey={getBodyFont()} onSelectionChange={key => setBodyFont(key?.toString() ?? "")}/>
            </SettingsSection>
            <SettingsSection>
                <Slider
                    minValue={14}
                    maxValue={20}
                    label='Font Size'
                    defaultValue={getFontSize()}
                    onChangeEnd={setFontSize}
                />
            </SettingsSection>
            <SettingsSection>
                <Slider
                    minValue={24}
                    maxValue={34}
                    label='Line Spacing'
                    defaultValue={getLineSpace()}
                    onChangeEnd={setLineSpacing}
                />
            </SettingsSection>
            <SettingsSection>
                <Slider
                    minValue={1.125}
                    maxValue={1.333}
                    step={0.5}
                    label='Line Spacing'
                    defaultValue={getFontScale()}
                    onChangeEnd={setFontScale}
                />
            </SettingsSection>
        </Settings>
    )
})