import { useThemeStore } from "@/stores/Theme"
import { observer } from "mobx-react-lite"
import { Settings } from "./_Settings"
import { LayoutSelect, LayoutType } from "@/features/themer/components/LayoutSelect"
import { AlignmentSelect } from "@/features/themer/components/AlignmentSelect"
import { SettingsSection } from "@/features/themer/components/SettingsSection"

export const LayoutSettings = observer(() => {
    const theme = useThemeStore()

    const [getLayout, setLayout] = theme.bind(p => p.theme.template.layout)
    const [getAlign, setAlign] = theme.bind(p => p.theme.template.components.general.innerLayout)

    return (
        <Settings title="LAYOUT">
            <SettingsSection label="PAGE LAYOUT">
                <LayoutSelect value={getLayout() as LayoutType} onChange={setLayout}/>
            </SettingsSection>
            <SettingsSection label="TEXT ALIGNMENT">
                <AlignmentSelect value={getAlign() as LayoutType} onChange={setAlign}/>
            </SettingsSection>
        </Settings>
    )
})